import { TouchApp } from "@mui/icons-material";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { Box, IconButton, Typography } from "@mui/material";
import {
  AdvancedMarker,
  Map,
  MapControl,
  MapMouseEvent,
  Pin,
  useAdvancedMarkerRef,
  useMap,
} from "@vis.gl/react-google-maps";
import { useEffect, useState } from "react";
import { ISurvey } from "survey-core";

import PlaceAutocomplete from "./PlaceAutoCompleteField";
import { IPlace } from "../../types";

const torontoLatLng = { lat: 43.65107, lng: -79.347015 };

const SurveyJSDynamicPanel = ({
  header,
  fields,
  panelName,
  values,
  valuesOnChange,
  removePanelText,
  onRemovePanel,
  disabled,
}: {
  header: string;
  fields: {
    fieldName: string;
    fieldTitle: string;
    visible?: boolean;
    required: boolean;
  }[];
  values: any;
  panelName: string;
  valuesOnChange: (values: any) => void;
  removePanelText: string;
  onRemovePanel: (panelIndex: number) => void;
  disabled: boolean;
}) => {
  return (
    <div
      style={{
        marginTop: "20px",
      }}
      className="sd-element--nested sd-element--nested-with-borders sd-element  sd-question sd-question--paneldynamic sd-element--complex sd-question--complex sd-row__question sd-question--title-top"
    >
      <div className="sd-paneldynamic__header sd-element__header sd-question__header--location-top sd-element__header--location-top">
        {header}
      </div>
      <div className="sd-element__content sd-question__content">
        <div className="sd-paneldynamic">
          <div className="sd-paneldynamic__panels-container">
            <div className="sd-paneldynamic__panel-wrapper sd-paneldynamic__panel-wrapper--list">
              {values.length > 0 &&
                values.map((value, valIndex) => (
                  <div
                    className="sd-element sd-element--complex sd-panel sd-row__panel"
                    key={`${value.id}${valIndex}`}
                  >
                    <div className="sd-panel__header sd-element__header sd-element__header--location-top">
                      <div className="sd-title sd-element__title sd-panel__title">
                        <span className="sv-string-viewer sv-string-viewer--multiline">
                          {panelName} {valIndex + 1}
                        </span>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns:
                          "repeat(auto-fill, minmax(240px, 1fr))",
                        gap: "16px",
                      }}
                    >
                      {fields
                        .filter((field) => field.visible !== false)
                        .map((field, index) => (
                          <div
                            className="sd-element__content sd-panel__content"
                            key={`${field.fieldName}${index}`}
                          >
                            <div
                              style={{
                                width: "100%",
                              }}
                            >
                              <div
                                data-key={`${field.fieldName}${index}`}
                                style={
                                  {
                                    // flex: "1 1 100%",
                                    // minWidth: "min(100%, 300px)",
                                    // maxWidth: "50%",
                                  }
                                }
                              >
                                <div
                                  // className="sd-element--nested sd-element sd-question sd-row__question sd-question--title-top sd-row__question--small"
                                  data-rendered="r"
                                  data-name={field.fieldName}
                                >
                                  <div className="sd-question__header sd-element__header sd-question__header--location-top sd-element__header--location-top">
                                    <h5
                                      className="sd-title sd-element__title sd-question__title"
                                      id="sq_115_ariaTitle"
                                    >
                                      <span className="sv-string-viewer sv-string-viewer--multiline">
                                        {field.fieldTitle}
                                        {field.required && (
                                          <span className="sd-question__required-text">
                                            {" *"}
                                          </span>
                                        )}
                                      </span>
                                    </h5>
                                  </div>
                                  <div
                                    className="sd-text__content sd-question__content"
                                    role="presentation"
                                  >
                                    <input
                                      id="sq_115i"
                                      className="sd-input sd-text"
                                      type="text"
                                      placeholder=""
                                      aria-required="false"
                                      aria-label={field.fieldTitle}
                                      aria-invalid="false"
                                      data-rendered="r"
                                      value={value[field.fieldName] || ""}
                                      onChange={(e) => {
                                        const updatedValues = [...values];
                                        updatedValues[valIndex][
                                          field.fieldName
                                        ] = e.target.value;
                                        valuesOnChange(updatedValues);
                                      }}
                                      disabled={disabled}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className="sd-action-bar sd-paneldynamic__panel-footer">
                      <div className="sv-action" id="remove-panel-sp_104">
                        <div className="sv-action__content">
                          <button
                            className="sd-action sd-paneldynamic__btn sd-action--negative sd-paneldynamic__remove-btn"
                            type="button"
                            onClick={() => onRemovePanel(valIndex)}
                          >
                            <span>
                              <span className="sv-string-viewer">
                                {removePanelText}
                              </span>
                            </span>
                            <span className="sd-hidden" />
                          </button>
                        </div>
                      </div>
                      <div
                        className="sv-action sv-dots sv-action--hidden"
                        id="dotsItem-id3"
                      >
                        <div className="sv-action__content">
                          <button
                            className="sv-action-bar-item sv-action-bar-item--icon sv-dots__item"
                            type="button"
                            title="More"
                            tabIndex={0}
                            role="button"
                          >
                            <svg
                              className="sv-svg-icon sv-action-bar-item__icon"
                              role="img"
                              style={{ width: 24, height: 24 }}
                            >
                              <use xlinkHref="#icon-more" />
                              <title>More</title>
                            </svg>
                          </button>
                          <div>
                            <div
                              tabIndex={-1}
                              className="sv-popup sv-popup--dropdown sv-popup--show-pointer sv-popup--left"
                              style={{ display: "none" }}
                            >
                              <div
                                className="sv-popup__container"
                                style={{
                                  left: 0,
                                  top: 0,
                                  height: "auto",
                                  width: "auto",
                                  minWidth: "auto",
                                }}
                              >
                                <div className="sv-popup__shadow">
                                  <span
                                    className="sv-popup__pointer"
                                    style={{ left: 0, top: 0 }}
                                  />
                                  <div className="sv-popup__body-content">
                                    <div className="sv-popup__scrolling-content">
                                      <div className="sv-popup__content">
                                        <div className="sv-list__container">
                                          <div className="sv-list__empty-container">
                                            <div
                                              className="sv-list__empty-text"
                                              aria-label="No data to display"
                                            >
                                              No data to display
                                            </div>
                                          </div>
                                          <ul
                                            className="sv-list"
                                            role="listbox"
                                            style={{ display: "none" }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MapMessage = ({ message }: { message: string }) => (
  <Box
    sx={{
      position: "absolute",
      top: "160px",
      left: "50%",
      transform: "translateX(-50%)",
      bgcolor: "rgba(0, 0, 0, 0.7)",
      color: "#fff",
      padding: "10px 20px",
      borderRadius: "8px",
      zIndex: 1000,
      transition: "opacity 0.3s ease",
    }}
  >
    <Typography>{message}</Typography>
  </Box>
);

const CustomMapElement = ({
  cameraLocations,
  setCameraLocations,
  survey,
  address,
  setAddress,
  disabled,
}: {
  cameraLocations:
    | {
        lat: number;
        lng: number;
        locationOnProperty: string;
        fieldOfView: string;
        make: string;
        model: string;
        id: string;
      }[]
    | null;
  setCameraLocations: (
    locations: {
      lat: number;
      lng: number;
      locationOnProperty: string;
      fieldOfView: string;
      make: string;
      model: string;
      id: string;
    }[],
  ) => void;
  survey: ISurvey | null;
  address: IPlace | null;
  setAddress: (place: IPlace | null) => void;
  disabled: boolean;
}) => {
  const googleMapId = "e78ded4294c5e80d";
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [isMapVisible, setIsMapVisible] = useState(false);
  const [mapMessage, setMapMessage] = useState<string | null>(null);

  const map = useMap();

  const showMessageOnMap = (message: string) => {
    setMapMessage(message);
    setTimeout(() => setMapMessage(null), 3000);
  };

  const handleLocationError = (error: GeolocationPositionError) => {
    if (error.code === error.PERMISSION_DENIED) {
      showMessageOnMap("Error: Geolocation service denied.");
    } else if (error.code === error.POSITION_UNAVAILABLE) {
      showMessageOnMap("Error: Position unavailable.");
    } else if (error.code === error.TIMEOUT) {
      showMessageOnMap("Error: Geolocation request timed out.");
    } else {
      showMessageOnMap("Error: Failed to retrieve location.");
    }
  };

  useEffect(() => {
    if (address && address.geometry) {
      setIsMapVisible(true);
      const location = address.geometry;
      if (location) {
        const latLng: google.maps.LatLngLiteral = {
          lat: location.lat,
          lng: location.lng,
        };
        map?.setCenter(latLng);
        map?.setZoom(18);
      }
    } else {
      setIsMapVisible(false);
    }
  }, [address, map]);

  const getCurrentPosition = () => {
    if (cameraLocations && cameraLocations.length > 0) {
      const bounds = new google.maps.LatLngBounds();
      cameraLocations?.forEach((location) => bounds.extend(location));
      map?.fitBounds(bounds);
      showMessageOnMap("Centered to fit all cameras.");
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          map?.panTo({ lat: latitude, lng: longitude });
          map?.setZoom(18);
          showMessageOnMap("Centered to your current location.");
        },
        (error) => {
          handleLocationError(error);
          map?.setCenter(torontoLatLng);
          map?.setZoom(16);
        },
      );
    }
  };

  const handleMapClick = (event: MapMouseEvent) => {
    if (disabled) return;

    const latLng = event.detail.latLng;
    if (!latLng) return;
    const newCameraLocation = {
      lat: latLng.lat,
      lng: latLng.lng,
      locationOnProperty: "",
      fieldOfView: "",
      make: "",
      model: "",
      id: Math.random().toString(36).substring(7),
    };
    setCameraLocations(
      cameraLocations
        ? [...cameraLocations, newCameraLocation]
        : [newCameraLocation],
    );

    showMessageOnMap("Camera added. Drag to reposition if needed.");
  };

  useEffect(() => {
    if (address && address.geometry) {
      map?.setCenter(address.geometry);
      map?.setZoom(18);
    } else if (navigator.permissions) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "granted") {
          getCurrentPosition();
        } else if (result.state === "prompt") {
          getCurrentPosition();
        } else {
          map?.setCenter(torontoLatLng);
          map?.setZoom(12);
        }
      });
    } else if (navigator.geolocation) {
      getCurrentPosition();
    }
  }, [map]);

  const [markerRef] = useAdvancedMarkerRef();

  return (
    <div
      style={{
        height: "auto",
        position: "relative",
        pointerEvents: disabled ? "none" : "inherit",
      }}
    >
      {!disabled && isMapVisible && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            marginBottom: "16px",
          }}
        >
          <TouchApp color="secondary" fontSize="large" />
          <Typography sx={{ margin: "0 8px" }}>
            <b>Tap on the map to add a new camera</b>
          </Typography>
        </div>
      )}
      <PlaceAutocomplete
        place={address || undefined}
        onPlaceSelect={(place) => {
          if (place) {
            setAddress(place);
          } else {
            setAddress(null);
          }
        }}
        hideClearButton={disabled}
        fieldName="Address"
        required={true}
      />
      {mapMessage && isMapVisible && <MapMessage message={mapMessage} />}{" "}
      {isMapVisible && (
        <Map
          mapId={googleMapId}
          defaultCenter={torontoLatLng}
          defaultZoom={12}
          onTilesLoaded={() => setIsMapLoaded(true)}
          mapTypeId={isMapLoaded ? google.maps.MapTypeId.SATELLITE : undefined}
          onClick={handleMapClick}
          streetViewControl={false}
          fullscreenControl={false}
          tilt={0}
          heading={0}
          rotateControl={false}
          // Custom styling to fit the map in the parent container
          style={{
            height: "calc(500px - 48px + 16px)",
          }}
        >
          {cameraLocations?.map((location, index) => (
            <AdvancedMarker
              key={location.id}
              ref={markerRef}
              position={location}
              draggable={!disabled}
              onDragEnd={(event) => {
                const lat = event?.latLng?.lat();
                const lng = event?.latLng?.lng();
                if (lat !== undefined && lng !== undefined) {
                  const updatedLocation = {
                    lat,
                    lng,
                    locationOnProperty: location.locationOnProperty,
                    fieldOfView: location.fieldOfView,
                    make: location.make,
                    model: location.model,
                    id: location.id,
                  };
                  const updatedLocations = cameraLocations?.map((loc) =>
                    loc.id === location.id ? updatedLocation : loc,
                  );
                  setCameraLocations(updatedLocations);
                  showMessageOnMap("Camera position updated.");
                }
              }}
            >
              <Pin glyph={`${index + 1}`} glyphColor="white" />
            </AdvancedMarker>
          ))}

          {isMapLoaded && (
            <MapControl position={google.maps.ControlPosition.RIGHT_BOTTOM}>
              <Box
                sx={{
                  bgcolor: "#fff",
                  boxShadow: 2,
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "40px",
                  height: "40px",
                  margin: "10px",
                }}
              >
                <IconButton
                  onClick={getCurrentPosition}
                  aria-label="Pan to current location"
                >
                  <MyLocationIcon />
                </IconButton>
              </Box>
            </MapControl>
          )}
        </Map>
      )}
      {isMapVisible && cameraLocations && cameraLocations.length > 0 && (
        <SurveyJSDynamicPanel
          header="Camera Locations"
          fields={[
            {
              fieldName: "longitude",
              fieldTitle: "Longitude",
              visible: false,
              required: true,
            },
            {
              fieldName: "latitude",
              fieldTitle: "Latitude",
              visible: false,
              required: true,
            },
            {
              fieldName: "locationOnProperty",
              fieldTitle: "Location on Property",
              required: true,
            },
            {
              fieldName: "fieldOfView",
              fieldTitle: "Field of View",
              required: true,
            },
            { fieldName: "make", fieldTitle: "Make", required: true },
            { fieldName: "model", fieldTitle: "Model", required: true },
            {
              fieldName: "id",
              fieldTitle: "ID",
              visible: false,
              required: true,
            },
          ]}
          values={
            cameraLocations
              ? cameraLocations.map((location) => ({
                  longitude: location.lng,
                  latitude: location.lat,
                  locationOnProperty: location.locationOnProperty,
                  fieldOfView: location.fieldOfView,
                  make: location.make,
                  model: location.model,
                  id: location.id,
                }))
              : []
          }
          valuesOnChange={(values) => {
            const updatedLocations = values.map((value) => ({
              lat: value.latitude,
              lng: value.longitude,
              locationOnProperty: value.locationOnProperty,
              fieldOfView: value.fieldOfView,
              make: value.make,
              model: value.model,
              id: value.id,
            }));
            setCameraLocations(updatedLocations);
          }}
          panelName="Camera"
          removePanelText={disabled ? "" : "Remove Camera"}
          onRemovePanel={(panelIndex) => {
            if (!disabled) {
              const updatedLocations = cameraLocations
                ? cameraLocations.filter((_, index) => index !== panelIndex)
                : [];
              setCameraLocations(updatedLocations);
            }
          }}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default CustomMapElement;
