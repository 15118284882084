import {
  Badge,
  BadgeProps,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useSessionStorage } from "@react-hooks-library/core";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";

import ReportCard from "./ReportCard";
import style from "./ReportV2List.module.scss";
import ReportsMap from "./ReportsMap";
import ReportFilters, {
  IReportListFilterOptions,
  RenderReportFilterChips,
} from "./Search/ReportFilters";
import { OnlineReportingContext } from "./context";
import CondensedReportView from "./report-view/CondensedReportView";
import { getReportTitle } from "./report-view/utils";
import { assignedTo, lastUpdatedBy } from "./utils";
import { useDevEnvironment } from "../../DevContext";
import { useDrawer } from "../../common/SlideoutDrawer/DrawerContext";
import SlideOutDrawer from "../../common/SlideoutDrawer/SlideoutDrawer";
import { deleteBulletin } from "../../common/api-client/apiAlertClient";
import {
  GetRv2UsersResponse,
  SubmissionSummary,
} from "../../common/api-client/or-api";
import { API } from "../../common/api-client/or-api/api";
import { isSmallScreen } from "../../common/utils";
import {
  priorityColourTypes,
  reportColourTypes,
  statusTypes,
} from "../../types/typesReportV2";
import SearchBar from "../alerts/Search/SearchBar";

/**
 * Creates columns for the DataGrid and applies default settings
 * @param fields
 * @returns
 */
const createColumns = (fields: GridColDef[]) => {
  return fields.map((f: GridColDef) => {
    const field: GridColDef = {
      sortable: false,
      ...f,
    };
    return field;
  });
};

const generateRowData = (submission: SubmissionSummary) => {
  const rowData = {
    ...submission,
    survey_name: getReportTitle(
      submission.is_supplemental,
      submission.survey_name ?? "",
    ),
    time_updated: new Date(submission.time_updated),
    time_created: new Date(submission.time_created),
    time_submitted: submission.time_submitted
      ? new Date(submission.time_submitted)
      : new Date(submission.time_created),
    tracking_number: `${submission.tracking_number ?? ""} v${
      submission.latest_version
    }`,
    updated_by: lastUpdatedBy(submission),
    assigned_to: assignedTo(submission),
  };
  return rowData;
};

const ReportV2List = ({ cisoDisplay }: { cisoDisplay: boolean }) => {
  const {
    reports,
    reportTypeNames,
    totalReportsCount,
    fetchLatestReports,
    fetchReportConfigurations,
    searchText,
    setSearchText,
    filtersVisible,
    setFiltersVisible,
    searchView,
    setSearchView,
    getSubmissionForID,
    fetchRv2Users,
  } = useContext(OnlineReportingContext);
  const { isOpen, openDrawer, closeDrawer } = useDrawer();
  const [selectedReport, setSelectedReport] = useState<any>(null);

  const { orgId } = useParams();
  const appName = window.location.pathname.split("/")[1];
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [loadingReports, setLoadingReports] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [deleteID, setDeleteID] = useState("");
  const navigate = useNavigate();
  const [filters, setFilters] = useSessionStorage<IReportListFilterOptions>(
    "reportFilters",
    {
      creationStartDate: null,
      creationEndDate: null,
      updateStartDate: null,
      updateEndDate: null,
      reportStatus: null,
      trackingNumber: null,
      reportType: null,
      assignedTo: null,
    },
  );

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 8,
  });

  const processDeletion = async () => {
    await deleteBulletin(deleteID);
    setDeleteID("");
    setDeleteOpen(false);
  };

  const { isDevEnv } = useDevEnvironment();

  useEffect(() => {
    async function fetchData() {
      setLoadingReports(true);
      await fetchReportConfigurations!(orgId ?? "");
      await fetchRv2Users(orgId ?? "");
      await fetchLatestReports!(
        orgId ?? "",
        filters.creationStartDate ?? undefined,
        filters.creationEndDate ?? undefined,
        filters.updateStartDate ?? undefined,
        filters.updateEndDate ?? undefined,
        filters.reportStatus
          ? decodeURIComponent(filters.reportStatus)
          : undefined,
        filters.trackingNumber ?? undefined,
        paginationModel.pageSize,
        paginationModel.page * paginationModel.pageSize,
        filters.reportType ?? undefined,
        searchText,
        filters.assignedTo ?? undefined,
      )
        .then(() => {
          setLoadingReports(false);
        })
        .catch((e) => {
          setLoadingReports(false);
          const message = e.message;
          toast.error(
            `Failed to fetch reports${isDevEnv ? `: ${message}` : ""}`,
          );
        });
    }

    if (
      (!loadingReports && initialized) ||
      sessionStorage.getItem("reportFilters") === null
    ) {
      // only needed on the initial load
      if (!initialized) {
        setFilters({
          ...filters,
          reportStatus: "submitted,modification_requested",
        });
      }
      fetchData();
    }
    setInitialized(true);
  }, [filters, paginationModel, searchText]);

  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    "& .MuiBadge-badge": {
      left: -1,
      top: 16,
      padding: "4px",
    },
  }));

  const rows = reports!
    .filter((report) => {
      return (
        (report.latest_version &&
          report.latest_version > 0 &&
          report.is_supplemental === false) ||
        (report.is_supplemental === true && report.is_linked === false)
      );
    })
    .map((report) => {
      return generateRowData(report);
    });

  const fields: GridColDef[] = [
    {
      field: "priority",
      headerName: "Priority",
      width: 65,
      renderCell: (params) => {
        return (
          <Chip
            label={params.value ?? "-"}
            color={
              priorityColourTypes[params.value]
                ? priorityColourTypes[params.value]
                : "default"
            }
          />
        );
      },
      filterable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      filterable: false,
      renderCell: (params) => {
        return (
          <Chip
            style={{
              opacity:
                params.value === "rejected" || params.value === "approved"
                  ? 0.6
                  : 1,
            }}
            label={
              statusTypes[params.value]
                ? statusTypes[params.value]
                : params.value
            }
            color={
              reportColourTypes[params.value]
                ? reportColourTypes[params.value]
                : "default"
            }
          />
        );
      },
    },
    {
      field: "assigned_to",
      headerName: "Assigned To",
      width: 200,
      filterable: false,
    },
    {
      field: "incident_number",
      headerName: "RMS Number",
      width: 150,
      sortable: false,
      filterable: false,
    },
    {
      field: "tracking_number",
      headerName: "Tracking Number",
      width: 150,
      sortable: false,
      filterable: false,
    },
    {
      field: "survey_name",
      headerName: "Report Type",
      width: 200,
      sortable: false,
      filterable: false,
    },
    {
      field: "first_name",
      headerName: "First Name",
      width: 150,
      filterable: false,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: 150,
      filterable: false,
    },
    {
      field: "time_updated",
      headerName: "Last Updated",
      width: 200,
      sortable: false,
      type: "dateTime",
      sortingOrder: ["desc", "asc"],
      filterable: false,
    },
    {
      field: "time_submitted",
      headerName: "Time Submitted",
      width: 200,
      sortable: false,
      type: "dateTime",
      sortingOrder: ["desc", "asc"],
      filterable: false,
    },
    {
      width: 200,
      field: "updated_by",
      headerName: "Updated By",
      filterable: false,
    },
  ];

  const columns = createColumns(fields);
  const mobileView = isSmallScreen();

  const mobileViewStyle = {
    height: "calc(100vh - 130px)",
    paddingTop: "16px",
    width: "95vw",
  };

  const desktopStyle = {
    height: "calc(100vh - 180px)",
    width: "calc(100% - 32px)",
    marginLeft: "16px",
    marginRight: "16px",
  };

  function updateSearch(updatedFilters: IReportListFilterOptions) {
    setFilters(updatedFilters);
  }

  const searchBarRef = useRef<HTMLInputElement | null>(null);

  // CISO view
  const topPageRef = useRef<HTMLInputElement | null>(null);
  const scrollToTop = () => {
    topPageRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  const bottomPageRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (
            entry.isIntersecting &&
            !loadingReports &&
            reports.length < totalReportsCount
          ) {
            setPaginationModel((prev) => ({
              page: 0,
              pageSize: prev.pageSize + 8,
            }));
          }
        });
      },
      { threshold: 1 },
    );

    const currentAnchor = bottomPageRef.current;
    if (currentAnchor) {
      observer.observe(currentAnchor);
    }

    return () => {
      if (currentAnchor) {
        observer.unobserve(currentAnchor);
      }
    };
  }, [bottomPageRef, loadingReports]);

  const renderSearchBar = () => {
    return (
      <SearchBar
        getMoreItems={() => {}}
        onSearch={scrollToTop}
        onClearSearch={scrollToTop}
        searchView={searchView}
        setSearchView={setSearchView}
        defaultView="list"
        searchText={searchText}
        setSearchText={setSearchText}
        currentItems={reports ?? []}
        setCurrentItems={() => {}}
        filtersVisible={filtersVisible}
        setFiltersVisible={setFiltersVisible}
        totalAlerts={totalReportsCount}
        loading={loadingReports}
        setLoading={() => {}}
        searchType="report"
        filtersRef={searchBarRef}
        filterChips={
          <RenderReportFilterChips
            filters={filters}
            setFilters={updateSearch}
          />
        }
        activeViews={cisoDisplay ? ["list", "card", "map"] : []}
      />
    );
  };

  const renderFilters = () => {
    return (
      <ReportFilters
        isOpen={filtersVisible}
        anchorEl={searchBarRef.current}
        onClose={() => setFiltersVisible(false)}
        filters={filters}
        setFilters={updateSearch}
      />
    );
  };

  const handleRowClick = (report) => {
    if (!selectedReport || selectedReport.id !== report.id) {
      setSelectedReport(report);
      openDrawer();
    }
  };

  const renderListView = () => {
    return (
      <DataGrid
        rows={rows}
        columns={columns}
        autoPageSize
        rowSelection={false}
        sx={{
          "& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell":
            {
              whiteSpace: "normal",
              wordWrap: "break-word",
            },
        }}
        onPaginationModelChange={(params) => {
          console.log(params);
          setPaginationModel(params);
        }}
        initialState={{ pagination: { paginationModel } }}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        rowCount={totalReportsCount}
        loading={loadingReports}
        paginationMode="server"
        paginationModel={paginationModel}
        onRowClick={(row) => {
          if (cisoDisplay) {
            handleRowClick(row.row);
          } else {
            navigate(`/${appName}/${orgId}/report/${row.id}/condensed`);
          }
        }}
        getRowClassName={(params) => {
          if (selectedReport && selectedReport.id === params.id) {
            return style.selectedRow;
          }
          return style.reportRow;
        }}
      />
    );
  };

  const renderCardView = () => {
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: "16px",
        }}
      >
        {reports.length > 0 &&
          reports.map((report, index) => (
            <ReportCard
              key={report.id}
              report={report}
              onClick={() => {
                if (cisoDisplay) {
                  setSelectedReport(report);
                  openDrawer();
                } else {
                  navigate(
                    `/${appName}/${orgId}/report/${report.id}/condensed`,
                  );
                }
              }}
              index={index}
              reportType={reports[index]?.survey_name as any}
              className={
                selectedReport && selectedReport.id === report.id
                  ? style.selectedCard
                  : ""
              }
            />
          ))}
        <div ref={bottomPageRef}></div>
        {loadingReports && <CircularProgress />}
        {/* Pagination right now just loads more, this needs to be changed to cache previous list items */}
        {/* <Button
          onClick={() => {

          }}
          style={{
            margin: "16px",
            display: "block",
            marginLeft: "auto"
          }}
        >
          Load More
        </Button> */}
      </div>
    );
  };

  const renderMapView = () => {
    return (
      <ReportsMap
        reports={reports}
        reportClickHandler={(report) => {
          if (cisoDisplay) {
            openDrawer();
          } else navigate(`/${appName}/${orgId}/report/${report.id}/condensed`);
        }}
        loadMore={() => {}}
        isFetchingNextPage={false}
        hasNextPage={false}
        totalReports={totalReportsCount}
        selectedReport={selectedReport}
        setSelectedReport={setSelectedReport}
      />
    );
  };

  return (
    <SlideOutDrawer
      width={240}
      slideoutLocation="right"
      slideoutContent={
        selectedReport ? (
          <div>
            <CondensedReportView
              cisoDisplay={cisoDisplay}
              idOverrride={selectedReport.id}
            />
          </div>
        ) : (
          <p>Select a report to see details.</p>
        )
      }
      mainContent={
        <div
          style={{
            position: "relative",
          }}
        >
          <div style={mobileView ? mobileViewStyle : desktopStyle}>
            <div className="search-bar-container">
              {renderSearchBar()}
              {filtersVisible && renderFilters()}
            </div>
            {cisoDisplay && searchView === "list" && renderListView()}
            {cisoDisplay && searchView === "card" && renderCardView()}
            {cisoDisplay && searchView === "map" && renderMapView()}
            {!cisoDisplay && renderListView()}
          </div>
          <Dialog
            open={deleteOpen}
            onClose={() => setDeleteOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Delete Draft Alert?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete draft bulletin with ID{" "}
                {deleteID}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDeleteOpen(false)}>No</Button>
              <Button onClick={processDeletion} autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      }
      onDrawerClose={() => {
        setSelectedReport(null);
        closeDrawer();
      }}
    />
  );
};

export default ReportV2List;
