import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ComponentCollection, surveyLocalization } from "survey-core";
import "survey-core/defaultV2.min.css";
import { ICreatorOptions, editorLocalization } from "survey-creator-core";
import "survey-creator-core/survey-creator-core.min.css";
import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";

import { SurveyModel } from "../../../common/api-client/or-api";
import { ADDRESS_AUTOCOMPLETE_TYPE } from "../custom-questions/AddressAutocompleteQuestion";
import { CAMERA_REGISTRY_MAP_TYPE } from "../custom-questions/CameraRegistrySJS/CameraRegistryQuestion";

const FormBuilder = ({
  originalSurveyModel,
  onSave,
  mode,
}: {
  originalSurveyModel: SurveyModel;
  onSave: (surveyModel: SurveyModel) => void;
  mode: "edit" | "create" | "view";
}) => {
  const { i18n } = useTranslation();

  const creatorOptions: ICreatorOptions = {
    isAutoSave: true,
    readOnly: mode === "view",
    showPreviewTab: true,
    showDesignerTab: mode === "edit" || mode === "create",
    showJSONEditorTab: mode === "edit" || mode === "create",
    showLogicTab: mode === "edit" || mode === "create",
    showTranslationTab: mode === "edit" || mode === "create",
  };

  const creator = new SurveyCreator(creatorOptions);

  const supportedLocales = ["en", "fr"];

  const addressAutoCompleteButton =
    ComponentCollection.Instance.getCustomQuestionByName(
      "custom-address-autocomplete",
    );

  if (!addressAutoCompleteButton) {
    ComponentCollection.Instance.add({
      name: "custom-address-autocomplete",
      title: "Address Autocomplete",
      questionJSON: {
        type: ADDRESS_AUTOCOMPLETE_TYPE,
        title: "Address Autocomplete",
        isRequired: true,
      },
    });
  }

  const cameraRegistryButton =
    ComponentCollection.Instance.getCustomQuestionByName(
      "custom-camera-registry",
    );

  if (!cameraRegistryButton) {
    ComponentCollection.Instance.add({
      name: "custom-camera-registry",
      title: "Camera Registry",
      questionJSON: {
        type: CAMERA_REGISTRY_MAP_TYPE,
        title: "Camera Registry",
        isRequired: true,
      },
    });
  }

  creator.saveSurveyFunc = (saveNumber, callback) => {
    const updatedSurveyModel: SurveyModel = {
      ...initialSurveyModel,
      configuration: JSON.parse(creator.text),
    };
    onSave(updatedSurveyModel);
    callback(saveNumber, true); // Indicate successful save
  };

  const [initialSurveyModel, setInitialSurveyModel] =
    useState(originalSurveyModel);

  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    setInitialSurveyModel(originalSurveyModel);
  }, [originalSurveyModel]);

  if (initialSurveyModel) {
    if (typeof initialSurveyModel.configuration === "string") {
      creator.text = initialSurveyModel.configuration;
    } else {
      creator.text = JSON.stringify(initialSurveyModel.configuration);
    }
  }

  const currentSurveyState = creator.text;
  surveyLocalization.supportedLocales = supportedLocales;
  // * creator.onMachineTranslate.add((_, options) => {
  // *   // Prepare strings for Microsoft Translator as an array of objects: [{ Text: "text to translate" }]
  // *   const data = [];
  // *   options.strings.forEach(str => { data.push({ Text: str }); });
  // *   // Include required locales in the URL
  // *   const params = "api-version=3.0&from=" + options.fromLocale + "&to=" + options.toLocale;
  // *   const url = endpoint + "/translate?" + params;
  // *   fetch(url, {
  // *     method: "POST",
  // *     headers: {
  // *       "Content-Type": "application/json",
  // *       "Ocp-Apim-Subscription-Key": apiKey,
  // *       "Ocp-Apim-Subscription-Region": resourceRegion,
  // *       "X-ClientTraceId": crypto.randomUUID()
  // *     },
  // *     body: JSON.stringify(data)
  // *   }).then(response => response.json())
  // *     .then(data => {
  // *       // Convert data received from Microsoft Translator to a flat array
  // *       const translatedStrings = [];
  // *       for (let i = 0; i < data.length; i++) {
  // *         translatedStrings.push(data[i].translations[0].text);
  // *       }
  // *       // Pass translated strings to Survey Creator
  // *       options.callback(translatedStrings);
  // *
  // *     }).catch(error => {
  // *       // If translation was unsuccessful:
  // *       options.callback();
  // *       alert("Could not translate strings to the " + options.toLocale + " locale");
  // *     });
  // * });
  creator.onMachineTranslate.add((_, options) => {
    const data: { Text: string }[] = [];
    options.strings.forEach((str: string) => {
      data.push({ Text: str });
    });
    console.log(data);
  });

  useEffect(() => {
    creator.locale = i18n.language;
    editorLocalization.currentLocale = i18n.language;
    surveyLocalization.currentLocale = i18n.language;
  }, [i18n.language]);

  useEffect(() => {
    if (currentSurveyState !== initialSurveyModel.configuration) {
      setIsDirty(true);
    }
  }, [currentSurveyState]);

  return (
    <SurveyCreatorComponent creator={creator} style={{ height: "100%" }} />
  );
};

export default FormBuilder;
