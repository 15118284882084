import {
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { apiAlertClient } from "../../../common/api-client";
import { mailTo } from "../../../common/utils";
import { AuthStateContext } from "../../../context";
import { IUserPermission } from "../../../types";
import { DisabledComponentDialog } from "../../DisabledComponent";
import { parseUserPermissions } from "../../UserManagement/utils";

function AlertAnalytics() {
  const [iframeUrl, setIframeUrl] = useState("");
  const [isReadOnlyDialogOpen, setIsReadOnlyDialogOpen] = useState(false);
  const [selectedGroupID, setSelectedGroupID] = useState(0);
  const navigate = useNavigate();
  const { currentUserGroups, currentUser } = useContext(AuthStateContext);

  const groupsToSelect = currentUserGroups.filter(
    (group) => !group.is_self_group,
  );

  const [alertPermissions] = useState(
    currentUser &&
      parseUserPermissions(currentUser?.permissions as IUserPermission[]),
  );
  const readOnly = alertPermissions
    ? !alertPermissions.canAccessDashboard
    : false;

  const getMetabaseURL = () => {
    if (selectedGroupID === 0) return;

    apiAlertClient.getMetabaseURL(selectedGroupID).then((res) => {
      setIframeUrl(res.metabase_url);
    });
  };

  const updateSelectedGroup = (groupID: number) => {
    setSelectedGroupID(groupID);
  };

  useEffect(() => {
    if (groupsToSelect.length > 0) {
      setSelectedGroupID(groupsToSelect[0].id);
    }
  }, [currentUserGroups]);

  useEffect(() => {
    if (readOnly) {
      setIsReadOnlyDialogOpen(true);
    }
  }, [readOnly]);

  useEffect(() => {
    if (!readOnly) getMetabaseURL();
  }, [selectedGroupID]);

  if (readOnly) {
    return (
      <DisabledComponentDialog
        isDialogOpen={isReadOnlyDialogOpen}
        handleClose={() => {
          navigate(`/rubialert/alerts`);
        }}
        title="You do not have access to view alert analytics."
        message={
          <Typography>
            Please contact{" "}
            <Link
              rel="noopener noreferrer"
              target="_blank"
              href={mailTo({
                email: "support@tryrubicon.com",
                subject: "Requesting Access to View Alert Analytics",
                body: `Hello Rubicon Support Team, 

                I would like to request access to view alert analytics.

                Thank you. 

                Sincerely,

                ${currentUser?.first_name} ${currentUser?.last_name} 
                ${currentUser?.email}`,
              })}
              color="secondary"
            >
              support@tryrubicon.com
            </Link>{" "}
            to request access.
          </Typography>
        }
      />
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          padding: "8px",
        }}
      >
        <FormControl>
          <InputLabel id="selected-group-label">
            View Dashboard for Group
          </InputLabel>
          <Select
            labelId="selected-group-label"
            id="selected-group"
            value={selectedGroupID}
            label="Selected Group"
            sx={{ minWidth: "300px", backgroundColor: "white" }}
            onChange={(event) =>
              updateSelectedGroup(event.target.value as number)
            }
          >
            {groupsToSelect.map((group) => (
              <MenuItem key={group.id} value={group.id}>
                {group.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <iframe
        src={iframeUrl}
        style={{
          flexGrow: 1,
        }}
        allowTransparency
      ></iframe>
    </div>
  );
}

export default AlertAnalytics;
