import React from "react";
import { useSearchParams } from "react-router-dom";

const HighlightText = ({ text }) => {
  const [searchParams] = useSearchParams();
  const searchText = searchParams.get("searchText");

  if (!searchText || !text) return <p>{text}</p>;

  const highlightsArray = searchText.split(" ");

  // Regex for exact match and partial matches
  const exactMatchRegex = new RegExp(`\\b(${searchText})\\b`, "gi");
  const partialMatchRegex = new RegExp(
    `\\b(${highlightsArray.join("|")})\\b`,
    "gi",
  );

  // Step 1: Split by exact match, marking exact matches
  const initialParts = text.split(exactMatchRegex);

  const finalParts = initialParts.flatMap((part) => {
    if (part.toLowerCase() === searchText.toLowerCase()) {
      // This is an exact match
      return [{ text: part, type: "exact" }];
    }

    // Step 2: Further split non-exact parts by partial matches
    return part.split(partialMatchRegex).map((subPart) => {
      if (
        highlightsArray.some(
          (word) => word.toLowerCase() === subPart.toLowerCase(),
        )
      ) {
        // This is a partial match
        return { text: subPart, type: "partial" };
      }

      // Regular text
      return { text: subPart, type: "normal" };
    });
  });

  return (
    <p>
      {finalParts.map((part, index) => {
        if (part.type === "exact") {
          return (
            <span key={index} className="bg-full-match">
              {part.text}
            </span>
          );
        }
        if (part.type === "partial") {
          return (
            <span key={index} className="bg-partial-match">
              {part.text}
            </span>
          );
        }
        return part.text;
      })}
    </p>
  );
};

export default HighlightText;
