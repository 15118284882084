import { Dialog, Link, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import styles from "./ShareDialog.module.scss";
import { NotifyEmails } from "./add/NotifyEmails";
import { ShareAccess } from "./add/ShareAccess";
import { mailTo } from "../../common/utils";
import { AuthStateContext } from "../../context";
import { IUserPermission } from "../../types";
import { DisabledComponentBanner } from "../DisabledComponent";
import { parseUserPermissions } from "../UserManagement/utils";

const ShareDialog = ({
  isShareModalOpenArg,
  setIsShareModalOpenArg,
  bulletinID,
}: {
  isShareModalOpenArg: boolean;
  setIsShareModalOpenArg: (arg: boolean) => void;
  bulletinID: number;
}) => {
  const [sharedFlag, setSharedFlag] = useState(false);
  const [sentFlag, setSentFlag] = useState(false);
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (sharedFlag && sentFlag) {
      closeShareDialog();
    }
  }, [sharedFlag, sentFlag, setIsShareModalOpenArg]);

  const closeShareDialog = () => {
    setIsShareModalOpenArg(false);
    const newSearchParams = new URLSearchParams(search);
    newSearchParams.delete("share");
    navigate(`${pathname}?${newSearchParams.toString()}`);
  };

  const { currentUser } = useContext(AuthStateContext);

  const [alertPermissions] = useState(
    parseUserPermissions(currentUser?.permissions as IUserPermission[]),
  );

  const readOnly = !alertPermissions.canCreateBulletin;

  return (
    <Dialog
      open={isShareModalOpenArg}
      onClose={closeShareDialog}
      className={styles.shareDialog}
    >
      {readOnly && (
        <DisabledComponentBanner
          handleClose={closeShareDialog}
          title="You do not have permission to share this bulletin."
          message={
            <Typography>
              Please contact{" "}
              <Link
                rel="noopener noreferrer"
                target="_blank"
                href={mailTo({
                  email: "support@tryrubicon.com",
                  subject: "Requesting Access to Share Bulletins",
                  body: `Hello Rubicon Support Team, 

                  I would like to request access to share bulletins. I am trying to share a bulletin with id ${bulletinID}.

                  Thank you. 

                  Sincerely,
                  
                  ${currentUser?.first_name} ${currentUser?.last_name} 
                  ${currentUser?.email}`,
                })}
                color="secondary"
              >
                support@tryrubicon.com
              </Link>{" "}
              to request access.
            </Typography>
          }
        />
      )}
      <div className={styles.shareDialogContainer}>
        <ShareAccess
          bulletinID={bulletinID}
          setSharedFlag={setSharedFlag}
          readOnly={readOnly}
        />
        <NotifyEmails
          bulletinID={bulletinID}
          setSentFlag={setSentFlag}
          readOnly={readOnly}
        />
      </div>
    </Dialog>
  );
};

export default ShareDialog;
