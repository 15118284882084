/**
 * devEnvironment - This is a flag to determine if the app is running in a development environment.
 * @returns {boolean} - Returns true if the app is running in a development environment.
 */
export const devEnvironment = () => {
  const isLocalhost =
    window.location.href.includes("localhost") ||
    window.location.href.includes("127.0.0.1");
  const isTest = window.location.href.includes("test");
  return isLocalhost || isTest;
};
