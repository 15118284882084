import { Box, Button, Chip, Grid, Popover } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

export const FilterChip = ({
  title,
  value,
  onRemove,
  displayFullLabel,
  customChipValueDisplay,
  disableRemove,
}: {
  title: string;
  value?: string;
  onRemove: () => void;
  displayFullLabel?: boolean;
  customChipValueDisplay?: (value: string) => string;
  disableRemove?: boolean;
}) => (
  <Chip
    label={
      displayFullLabel
        ? `${title}: ${customChipValueDisplay ? customChipValueDisplay(value || "") : value}`
        : customChipValueDisplay
          ? customChipValueDisplay(value || "")
          : value
    }
    onDelete={disableRemove ? undefined : onRemove}
    color="secondary"
    variant="filled"
  />
);

/**
 * Renders a list of filter chips and a "Clear Filters" button.
 */
export const RenderFilterChips = ({
  filterChipsActive,
  onClearAll,
  hideClearAll,
}: {
  filterChipsActive: (JSX.Element | null)[];
  onClearAll: () => void;
  hideClearAll?: boolean;
}) => {
  const hasActiveFilters = filterChipsActive.some((filter) => filter !== null);
  if (hasActiveFilters === false) return null;
  else
    return (
      <Box
        sx={{ display: "flex", flexWrap: "wrap", gap: 1, alignItems: "center" }}
      >
        {filterChipsActive}

        {!hideClearAll && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={onClearAll}
            size="small"
            sx={{
              borderRadius: 50,
            }}
          >
            Clear
          </Button>
        )}
      </Box>
    );
};

export const DateRangePickerCustom = ({
  labelFrom,
  labelTo,
  startDate,
  endDate,
  onStartChange,
  onEndChange,
}: {
  labelFrom: string;
  labelTo: string;
  startDate: string;
  endDate: string;
  onStartChange: (newValue: string) => void;
  onEndChange: (newValue: string) => void;
}) => (
  <Grid container spacing={2}>
    <Grid item xs={6}>
      <DatePicker
        label={labelFrom}
        value={startDate ? dayjs(startDate) : null}
        onChange={(newValue) =>
          onStartChange(newValue?.toISOString().split("T")[0] || "")
        }
        slotProps={{
          textField: {
            variant: "outlined",
            fullWidth: true,
          },
          actionBar: {
            actions: ["today", "clear"],
          },
        }}
        maxDate={endDate ? dayjs(endDate) : null}
      />
    </Grid>
    <Grid item xs={6}>
      <DatePicker
        label={labelTo}
        value={endDate ? dayjs(endDate) : null}
        onChange={(newValue) =>
          onEndChange(newValue?.toISOString().split("T")[0] || "")
        }
        slotProps={{
          textField: { variant: "outlined", fullWidth: true },
          actionBar: {
            actions: ["today", "clear"],
          },
        }}
        minDate={startDate ? dayjs(startDate) : null}
      />
    </Grid>
  </Grid>
);

interface SearchBarFiltersProps {
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  gridElements: JSX.Element[];
}
export const SearchBarFilters = ({
  isOpen,
  anchorEl,
  onClose,
  gridElements,
}: SearchBarFiltersProps) => {
  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      sx={{ marginTop: 1 }}
    >
      <Grid
        container
        spacing={2}
        justifyContent="flex-start"
        alignItems="center"
        sx={{
          padding: 2,
          width: { xs: "100%", sm: anchorEl?.clientWidth || "auto" },
          marginBottom: 1,
        }}
      >
        {gridElements}
      </Grid>
    </Popover>
  );
};
