import { Autocomplete, Chip, TextField } from "@mui/material";

import { getReportTitle } from "./utils";
import StyledBadge from "../../../common/StyledBadge";
import { SubmissionSummary } from "../../../common/api-client/or-api";
import { formatDateShort } from "../../../common/utils";
import { reportColourTypes, statusTypes } from "../../../types/typesReportV2";

interface LinkSupplementaryDropdownProps {
  reportList: SubmissionSummary[];
  selectedReportToLink: SubmissionSummary | null;
  setSelectedReportToLink: (report: SubmissionSummary | null) => void;
  setIsLinkingDialogOpen: (open: boolean) => void;
}

const LinkSupplementaryDropdown = ({
  reportList,
  selectedReportToLink,
  setSelectedReportToLink,
  setIsLinkingDialogOpen,
}: LinkSupplementaryDropdownProps) => {
  return (
    <Autocomplete
      options={[...reportList]}
      getOptionLabel={(option) => {
        const title = getReportTitle(
          option.is_supplemental,
          option.survey_name ?? "",
        );
        return `${option.tracking_number} ${option.incident_number ? `(${option.incident_number})` : ""} - ${title} - ${formatDateShort(
          option.time_created,
        )}`;
      }}
      value={selectedReportToLink ?? null}
      onChange={(event, newValue) => {
        if (newValue && newValue.id) {
          setSelectedReportToLink(newValue);
          setIsLinkingDialogOpen(true);
        } else {
          setSelectedReportToLink(null);
        }
      }}
      size="medium"
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Report to Link to Supplementary Report"
          variant="outlined"
          fullWidth
        />
      )}
      renderOption={(props, option) => {
        const title = getReportTitle(
          option.is_supplemental,
          option.survey_name ?? "",
        );
        return (
          <li
            {...props}
            style={{
              width: "100%",
              padding: "8px 12px",
              backgroundColor: "#fff",
              borderRadius: "8px",
              marginBottom: "8px",
              boxShadow:
                "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
              cursor: "pointer",
            }}
            onMouseEnter={(e) =>
              (e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)")
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.boxShadow =
                "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)")
            }
          >
            <StyledBadge
              color="error"
              variant="dot"
              invisible={option.status !== "submitted"}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Chip
                style={{
                  opacity:
                    option.status === "rejected" || option.status === "approved"
                      ? 0.6
                      : 1,
                  maxWidth: "100px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
                label={
                  statusTypes[option.status]
                    ? statusTypes[option.status]
                    : option.status
                }
                color={
                  reportColourTypes[option.status]
                    ? reportColourTypes[option.status]
                    : "default"
                }
              />
            </StyledBadge>
            <div style={{ marginLeft: "12px", flex: 1 }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span style={{ fontWeight: "bold" }}>
                  {option.tracking_number}{" "}
                  {option.incident_number ? `(${option.incident_number})` : ""}
                </span>
                <span style={{ fontStyle: "italic", color: "#666" }}>
                  {formatDateShort(option.time_created)}
                </span>
              </div>
              <div>
                {title} - {option.first_name} {option.last_name}
              </div>
            </div>
          </li>
        );
      }}
      sx={{
        width: "600px",
      }}
      filterOptions={(options, params) => {
        const filtered = options.filter((option) => {
          return (
            (option.survey_name || "survey_name")
              .toLowerCase()
              .includes(params.inputValue.toLowerCase()) ||
            option.id.toLowerCase().includes(params.inputValue.toLowerCase()) ||
            `${option.first_name} ${option.last_name}`
              .toLowerCase()
              .includes(params.inputValue.toLowerCase()) ||
            formatDateShort(option.time_created)
              .toLowerCase()
              .includes(params.inputValue.toLowerCase()) ||
            option.tracking_number
              ?.toLowerCase()
              .includes(params.inputValue.toLowerCase()) ||
            option.incident_number
              ?.toLowerCase()
              .includes(params.inputValue.toLowerCase())
          );
        });

        return filtered;
      }}
      noOptionsText="No reports found"
    />
  );
};

export default LinkSupplementaryDropdown;
