import React, { useRef, useEffect, useState, useCallback } from "react";

import { colors } from "./colors";

interface LazyLoadVideoProps {
  src: string;
  className?: string;
  controls?: boolean;
  preload?: "none" | "metadata" | "auto";
  poster?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  muted?: boolean;
  handleLoad?: () => void;
  handleVideoErrorClick?: () => void;
  errorMessage?: string;
}

const LazyLoadVideo: React.FC<LazyLoadVideoProps> = ({
  src,
  className,
  controls = true,
  preload = "metadata",
  poster,
  onClick,
  style,
  muted,
  handleLoad,
  handleVideoErrorClick,
  errorMessage = "Video failed to load in browser. Try uploading in H.264 codec format.",
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isInView, setIsInView] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [error, setError] = useState(false);
  const [userInteracted, setUserInteracted] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsInView(entry.isIntersecting);
          if (entry.isIntersecting && !isLoaded) {
            setIsLoaded(true); // Load the video only once when it first comes into view
          }
        });
      },
      { threshold: 0.25 },
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, [isLoaded]);

  useEffect(() => {
    const video = videoRef.current;
    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);

    video?.addEventListener("play", handlePlay);
    video?.addEventListener("pause", handlePause);

    return () => {
      video?.removeEventListener("play", handlePlay);
      video?.removeEventListener("pause", handlePause);
    };
  }, []);

  const handleMouseEnter = useCallback(() => {
    if (isInView && isLoaded && !error && userInteracted && !controls) {
      videoRef.current?.play().catch((e) => console.warn(e));
    }
  }, [isInView, isLoaded, error, userInteracted, controls]);

  const handleMouseLeaveOrOutofView = useCallback(() => {
    if (!controls && isPlaying) {
      videoRef.current?.pause();
    }
  }, [controls, isPlaying]);

  // Pause the video if it goes out of view or mouse leaves
  useEffect(() => {
    if (!isInView || !userInteracted) {
      handleMouseLeaveOrOutofView();
    }
  }, [isInView, handleMouseLeaveOrOutofView, userInteracted]);

  const handleError = useCallback(() => {
    setError(true);
    console.error("Error loading video");
  }, []);

  const handleUserInteraction = useCallback(() => {
    setUserInteracted(true);
    if (isInView && isLoaded && !error) {
      videoRef.current?.play().catch((e) => console.warn(e));
    }
  }, [isInView, isLoaded, error]);

  if (!error) {
    return (
      <div
        style={{
          position: "relative",
          overflow: "hidden",
          ...style,
        }}
        onClick={handleUserInteraction}
        // onMouseEnter={handleUserInteraction}
      >
        <video
          ref={videoRef}
          src={isLoaded && !error ? src : undefined}
          className={className}
          controls={controls}
          preload={preload}
          poster={poster}
          onClick={onClick}
          // onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeaveOrOutofView}
          style={{
            width: "100%",
            height: "auto",
            objectFit: "contain",
          }}
          muted={muted}
          onError={handleError}
          onLoadedMetadata={handleLoad}
          controlsList="nodownload"
        >
          Loading video...
        </video>
        {!isPlaying && !controls && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              fontSize: "4rem",
              color: "white",
              pointerEvents: "none",
              textShadow: "0 0 20px rgba(0, 0, 0, 0.7)",
            }}
          >
            &#9658;
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      style={{
        border: `1px solid ${colors.MUI_TEXT_SECONDARY}`,
        color: colors.MUI_TEXT_SECONDARY,
        textAlign: "center",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...style,
      }}
      onClick={handleVideoErrorClick}
    >
      <div
        style={{
          padding: "1rem",
          textAlign: "center",
          position: "relative",
          width: "80%",
          ...style,
        }}
      >
        {errorMessage}
      </div>
    </div>
  );
};

export default LazyLoadVideo;
