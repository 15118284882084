import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import datePickerEN from "./locales/en/bulletin/create/datePicker.json";
import bulletinEN from "./locales/en/bulletin.json";
import datePickerFR from "./locales/fr/bulletin/create/datePicker.json";
import bulletinFR from "./locales/fr/bulletin.json";

i18next.use(initReactI18next).init({
  resources: {
    en: {
      bulletin: bulletinEN,
      "bulletin/create/datePicker": datePickerEN,
    },
    fr: {
      bulletin: bulletinFR,
      "bulletin/create/datePicker": datePickerFR,
    },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
