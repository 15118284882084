import "./FormManagement.scss";
import { Add } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";

import { useFormBuilderContext } from "./FormContextProvider";
import { LinkCell } from "../../../common/DataGridCells";
import { API } from "../../../common/api-client/or-api/api";

const useDynamicColumnWidths = (topPageRef) => {
  const calculateColumnWidths = useCallback((parentWidth) => {
    const baseWidth = parentWidth
      ? parentWidth * 0.85
      : window.innerWidth * 0.85;

    const ratios = {
      icon: 0.5,
      name: 3,
      survey_class: 2,
      is_supplemental: 1,
      hidden: 1,
    };
    const totalRatios = Object.values(ratios).reduce(
      (acc, val) => acc + val,
      0,
    );

    const columnWidths = {};
    for (const [key, value] of Object.entries(ratios)) {
      columnWidths[key] = (baseWidth / totalRatios) * value;
    }

    return columnWidths;
  }, []);

  const [columnWidths, setColumnWidths] = useState<{
    [key: string]: number;
  }>(() =>
    calculateColumnWidths(topPageRef.current?.parentElement?.offsetWidth),
  );

  useEffect(() => {
    const handleResize = () => {
      const parentWidth = topPageRef.current?.parentElement?.offsetWidth;
      setColumnWidths(calculateColumnWidths(parentWidth));
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [topPageRef, calculateColumnWidths]);

  return columnWidths;
};

const FormListView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const orgId = params.orgId;

  const { surveyConfigs, setSurveyConfigs, setSurveyModels } =
    useFormBuilderContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [configsResponse, surveysResponse] = await Promise.all([
          API.orgConfig.get(orgId ?? ""),
          API.survey.getOrgSurveys(orgId ?? ""),
        ]);
        setSurveyConfigs(configsResponse?.surveys || []);
        setSurveyModels(surveysResponse?.surveys || []);
      } catch (error) {
        navigate("/");
      }
    };

    fetchData();
  }, [orgId, navigate]);

  const topPageRef = useRef<HTMLInputElement | null>(null);
  const columnWidths = useDynamicColumnWidths(topPageRef);

  const renderLinkCell = (params, path) => (
    <LinkCell
      value={params.value}
      onClick={() =>
        navigate(path, {
          replace: false,
          state: { orgId },
        })
      }
    />
  );

  const createNewFormHandler = () => {
    navigate(`./new`, {
      replace: false,
      state: { orgId },
    });
  };

  const columns = [
    {
      field: "icon",
      headerName: "",
      width: columnWidths.icon,
      renderCell: (params) => {
        const iconUrl = params.row.description?.image_url;
        return (
          <div className="icon-cell">
            {iconUrl ? <img src={iconUrl} alt="" /> : "❔"}
          </div>
        );
      },
      sortable: false,
    },
    {
      field: "name",
      headerName: "Name",
      width: columnWidths.name,
      renderCell: (params) =>
        renderLinkCell(params, `./${params.row.survey_class}`),
    },
    {
      field: "survey_class",
      headerName: "Survey Class",
      width: columnWidths.survey_class,
    },
    {
      field: "is_supplemental",
      headerName: "Supplemental",
      width: columnWidths.is_supplemental,
      type: "boolean",
    },
    {
      field: "hidden",
      headerName: "Hidden",
      width: columnWidths.hidden,
      type: "boolean",
    },
  ];

  return (
    <div className="form_list_container">
      <div id="top-page-div" ref={topPageRef}></div>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12} sm={6}>
          <Typography variant="h4" gutterBottom>
            Forms Management
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={createNewFormHandler}
            startIcon={<Add />}
            className="create-form-button"
          >
            Create Form
          </Button>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Survey Configurations
      </Typography>
      <DataGrid
        rows={surveyConfigs}
        columns={columns}
        rowSelection={false}
        getRowId={(row) => row.survey_id}
        className="form-list-datagrid"
      />
    </div>
  );
};

export default FormListView;
