import {
  Button,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";

import ReportFilterButtons from "./ReportFilterButtons";
import { generateTextMessageBody } from "./utils";
import { useDevEnvironment } from "../../../DevContext";
import { SurveyConfiguration } from "../../../common/api-client/or-api";
import { API } from "../../../common/api-client/or-api/api";
import { getServiceConfig } from "../../../common/serviceConfigurations";
import { AuthStateContext } from "../../../context";
import { OnlineReportingContext } from "../context";

const CitizenEngage = () => {
  const { orgId } = useParams();

  const { isDevEnv } = useDevEnvironment();
  const { fetchReportConfigurations, reportConfigurations } = useContext(
    OnlineReportingContext,
  );
  const { currentUser } = useContext(AuthStateContext);

  const [callerFirstName, setCallerFirstName] = useState("");
  const [callerLastName, setCallerLastName] = useState("");
  const [areaCode, setAreaCode] = useState("+1");
  const [callerPhoneNumber, setCallerPhoneNumber] = useState("");
  const [selectedReport, setSelectedReport] =
    useState<SurveyConfiguration | null>(null);
  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString(),
  );

  useEffect(() => {
    fetchReportConfigurations(orgId ?? "");
  }, []);

  const organizationConfig = getServiceConfig(
    orgId ?? currentUser?.rubi_org_id ?? "NA",
    isDevEnv,
  );
  const { servicesURLSlug, fullName } = organizationConfig;

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const validatePhoneNumber = (phoneNumber: string) => {
    return phoneNumber.match(/^\d{10}$/);
  };

  const putCallLog = async () => {
    try {
      const res = await API.callLog.putCallLog(orgId ?? "", {});
      return res.call_log_id;
    } catch (err) {
      console.error(err);
      toast.error("❌ Failed to create call log. Please try again.");
      throw err;
    }
  };

  const patchCallLog = async (callLogId: string) => {
    try {
      if (!selectedReport) {
        toast.error("⚠️ Please select a report.");
        return;
      }

      await API.callLog.patchCallLog(orgId ?? "", callLogId, {
        first_name: callerFirstName ?? null,
        last_name: callerLastName ?? null,
        phone_number: `${areaCode}${callerPhoneNumber}`,
        email: null,
        location: null,
        internal_notes: null,
        message: generateTextMessageBody(
          selectedReport.name,
          callerFirstName,
          callerLastName,
          servicesURLSlug ?? "",
          selectedReport,
          fullName ?? "",
          isDevEnv,
        ),
      });
    } catch (err) {
      console.error(err);
      toast.error("❌ Failed to update call log. Please try again.");
    }
  };

  const handleSendText = async () => {
    if (!callerPhoneNumber) {
      toast.error("⚠️ Please enter a valid phone number.");
      return;
    }
    if (!selectedReport) {
      toast.error("⚠️ Please select a report.");
      return;
    }

    try {
      const callId = await putCallLog();
      await patchCallLog(callId);
      console.log("Successfully created and updated call log");
      toast.success("✅ Report link sent successfully!");
    } catch (err) {
      console.error("Error in creating or updating call log", err);
      toast.error("❌ Failed to send report link. Please try again.");
    }
  };

  const handleClearForm = () => {
    setCallerFirstName("");
    setCallerLastName("");
    setAreaCode("+1");
    setCallerPhoneNumber("");
    setSelectedReport(null);
  };

  return (
    <div style={{ padding: "20px", maxWidth: "800px", margin: "auto" }}>
      <Paper elevation={6} style={{ padding: "30px", marginTop: "20px" }}>
        <Typography
          variant="h4"
          color="primary"
          style={{ marginBottom: "8px" }}
        >
          <b>CitizenENGAGE</b>
        </Typography>

        <Typography variant="body1" style={{ marginBottom: "8px" }}>
          This tool allows members of {fullName} to directly text a link to a{" "}
          <b>non-emergency report</b> to a citizen.
        </Typography>

        <Typography
          variant="caption"
          display="block"
          style={{ marginBottom: "8px", fontStyle: "italic" }}
        >
          Current Time: {currentTime}
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              label="Caller First Name"
              fullWidth
              margin="dense"
              value={callerFirstName}
              onChange={(e) => setCallerFirstName(e.target.value)}
              style={{ marginBottom: "8px" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              label="Caller Last Name"
              fullWidth
              margin="dense"
              value={callerLastName}
              onChange={(e) => setCallerLastName(e.target.value)}
              style={{ marginBottom: "8px" }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} alignItems="center">
          <Grid item xs={4}>
            <TextField
              select
              label="Area Code"
              value={areaCode}
              onChange={(e) => setAreaCode(e.target.value)}
              variant="outlined"
              fullWidth
              margin="dense"
              InputLabelProps={{ shrink: true }}
              style={{ marginBottom: "8px" }}
            >
              <MenuItem value="+1">+1 (Canada)</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={8}>
            <TextField
              variant="outlined"
              label="Caller Phone Number"
              fullWidth
              margin="dense"
              value={callerPhoneNumber}
              onChange={(e) => setCallerPhoneNumber(e.target.value)}
              style={{ marginBottom: "8px" }}
              InputLabelProps={{ shrink: true }}
              onBlur={() => {
                if (!validatePhoneNumber(callerPhoneNumber)) {
                  toast.error(
                    "🚫 Invalid phone number entered! Please enter a 10-digit number.",
                  );
                }
              }}
            />
          </Grid>
        </Grid>
        {reportConfigurations && (
          <ReportFilterButtons
            reports={reportConfigurations}
            selectedReport={selectedReport}
            onReportSelect={setSelectedReport}
          />
        )}

        <Grid
          container
          spacing={1}
          justifyContent="space-between"
          style={{ marginTop: "20px" }}
        >
          <Grid item xs={2}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClearForm}
              fullWidth
            >
              Clear
            </Button>
          </Grid>
          <Grid item xs={10}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSendText}
              fullWidth
            >
              Send Text
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default CitizenEngage;
