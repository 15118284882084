import { Chip } from "@mui/material";
import { useRef } from "react";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import MediaDisplay from "./MediaDisplay";
import { IEvidenceFile, ITag } from "../types/typesAlerts";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

interface CustomizedSliderProps {
  medias: any[];
  mode: string;
  view_token: string;
  tagDict: { [key: string]: ITag };
  customVideoErrorMessage?: string;
  handleSlideClick: (index: number) => void;
  handleVideoErrorClick?: () => void;
}

export default function CustomizedSlider({
  medias,
  mode,
  view_token,
  tagDict,
  customVideoErrorMessage,
  handleSlideClick,
  handleVideoErrorClick,
}: CustomizedSliderProps) {
  const containerRef = useRef<HTMLDivElement>(null); // Ref for the parent/container element
  const swiperRef = useRef<any>(null); // Ref for the Swiper instance
  // Function to display media tags for a file
  const MediaTagDisplay = (
    fileName: string,
    tagDict: { [key: string]: ITag },
  ) => {
    const mediaTags = Object.values(tagDict).filter((tag) =>
      tag?.attachments?.find((name) => fileName === name),
    );
    return (
      <div
        className="d-flex flex-row gap-2 justify-content-center "
        style={{
          flexWrap: "wrap",
        }}
      >
        {mediaTags.map((mediaTag, index) => (
          <Chip
            label={mediaTag?.title}
            style={{
              maxWidth: "160px",
            }}
            color="secondary"
            size="small"
            key={index}
          />
        ))}
      </div>
    );
  };

  // Function to get download URL
  const getDownloadUrl = (attachment: IEvidenceFile) => {
    const url = new URL(attachment?.download_url ?? "");
    if (mode === "view" && view_token)
      url.searchParams.set("access_token", view_token);
    return url.toString();
  };

  // Function to get thumbnail URL
  const getThumbnailUrl = (attachment: IEvidenceFile) => {
    const url = new URL(
      (attachment as any)?.thumbnail_url ?? (attachment as any)?.download_url,
    );
    if (mode === "view" && view_token)
      url.searchParams.set("access_token", view_token);
    return url.toString();
  };

  // Function to get the appropriate URL based on content type
  const getUrl = (attachment: IEvidenceFile) => {
    const url = attachment.content_type.includes("video")
      ? getDownloadUrl(attachment)
      : getThumbnailUrl(attachment);
    return url;
  };

  return (
    <div ref={containerRef}>
      <Swiper
        ref={swiperRef} // Pass the ref here
        navigation={true}
        observeParents={true}
        breakpointsBase="container"
        loop={true}
        breakpoints={{
          320: {
            slidesPerView: 2,
          },
          480: {
            slidesPerView: 3,
          },
        }}
        spaceBetween={10}
        centeredSlides={medias.length === 1}
        pagination={{ clickable: true, type: "progressbar" }}
        modules={[Pagination, Navigation]}
        className="desktop-swiper d-none d-md-block"
      >
        {medias.map((attachment, index) => (
          <SwiperSlide
            key={`${attachment?.filename}-${index}`}
            className="h-100 d-flex justify-content-center align-items-center"
            onClick={() => handleSlideClick(index)} // On slide click, show full-screen
          >
            <div className="pt-2">
              <MediaDisplay
                src={getUrl(attachment)}
                mediaType={
                  attachment.content_type.includes("video") ? "video" : "image"
                }
                onClick={() => handleSlideClick(index)}
                onLoad={(e) => {
                  const { naturalHeight, naturalWidth } =
                    e.target as HTMLImageElement;
                  if (attachment.content_type.includes("video")) {
                    if (naturalHeight > naturalWidth) {
                      e.target.style.height = "100%";
                      e.target.style.width = "auto";
                      e.target.style.objectFit = "contain";
                    } else {
                      e.target.style.width = "100%";
                      e.target.style.height = "auto";
                      e.target.style.objectFit = "contain";
                    }
                  } else {
                    e.target.style.width = "100%";
                    e.target.style.objectFit = "contain";
                  }
                }}
                customVideoErrorMessage={customVideoErrorMessage}
                handleVideoErrorClick={handleVideoErrorClick}
              />
              {MediaTagDisplay((attachment as any).filename, tagDict)}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        autoHeight={true}
        navigation={true}
        slidesPerView={1}
        spaceBetween={20}
        pagination={{ clickable: true }}
        centeredSlides={true}
        loop={true}
        modules={[Pagination, Navigation]}
        className="d-md-none"
        style={{
          width: "100vw",
        }}
      >
        {medias.map((attachment, index) => (
          <SwiperSlide
            key={`${attachment?.filename}-${index}`}
            className="h-100 d-flex justify-content-center align-items-center"
            onClick={() => handleSlideClick(index)}
          >
            <div
              style={{
                overflow: "hidden",
              }}
            >
              <MediaDisplay
                src={getUrl(attachment)}
                mediaType={
                  attachment.content_type.includes("video") ? "video" : "image"
                }
                onClick={() => handleSlideClick(index)}
                onLoad={(e) => {
                  const { naturalHeight, naturalWidth } =
                    e.target as HTMLImageElement;
                  if (attachment.content_type.includes("video")) {
                    if (naturalHeight > naturalWidth) {
                      e.target.style.height = "100%";
                      e.target.style.width = "auto";
                      e.target.style.objectFit = "contain";
                    } else {
                      e.target.style.width = "100%";
                      e.target.style.height = "auto";
                      e.target.style.objectFit = "contain";
                    }
                  } else {
                    e.target.style.width = "100%";
                    e.target.style.height = "100%";
                    e.target.style.objectFit = "contain";
                  }
                }}
                customVideoErrorMessage={customVideoErrorMessage}
                handleVideoErrorClick={handleVideoErrorClick}
              />
              {MediaTagDisplay((attachment as any).filename, tagDict)}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
