import { ArrowBack, Edit } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import FormBuilder from "./FormBuilder";
import { useFormBuilderContext } from "./FormContextProvider";
import { SurveyModel } from "../../../common/api-client/or-api";
import { API } from "../../../common/api-client/or-api/api";
import "./SurveyEdit.scss";

export const SurveyEdit = () => {
  const { orgId, surveyId } = useParams();
  const { surveyModels, setSurveyModels } = useFormBuilderContext();
  const navigate = useNavigate();

  const [mode, setMode] = useState<"edit" | "create" | "view">("edit");
  const [initialSurvey, setInitialSurvey] = useState<SurveyModel | null>(null);

  useEffect(() => {
    if (surveyId && window.location.pathname.includes("edit")) {
      setMode("edit");
    } else if (surveyId && !window.location.pathname.includes("edit")) {
      setMode("view");
    } else {
      setMode("create");
      const putNewSurvey = async () => {
        try {
          const [newSurveyResponse] = await Promise.all([
            API.survey.put(orgId ?? "", {
              survey: {},
            }),
          ]);
          setInitialSurvey(newSurveyResponse.survey);
        } catch (error) {
          console.error("Error creating new survey: ", error);
        }
      };
      putNewSurvey();
    }
  }, [surveyId, window.location.pathname]);

  useEffect(() => {
    const fetchSurvey = async () => {
      const survey = surveyModels.find((survey) => survey.id === surveyId);

      if (survey) {
        setInitialSurvey(survey);
        console.log("survey : ", survey);
      }
    };
    if (surveyId) {
      fetchSurvey();
    }
  }, [orgId, surveyId]);

  const handleConfigChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    try {
      const newConfig = JSON.parse(event.target.value);
      if (initialSurvey) {
        initialSurvey.configuration = newConfig;
        setInitialSurvey(initialSurvey);
      }
    } catch (error) {
      // Handle invalid JSON
    }
  };

  const handleSave = async (surveyModel: SurveyModel) => {
    // let's just update the context...
    // setSurveyModels([...surveyModels, surveyModel]);
    try {
      await Promise.all([
        API.survey.put(orgId ?? "", {
          survey: surveyModel,
        }),
      ]);
    } catch (error) {
      console.error("Error creating new survey: ", error);
    }
    // navigate(`/rubireportv2/${orgId}/forms/surveys`);
  };

  return (
    <div style={{ height: "100%" }}>
      <div>
        <Button
          variant="outlined"
          color="primary"
          size="medium"
          startIcon={<ArrowBack />}
          onClick={() => navigate(`/rubireportv2/${orgId}/forms/surveys`)}
          sx={{
            marginTop: "8px",
            height: "40px",
            position: "fixed",
            zIndex: 2,
            borderRadius: "0 10px 10px 0",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.30)",
            backgroundColor: "#fff",
          }}
        >
          Back
        </Button>
      </div>
      <Box display="flex" flexDirection="column" gap="16px">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
          borderRadius="8px"
          marginLeft="16px"
          marginRight="16px"
          padding="16px"
        >
          {/* <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Typography variant="h5" gutterBottom>
              {mode === "edit"
                ? "Edit Survey"
                : mode === "create"
                  ? "Create Survey"
                  : "Viewing Survey"}
            </Typography>
          </Box> */}
          <Box
            paddingLeft="24px"
            paddingRight="24px"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gap="16px"
          >
            <Typography align="right" color="text.secondary" paddingRight="0">
              {mode === "view" ? (
                <>
                  Viewing Survey with ID: <br />
                  {initialSurvey?.id}
                </>
              ) : mode === "create" ? (
                <>
                  Creating Survey with ID: <br />
                  {initialSurvey?.id}
                </>
              ) : (
                <>
                  Editing Survey with ID: <br />
                  {initialSurvey?.id}
                </>
              )}
            </Typography>
            {mode === "view" ? (
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<Edit />}
                onClick={() => navigate(`./edit`)}
              >
                Edit
              </Button>
            ) : null}
          </Box>
        </Box>

        <Box marginLeft="16px" marginRight="16px" height="80vh">
          <FormBuilder
            originalSurveyModel={initialSurvey ?? ({} as SurveyModel)}
            onSave={handleSave}
            mode={mode}
          />
        </Box>
      </Box>
    </div>
  );
};
