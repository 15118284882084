import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { styled, useTheme } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";

import { useDrawer } from "./DrawerContext";

const Main = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "open" && prop !== "drawerWidth" && prop !== "location",
})<{ open: boolean; drawerWidth: number; location: "left" | "right" }>(
  ({ theme, open, drawerWidth, location }) => ({
    flexGrow: 1,
    transition: theme.transitions.create(["margin", "width"], {
      easing: open
        ? theme.transitions.easing.easeOut
        : theme.transitions.easing.sharp,
      duration: open
        ? theme.transitions.duration.enteringScreen
        : theme.transitions.duration.leavingScreen,
    }),
    width: `calc(100% - ${open ? drawerWidth : 0}px)`,
    overflow: "auto",
    height: "calc(100vh - 50px)",
    ...(location === "right"
      ? {
          marginRight: open ? drawerWidth : 0,
        }
      : {
          marginLeft: open ? drawerWidth : 0,
        }),
  }),
);

const ResizableHandle = styled(Box)({
  width: "10px",
  cursor: "col-resize",
  height: "100%",
  position: "absolute",
  top: 0,
  zIndex: 1000,
  backgroundColor: "rgba(0, 0, 0, 0.1)",
  boxShadow: "0 0 5px rgba(0, 0, 0, 0.5)",
});

const CloseButton = styled(Box)<{ location: "left" | "right" }>(
  ({ theme, location }) => ({
    cursor: "pointer",
    zIndex: 1200,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "24px",
    height: "48px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    borderRadius: location === "left" ? "4px 0 0 4px" : "0 4px 4px 0",
    transition: theme.transitions.create("background-color", {
      duration: theme.transitions.duration.short,
    }),
    "&:hover": {
      boxShadow: theme.shadows[4],
      transition: theme.transitions.create("background-color", {
        duration: theme.transitions.duration.short,
      }),
    },
    "& svg": {
      fontSize: "18px",
    },
  }),
);

const SlideOutDrawer = ({
  slideoutContent,
  width = 240,
  mainContent,
  slideoutLocation = "right",
  onDrawerClose,
}: {
  slideoutContent: React.ReactNode;
  width?: number;
  mainContent: React.ReactNode;
  slideoutLocation?: "left" | "right";
  onDrawerClose: React.MouseEventHandler<HTMLDivElement>;
}) => {
  const { isOpen, closeDrawer, drawerWidth, setDrawerWidth } = useDrawer();
  const theme = useTheme();

  const handleRef = useRef<HTMLDivElement | null>(null);

  const handleMouseDown = (e: React.MouseEvent) => {
    const startX = e.clientX;
    const startWidth = drawerWidth;

    // should not select text while dragging
    e.preventDefault();

    const doDrag = (moveEvent: MouseEvent) => {
      const newWidth =
        startWidth +
        (slideoutLocation === "right"
          ? startX - moveEvent.clientX
          : moveEvent.clientX - startX);
      setDrawerWidth(
        Math.min(Math.max(newWidth, 200), window.innerWidth * 0.8),
      );
    };

    const stopDrag = () => {
      document.removeEventListener("mousemove", doDrag);
      document.removeEventListener("mouseup", stopDrag);
    };

    document.addEventListener("mousemove", doDrag);
    document.addEventListener("mouseup", stopDrag);
  };

  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
      }}
    >
      <Main open={isOpen} drawerWidth={drawerWidth} location={slideoutLocation}>
        {mainContent}
      </Main>
      <Drawer
        variant="persistent"
        anchor={slideoutLocation}
        open={isOpen}
        PaperProps={{
          sx: {
            width: drawerWidth,
            position: "fixed",
            top: 0,
            [slideoutLocation]: 0,
            height: "calc(100vh)",
            marginTop: theme.spacing(6),
            overflow: "hidden",
          },
        }}
      >
        <CloseButton location={slideoutLocation} onClick={onDrawerClose}>
          {slideoutLocation === "right" ? (
            <KeyboardDoubleArrowRight fontSize="small" />
          ) : (
            <KeyboardDoubleArrowLeft fontSize="small" />
          )}
        </CloseButton>

        <ResizableHandle
          ref={handleRef}
          onMouseDown={handleMouseDown}
          sx={{
            left: slideoutLocation === "right" ? "-2.5px" : undefined,
            right: slideoutLocation === "left" ? "-2.5px" : undefined,
          }}
        />
        <Box
          sx={{
            paddingLeft: slideoutLocation === "right" ? 1 : -1,
            overflowY: "auto",
            height: "calc(100vh - 50px)",
          }}
        >
          {slideoutContent}
        </Box>
      </Drawer>
    </Box>
  );
};

export default SlideOutDrawer;
