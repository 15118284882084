import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();
  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    i18n.changeLanguage(event.target.value);
  };

  useEffect(() => {
    const browserLang = navigator.language.split("-")[0];
    if (["en", "fr"].includes(browserLang)) {
      i18n.changeLanguage(browserLang);
    } else {
      i18n.changeLanguage("en");
    }
  }, [i18n]);

  return (
    <FormControl
      variant="outlined"
      size="small"
      sx={{
        marginLeft: "16px",
        "& .MuiOutlinedInput-root": {
          color: "#ffffff",
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          borderColor: "rgba(255, 255, 255, 0.3)",
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            borderColor: "#ffffff",
          },
          "&.Mui-focused": {
            borderColor: "#ffffff",
          },
        },
        "& .MuiSelect-icon": {
          color: "#ffffff",
        },
        "& .MuiList-root": {
          backgroundColor: "#333333",
        },
      }}
    >
      <Select
        value={i18n.language}
        onChange={handleLanguageChange}
        displayEmpty
      >
        <MenuItem value="en">EN</MenuItem>
        <MenuItem value="fr">FR</MenuItem>
      </Select>
    </FormControl>
  );
};

export default LanguageSelector;
