import MyLocationIcon from "@mui/icons-material/MyLocation";
import { Box, IconButton } from "@mui/material";
import {
  AdvancedMarker,
  Map,
  MapControl,
  Marker,
  Pin,
  useMap,
} from "@vis.gl/react-google-maps";
import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useDevEnvironment } from "../../DevContext";
import { SubmissionSummary } from "../../common/api-client/or-api";
import { getServiceConfig } from "../../common/serviceConfigurations";
import { AuthStateContext } from "../../context";
import {
  fetchSubmissionData,
  SubmissionData,
} from "../../utils/submissionUtils";
import { OnlineReportingContext } from "../online-reporting/context";

interface RegistryMapViewProps {
  reports: SubmissionSummary[];
  loadingEntries: boolean;
  onPinClick?: (submission: SubmissionSummary) => void;
  selectedReportId?: string;
}

interface Location {
  lat: number;
  lng: number;
  submission_id: string;
  submissionData?: SubmissionData;
}

const RegistryMapView = ({
  reports,
  loadingEntries,
  onPinClick,
  selectedReportId,
}: RegistryMapViewProps) => {
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [usersLocation, setUsersLocation] =
    useState<google.maps.LatLngLiteral | null>(null);
  const [locations, setLocations] = useState<Location[]>([]);
  const map = useMap();
  const googleMapId = "4380cd85df35e961";
  const { orgId } = useParams();

  const { currentUser } = useContext(AuthStateContext);
  const { isDevEnv } = useDevEnvironment();
  const organizationConfig = getServiceConfig(
    orgId ?? currentUser?.rubi_org_id ?? "NA",
    isDevEnv,
  );

  const defaultMapCenter = organizationConfig.mapCenter;

  const { getSubmissionForID } = useContext(OnlineReportingContext);

  const getCurrentPosition = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setUsersLocation({ lat: latitude, lng: longitude });
        map?.setCenter({ lat: latitude, lng: longitude });
      },
      (error) => {
        toast.error("Unable to get location: " + error.message);
      },
    );
  };

  const panToCurrentLocation = () => {
    if (navigator.permissions) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "granted" || result.state === "prompt") {
          getCurrentPosition();
        } else {
          toast.error("Location access denied");
        }
      });
    } else if (navigator.geolocation) {
      getCurrentPosition();
    }
  };

  const findGoogleMapPlaces = (obj: any, submission_id: string) => {
    const newLocations: Location[] = [];

    const searchObject = (obj: any) => {
      if (!obj || typeof obj !== "object") return;

      if (
        obj.googleMapsPlace &&
        obj.googleMapsPlace.geometry &&
        obj.googleMapsPlace.geometry
      ) {
        newLocations.push({
          lat: obj.googleMapsPlace.geometry.lat,
          lng: obj.googleMapsPlace.geometry.lng,
          submission_id: submission_id,
        });
      }

      Object.values(obj).forEach((value) => {
        if (value && typeof value === "object") {
          searchObject(value);
        }
      });
    };

    searchObject(obj);
    return newLocations;
  };

  useEffect(() => {
    const loadLocationsWithSubmissionData = async () => {
      const allLocations: Location[] = [];
      for (const report of reports) {
        const submissionData = await fetchSubmissionData(
          report.id,
          getSubmissionForID,
        );
        const reportLocations = findGoogleMapPlaces(
          submissionData.details?.submission,
          report.id,
        );
        for (const location of reportLocations) {
          location.submissionData = submissionData;
          location.submission_id = report.id;
        }

        allLocations.push(...reportLocations);
      }
      setLocations(allLocations);
    };
    if (loadingEntries) return;
    loadLocationsWithSubmissionData();
  }, [reports, getSubmissionForID, loadingEntries]);

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Map
        onTilesLoaded={() => setIsMapLoaded(true)}
        defaultCenter={defaultMapCenter}
        defaultZoom={13}
        gestureHandling="greedy"
        disableDefaultUI={true}
        mapId={googleMapId}
      >
        {isMapLoaded &&
          locations.map((location, index) => (
            <AdvancedMarker
              key={location.submission_id + index}
              position={{
                lat: location.lat,
                lng: location.lng,
              }}
              onClick={() => {
                const submission = reports.find(
                  (r) => r.id === location.submission_id,
                );
                if (submission && onPinClick) {
                  onPinClick(submission);
                }
              }}
            >
              <Pin
                background={
                  location.submission_id === selectedReportId
                    ? "#2196F3"
                    : undefined
                }
                glyphColor={
                  location.submission_id === selectedReportId
                    ? "#FFFFFF"
                    : undefined
                }
              />
            </AdvancedMarker>
          ))}

        {isMapLoaded && (
          <MapControl position={google.maps.ControlPosition.RIGHT_BOTTOM}>
            <Box
              sx={{
                bgcolor: "#fff",
                boxShadow: 2,
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "40px",
                height: "40px",
                margin: "10px",
              }}
            >
              <IconButton
                onClick={panToCurrentLocation}
                aria-label="Pan to current location"
              >
                <MyLocationIcon />
              </IconButton>
            </Box>
          </MapControl>
        )}

        {isMapLoaded && usersLocation && (
          <Marker
            position={usersLocation}
            icon={{
              path: google.maps.SymbolPath.CIRCLE,
              scale: 8,
              fillColor: "#4285F4",
              fillOpacity: 1,
              strokeColor: "#FFFFFF",
              strokeWeight: 2,
            }}
          />
        )}
      </Map>
    </div>
  );
};

export default RegistryMapView;
