import { useMapsLibrary } from "@vis.gl/react-google-maps";
import { useEffect, useRef, useState, useCallback, useContext } from "react";

import { serviceConfigurations } from "../../../../common/serviceConfigurations";
import { IPlace } from "../../types";
import { transformPlaceObject } from "../../utils";

// import { OnlineReportingContext } from "../../../contexts/online-reports";
// import { IPlace } from "../../../types";
// import { transformPlaceObject } from "../../shared/utils";

export interface PlaceAutocompleteProps {
  place?: IPlace;
  onPlaceSelect: (place: IPlace | null) => void;
  center?: google.maps.LatLngLiteral;
  hideClearButton?: boolean;
  required?: boolean;
  fieldName?: string;
}

const PlaceAutocomplete = ({
  place,
  onPlaceSelect,
  hideClearButton,
  required,
  fieldName,
}: PlaceAutocompleteProps) => {
  const [placeAutocomplete, setPlaceAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const places = useMapsLibrary("places");
  // const { police } = useContext(OnlineReportingContext);
  const police = serviceConfigurations["Timmins Police Service"];

  useEffect(() => {
    if (
      !places ||
      !inputRef.current ||
      !(inputRef.current instanceof HTMLInputElement)
    ) {
      return;
    }

    const bounds: google.maps.places.AutocompleteOptions["bounds"] =
      police?.mapCenter
        ? {
            east: police.mapCenter.lng + 0.01,
            west: police.mapCenter.lng - 0.01,
            north: police.mapCenter.lat + 0.01,
            south: police.mapCenter.lat - 0.01,
          }
        : undefined;

    const options = {
      fields: [
        "geometry",
        "name",
        "formatted_address",
        "place_id",
        "types",
        "url",
        "address_components",
      ],
      bounds: bounds,
    };

    const autocompleteInstance = new places.Autocomplete(
      inputRef.current,
      options,
    );
    setPlaceAutocomplete(autocompleteInstance);

    return () => {
      if (autocompleteInstance) {
        google.maps.event.clearInstanceListeners(autocompleteInstance);
      }
    };
  }, [places, police]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    const handlePlaceChanged = () => {
      const place = transformPlaceObject(placeAutocomplete.getPlace());
      if (place && place.geometry) {
        onPlaceSelect(place);
      } else {
        console.error("Selected place does not have geometry.");
        onPlaceSelect(null);
      }
    };

    const listener = placeAutocomplete.addListener(
      "place_changed",
      handlePlaceChanged,
    );

    return () => {
      if (listener) {
        google.maps.event.removeListener(listener);
      }
    };
  }, [onPlaceSelect, placeAutocomplete]);

  useEffect(() => {
    if (place && inputRef.current) {
      inputRef.current.value = place.formatted_address || "";
    }
  }, [place]);

  const handleClearInput = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = "";
      onPlaceSelect(null);
    }
  }, [onPlaceSelect]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && inputRef.current) {
      event.preventDefault();
      inputRef.current.blur();
      inputRef.current.focus();
    }
  };

  return (
    <div
      style={{ position: "relative", marginBottom: "1rem" }}
      className="sd-element sd-question sd-row__question sd-question--title-top sd-row__question--small"
    >
      {fieldName && (
        <div className="sd-question__header sd-element__header sd-question__header--location-top sd-element__header--location-top">
          <h5 className="sd-title sd-element__title sd-question__title sd-question__title--required">
            <span className="sv-string-viewer sv-string-viewer--multiline">
              {fieldName}{" "}
              {required && (
                <span className="sd-question__required-text">*</span>
              )}
            </span>
          </h5>
        </div>
      )}
      <input
        className={"sd-input sd-text"}
        ref={inputRef}
        placeholder="Search for a place..."
        onKeyDown={handleKeyDown}
        autoComplete="off"
      />

      {!hideClearButton && place && (
        <div
          className="sd-dropdown_clean-button"
          aria-hidden="true"
          onClick={handleClearInput}
          style={{
            position: "absolute",
            right: "0.5rem",
            top: "56px",
            transform: "translateY(-50%)",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "1.5rem",
            height: "1.5rem",
            borderRadius: "50%",
          }}
        >
          <svg
            className="sv-svg-icon sd-dropdown_clean-button-svg sv-dropdown_select-wrapper"
            role="img"
            style={{
              height: "calc(1.5*var(--sjs-internal-font-editorfont-size))",
              width: "calc(1.5*var(--sjs-internal-font-editorfont-size))",
              fontSize: "var(--sjs-internal-font-editorfont-size)",
              fill: "rgb(0, 0, 0, 0.45)",
            }}
          >
            <use xlinkHref="#icon-clear"></use>
            <title>Clear</title>
          </svg>
        </div>
      )}
    </div>
  );
};

export default PlaceAutocomplete;
