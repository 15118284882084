import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Typography, CircularProgress, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";

import styles from "./Home.module.css";
import LoginWrapper from "./LoginWrapper";
import { getSSODetails, ssoStart } from "../common/api-client/apiUserClient";
import { ssoConfigurations } from "../common/serviceConfigurations";

export const ServiceLogin = () => {
  const { organizationSlug } = useParams();
  const navigate = useNavigate();
  const organizationConfig = ssoConfigurations.find(
    (config) => config.ssoSlug === organizationSlug,
  );
  const [ssoID, setSSOID] = useState<string>("");
  const [loading, setLoading] = useState(false);

  if (organizationSlug && !organizationConfig) {
    navigate("/login");
    return <div>Organization not found</div>;
  }

  useEffect(() => {
    const loadSSORedirect = async () => {
      const result = await getSSODetails(organizationSlug!);
      const connections = result.sso_connections;
      const mainConnection = connections[0];

      setSSOID(mainConnection.connection_id);
    };
    if (organizationConfig?.ssoSlug) {
      loadSSORedirect();
    }
  }, [organizationConfig]);

  const handleSubmit = async () => {
    setLoading(true);
    const result = await ssoStart(ssoID);
    if (result.redirect_url) {
      window.location.href = result.redirect_url;
    } else {
      toast.error(
        "There was a problem with the SSO redirect. Please try again.",
      );
    }
    setLoading(false);
  };

  return (
    <LoginWrapper>
      <div className={styles.paper}>
        <div style={{ width: "100%", alignItems: "left" }}>
          <Button
            name="Back"
            color="primary"
            startIcon={<ArrowBackIcon />}
            size="large"
            onClick={() => {
              navigate("/login");
            }}
          >
            {"Back"}
          </Button>
        </div>

        <img
          className={styles.loginLogo}
          src={organizationConfig?.logoURL}
          alt="logo"
        />

        <Typography component="h1" variant="h5">
          Sign in with your {organizationConfig?.fullName} account
        </Typography>
        <p>
          <i>
            You will be redirected to your organization&apos;s typical sign-in
            process
          </i>
        </p>
        <Button
          name="Submit"
          variant="contained"
          type="submit"
          color="primary"
          size="large"
          disabled={!ssoID}
          fullWidth
          onClick={() => {
            handleSubmit();
          }}
        >
          {loading && <CircularProgress size={25} thickness={2} />}
          {"Sign In"}
        </Button>
      </div>
    </LoginWrapper>
  );
};
