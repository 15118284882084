import React from "react";
import { useParams } from "react-router-dom";

const RegistrySettings = () => {
  const { orgId } = useParams();

  return (
    <div>
      <h1>Registry Settings</h1>
      <p>Organization ID: {orgId}</p>
      {/* Settings form will go here */}
    </div>
  );
};

export default RegistrySettings;
