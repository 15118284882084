/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContactModel } from '../models/ContactModel';
import type { PatchContactRequest } from '../models/PatchContactRequest';
import type { PutContactRequest } from '../models/PutContactRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ContactService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param propertyId
     * @param requestBody
     * @returns ContactModel
     * @throws ApiError
     */
    public putPortal(
        propertyId: string,
        requestBody: PutContactRequest,
    ): CancelablePromise<ContactModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/camera_registry/portal/property/{property_id}/contact',
            path: {
                'property_id': propertyId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param propertyId property_id
     * @param contactId contact_id
     * @returns any
     * @throws ApiError
     */
    public deletePortal(
        propertyId: string,
        contactId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/camera_registry/portal/property/{property_id}/contact/{contact_id}',
            path: {
                'property_id': propertyId,
                'contact_id': contactId,
            },
        });
    }

    /**
     * @param propertyId property_id
     * @param contactId contact_id
     * @param requestBody
     * @returns ContactModel
     * @throws ApiError
     */
    public patchPortal(
        propertyId: string,
        contactId: string,
        requestBody: PatchContactRequest,
    ): CancelablePromise<ContactModel> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/camera_registry/portal/property/{property_id}/contact/{contact_id}',
            path: {
                'property_id': propertyId,
                'contact_id': contactId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param propertyId
     * @param requestBody
     * @returns ContactModel
     * @throws ApiError
     */
    public putPublic(
        propertyId: string,
        requestBody: PutContactRequest,
    ): CancelablePromise<ContactModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/camera_registry/public/property/{property_id}/contact',
            path: {
                'property_id': propertyId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param propertyId property_id
     * @param contactId contact_id
     * @returns any
     * @throws ApiError
     */
    public deletePublic(
        propertyId: string,
        contactId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/camera_registry/public/property/{property_id}/contact/{contact_id}',
            path: {
                'property_id': propertyId,
                'contact_id': contactId,
            },
        });
    }

    /**
     * @param propertyId property_id
     * @param contactId contact_id
     * @param requestBody
     * @returns ContactModel
     * @throws ApiError
     */
    public patchPublic(
        propertyId: string,
        contactId: string,
        requestBody: PatchContactRequest,
    ): CancelablePromise<ContactModel> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/camera_registry/public/property/{property_id}/contact/{contact_id}',
            path: {
                'property_id': propertyId,
                'contact_id': contactId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
