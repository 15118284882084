import { SurveyModel } from "../../../common/api-client/or-api";

export const getSurveyTitleFromModel = (model: SurveyModel) => {
  if (typeof model.configuration === "string") {
    const parsedConfig = JSON.parse(model.configuration);
    return parsedConfig.title || model.id;
  }
  if (typeof model.configuration === "object") {
    const title = model.configuration.title;
    if (typeof title === "string") {
      return title;
    } else if (typeof title === "object") {
      return `${title.default} / ${title.fr}`;
    }
  }

  return model.id;
};
