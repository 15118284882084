import axios from "axios";
import { ITheme, Model, Question } from "survey-core";

import { defaultTheme } from "./constants";
import { IPlace } from "./types";
import {
  SubmissionAttachment,
  SubmissionSummary,
} from "../../common/api-client/or-api";
import { API } from "../../common/api-client/or-api/api";
import { statusTypes } from "../../types/typesReportV2";

export interface UploadFile {
  name: string;
  type: string;
  content: string;
}

export const lastUpdatedBy = (
  submission: SubmissionSummary,
  includeBadge = true,
) => {
  const name = submission.last_updated_by
    ? `${submission.last_updated_by?.rank_short ?? ""} ${
        submission.last_updated_by?.first_name ?? ""
      } ${submission.last_updated_by?.last_name ?? ""}`
    : "Reporting Individual";

  if (includeBadge && submission.last_updated_by) {
    return `${name} (${submission.last_updated_by?.badge_number ?? ""})`;
  }
  return name;
};

export const assignedTo = (submission: SubmissionSummary) => {
  if (submission.assigned_to) {
    return `${submission.assigned_to?.first_name ?? ""} ${submission.assigned_to?.last_name ?? ""} (${submission.assigned_to?.badge_number ?? ""})`;
  }
  return "";
};

export const uploadToS3 = async (file: File, submissionID: string) => {
  const s3Details = await API.submission.postSubmissionAttachmentPortal(
    submissionID,
    {
      filename: file.name,
      size: file.size,
      content_type: file.type,
    },
  );
  const headers: { [key: string]: string } = {};
  s3Details.s3_presigned.headers
    .filter((header) => header.key !== "content-length")
    .forEach((header) => {
      headers[header.key] = header.value;
    });
  await axios.put(s3Details.s3_presigned.uri, file, {
    headers,
  });
  return s3Details;
};

export const imageUrlToBase64 = async (url) => {
  const data = await axios.get(url, {
    responseType: "blob",
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  });
  const blob = data.data;
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data?.toString() ?? "");
    };
    reader.onerror = reject;
  });
};

export async function loadImageData(
  data: any,
  attachments: Array<SubmissionAttachment>,
  includeVideos = false,
) {
  const promises = attachments.map(async (attachment) => {
    const assetURL = attachment.presigned_get.s3_presigned.uri;
    const base64 = await imageUrlToBase64(assetURL);
    return { name: attachment.filename, base64 };
  });

  const attachmentResults = await Promise.all(promises);
  const assetURLs = attachmentResults.map((attachment) => {
    return { name: attachment.name, content: attachment.base64 };
  });

  function traverse(jsonObj) {
    if (jsonObj !== null && typeof jsonObj == "object") {
      Object.entries(jsonObj).forEach(([key, value]) => {
        // key is either an array index or object key
        if (
          key === "type" &&
          (jsonObj["type"]?.startsWith("image") ||
            (includeVideos && jsonObj["type"]?.startsWith("video")))
        ) {
          const assetURL = assetURLs.find(
            (attachment) => attachment.name === jsonObj.name,
          );
          jsonObj["content"] = assetURL?.content ?? "";
        } else {
          traverse(value);
        }
      });
    } else {
      // jsonObj is a number or string
    }
  }
  traverse(data);
}

export const extractAttachmentsFromObject = (
  obj,
  reportAttachments: {
    incident_evidence_description: string;
    incident_evidence_upload: UploadFile[];
  }[] = [],
) => {
  if (
    typeof obj === "object" &&
    obj !== null &&
    (obj.incident_evidences ||
      obj.vulnerable_evidences ||
      obj["person-vulnerable_evidences"])
  ) {
    const incidentEvidences: any[] =
      obj.incident_evidences ??
      obj.vulnerable_evidences ??
      obj["person-vulnerable_evidences"];
    incidentEvidences.forEach((evidence) => {
      reportAttachments.push({
        incident_evidence_description:
          evidence.incident_evidence_description ??
          evidence.incident_evidence_keywordDescription ??
          evidence["person-vulnerable_evidence-portrait_description"],
        incident_evidence_upload:
          evidence.incident_evidence_upload ??
          evidence["person-vulnerable_evidence-portrait_upload"],
      });
    });
  } else if (
    typeof obj === "object" &&
    obj !== null &&
    obj["bicycles-registered"]
  ) {
    const incidentEvidences: any[] = obj["bicycles-registered"];
    incidentEvidences.forEach((evidence) => {
      evidence["bicycle-registered_evidence_upload"]?.forEach((upload) => {
        reportAttachments.push({
          incident_evidence_description: "",
          incident_evidence_upload: [upload],
        });
      });
      evidence["bicycle-registered_evidence-proofOfOwnership_upload"]?.forEach(
        (upload) => {
          reportAttachments.push({
            incident_evidence_description: "",
            incident_evidence_upload: [upload],
          });
        },
      );
    });
  } else if (Array.isArray(obj)) {
    obj.forEach((item) => {
      extractAttachmentsFromObject(item, reportAttachments);
    });
  }
  return reportAttachments;
};

export const removeAttachmentsFromPages = (pages) => {
  if (Array.isArray(pages)) {
    pages.forEach((page) => {
      removeAttachmentsFromPages(page);
    });
  } else if (typeof pages === "object" && pages !== null) {
    if (pages.elements) {
      pages.elements = pages.elements.map((element) => {
        const elementsToRemove = element.templateElements?.filter(
          (templateElement) => templateElement.name.includes("_upload"),
        );

        // remove the _upload and _description elements
        elementsToRemove?.forEach((elementToRemove) => {
          const elementName = elementToRemove.name;
          const elementDescription = elementName.replace(
            "_upload",
            "_description",
          );
          element.templateElements = element.templateElements?.filter(
            (templateElement) =>
              templateElement.name !== elementName &&
              templateElement.name !== elementDescription,
          );
        });
        return element;
      });
    }
    Object.values(pages).forEach((value) => {
      removeAttachmentsFromPages(value);
    });
  }
};

export const parseData = (obj, parseDates = false) => {
  const dateKeys = [
    "incident_endDateTime",
    "incident_startDateTime",
    "supplementary_reportedDateTime",
    "supplementary_linkedDateTime",
  ];
  if (typeof obj === "object" && obj !== null && !Array.isArray(obj)) {
    if (obj.googleMapsPlace) {
      return extractAddress(obj);
    }

    Object.keys(obj).forEach((key) => {
      if (parseDates && dateKeys.includes(key)) {
        const parsedDate = new Date(obj[key]).toLocaleString();
        if (parsedDate == "Invalid Date") {
          console.log("key: ", key, "| value: ", obj[key]);
        } else obj[key] = parsedDate;
      }
      if (key.includes("-Comment")) {
        const keyWithoutComment = key.split("-Comment")[0];
        const keyData = obj[keyWithoutComment];
        if (Array.isArray(keyData)) {
          const newKeyData = keyData.map((data) => {
            if (data === "other") {
              // replace the value with the value from the -Comment key
              return obj[key];
            }
            return data;
          });
          obj[keyWithoutComment] = newKeyData;
        } else if (typeof keyData === "string" && keyData === "other") {
          obj[keyWithoutComment] = obj[key];
        }
      }
      obj[key] = parseData(obj[key], parseDates);
    });
  } else if (Array.isArray(obj)) {
    obj.forEach((item) => {
      item = parseData(item, parseDates);
    });
  }
  // else if (typeof obj === "boolean") {
  //   return obj ? "Yes" : "No";
  // }
  return obj;
};

export const extractAddress = (value: any) => {
  if (value["googleMapsPlace"]) {
    const place = value.googleMapsPlace;
    const address = place?.formatted_address
      ? place.formatted_address?.includes(place.name ? place.name : "")
        ? place.formatted_address
        : place.name + " - " + place.formatted_address
      : "";
    return address;
  }
};

export type ReportAttachment = {
  incident_evidence_description: string;
  incident_evidence_upload: UploadFile[];
};

export const getAttachmentContent = (
  reportAttachments: ReportAttachment[] = [],
  attachments: SubmissionAttachment[],
) => {
  reportAttachments.forEach((attachment) => {
    attachment.incident_evidence_upload?.forEach((uploadFile) => {
      const attachmentContent = attachments.find(
        (attachment) => attachment.filename === uploadFile.name,
      );
      if (attachmentContent) {
        uploadFile.content = attachmentContent.presigned_get.s3_presigned.uri;
      }
    });
  });
};

export const updateElementTypes = (element: Question) => {
  const newElement = { ...element } as Question;
  if (newElement.type === "address-autocomplete") {
    newElement.type = "text";
  }
  // else if (newElement.type === "boolean") {
  //   newElement.type = "text";
  // }
  else if (newElement.type === "text") {
    newElement.type = "comment";
    newElement.rows = 1;
    newElement.autoGrow = true;
    newElement.minWidth = "auto";
  }
  ["elements", "templateElements"].forEach((key) => {
    if (newElement[key]) {
      newElement[key] = newElement[key].map(updateElementTypes);
    }
  });
  return newElement;
};

const generateExtraPDFData = (reportMetadata: any, description: string) => {
  return {
    incident_type: description,
    report_status: statusTypes[reportMetadata.status]
      ? statusTypes[reportMetadata.status]
      : reportMetadata.status,
    report_trackingNumber: reportMetadata.tracking_number ?? "",
    report_agencyRMSNumber: reportMetadata.incident_number ?? "",
    report_reportedDateTime: reportMetadata.time_created
      ? new Date(reportMetadata.time_created).toLocaleString()
      : "'",
    report_approvedByName:
      lastUpdatedBy(reportMetadata as SubmissionSummary, false) ?? "",
    report_approvedByBadge: reportMetadata.last_updated_by?.badge_number ?? "",
    report_processedByName: lastUpdatedBy(
      reportMetadata as SubmissionSummary,
      false,
    ),
    report_processedByBadge: reportMetadata.last_updated_by?.badge_number ?? "",
    supplementary_reportedDateTime: reportMetadata.time_created
      ? new Date(reportMetadata.time_created).toLocaleString()
      : "'",
    supplementary_linkedDateTime: reportMetadata.time_updated
      ? new Date(reportMetadata.time_updated)
      : "'",
    report_officerNotes:
      reportMetadata?.report_officerNotes ??
      reportMetadata?.approve_note ??
      reportMetadata?.reject_note ??
      reportMetadata?.escalate_note ??
      "",
  };
};

export const createCondensedModel = async (
  basePDFStructure: any,
  data: any,
  attachments: SubmissionAttachment[],
  hideAdminInformation = true,
  isSupplementary = false,
) => {
  const basePDFStructureCopy: any = {
    ...basePDFStructure,
    headerView: false,
    autoGrowComment: true,
    allowResizeComment: false,
    width: "960px",
  };
  const supplementaryAdminInformationName =
    "ADMINISTRATIVE SUPPLEMENTARY INFORMATION";
  const originalReportAdminInformationName = "Administrative Information";

  basePDFStructureCopy.pages = basePDFStructureCopy.pages.map((page) => ({
    ...page,
    elements: page.elements.map(updateElementTypes),
  }));

  if (hideAdminInformation) {
    basePDFStructureCopy.pages = basePDFStructureCopy.pages.map((page) => ({
      ...page,
      elements: page.elements.filter((element) => {
        return (
          !element.title
            ?.toLowerCase()
            .includes(supplementaryAdminInformationName.toLowerCase()) &&
          !element.title
            ?.toLowerCase()
            .includes(originalReportAdminInformationName.toLowerCase())
        );
      }),
    }));
  } else {
    if (isSupplementary) {
      basePDFStructureCopy.pages = basePDFStructureCopy.pages.map((page) => ({
        ...page,
        elements: page.elements.filter(
          (element) =>
            !element.title
              ?.toLowerCase()
              .includes(originalReportAdminInformationName.toLowerCase()),
        ),
      }));
    }
  }

  const tempData = { ...data };

  let reportAttachments: ReportAttachment[] = [];

  // TODO : remove empty fields from basePDFStructureCopy
  const emptyFields = [];

  extractAttachmentsFromObject(tempData, reportAttachments);
  parseData(tempData, !hideAdminInformation);

  if (basePDFStructure["description"]?.includes("Bicycle Registration")) {
    await loadImageData(tempData, attachments, true);
  } else {
    removeAttachmentsFromPages(basePDFStructureCopy.pages);
    getAttachmentContent(reportAttachments, attachments);
  }

  reportAttachments = reportAttachments.filter(
    (attachment) => attachment.incident_evidence_upload?.length > 0,
  );

  // convert datetime-local to text if PDF rendering
  // Clone rendering is not parsing the datetime correctly
  let modelJSON = basePDFStructureCopy;

  if (!hideAdminInformation) {
    modelJSON = JSON.parse(
      JSON.stringify(basePDFStructureCopy).replaceAll("datetime-local", "text"),
    );
  }
  const newSurvey = new Model(modelJSON);
  newSurvey.mode = "display";

  const condensedViewTheme: ITheme = {
    ...defaultTheme,
    cssVariables: {
      ...defaultTheme.cssVariables,
      "--sjs-base-unit": "8px", // controls padding and margin
      "--sd-base-padding": "2px", // base padding
      "--sd-base-vertical-padding": "2px", // base padding
      "--sv-element-add-padding-left": "8px",
      "--sjs-corner-radius": "4px",
      "--sjs-border-default": "rgba(0, 0, 0, 0)", // panel border
      "--sjs-shadow-inner": "0px 0px 0px 1px rgba(0, 0, 0, 0.1)", // input field border
      // INPUT FIELD PROPERTIES
      "--sjs-editor-background": "rgba(211, 211, 211, 0.2)",
      "--sjs-editorpanel-backcolor": "rgba(211, 211, 211, 0.1)",
      "--sjs-general-backcolor-dim-light": "rgba(211, 211, 211,0.2)",
      // QUESTION TITLE
      "--sjs-font-questiontitle-weight": "100",
      "--sjs-font-questiontitle-size": "10px",
      // ANSWERS
      "--sjs-font-editorfont-placeholdercolor": "rgba(0, 0, 0, 1)",
      "--sjs-font-editorfont-weight": "600",
      "--sjs-font-editorfont-size": "12px",
    },
  };
  newSurvey.applyTheme(condensedViewTheme);
  // const customStyles = {
  //   "sliderGhost": {
  //     color: "red",
  //     fontSize: "18px",
  //     backgroundColor: "red"
  //   }
  // };

  // use the .css to get the properties of the survey
  // console.log(newSurvey.css);
  // const prevCss = newSurvey.css;
  newSurvey.css = {
    body: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    boolean: {
      itemDisabled: {},
      itemHover: {},
    },
    page: {
      root: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  };

  if (!isSupplementary) {
    newSurvey.data = {
      ...tempData,
      ...generateExtraPDFData(tempData, basePDFStructure.description),
    };
  } else {
    parseData(tempData, !hideAdminInformation);
    newSurvey.data = tempData;
  }
  return { newSurvey, reportAttachments };
};

export const transformPlaceObject = (
  place: google.maps.places.PlaceResult,
): IPlace => {
  const lat = place.geometry?.location?.lat() ?? 0;
  const lng = place.geometry?.location?.lng() ?? 0;

  return {
    address_components: place.address_components
      ? [...place.address_components]
      : [],
    formatted_address: place.formatted_address ? place.formatted_address : "",
    geometry: { lat, lng },
    name: place.name ? place.name : "",
    url: place.url ? place.url : "",
    types: place.types ? [...place.types] : [],
    place_id: place.place_id ? place.place_id : "",
  };
};
