import {
  GetSubmissionResponsePortal,
  SubmissionAttachment,
} from "../common/api-client/or-api";

export interface SubmissionData {
  details: GetSubmissionResponsePortal | null;
  attachments: SubmissionAttachment[];
}

export const fetchSubmissionData = async (
  submissionId: string,
  getSubmissionForID: (
    id: string,
    version?: string,
  ) => Promise<GetSubmissionResponsePortal | null>,
): Promise<SubmissionData> => {
  try {
    const result = await getSubmissionForID(submissionId);
    return {
      details: result,
      attachments: result?.attachments || [],
    };
  } catch (error) {
    console.error("Error fetching submission data:", error);
    return {
      details: null,
      attachments: [],
    };
  }
};

export interface MediaDetail {
  media_type: "video" | "image";
  url: string;
}

export const parseMediaDetails = (
  attachments: SubmissionAttachment[],
  limit = 4,
): MediaDetail[] => {
  const mediaDetails: MediaDetail[] = [];

  attachments.forEach((file) => {
    if (file.content_type?.includes("video")) {
      mediaDetails.push({
        media_type: "video",
        url: file.presigned_get?.s3_presigned.uri || "",
      });
    } else if (file.content_type?.includes("image")) {
      mediaDetails.push({
        media_type: "image",
        url: file.presigned_get?.s3_presigned.uri || "",
      });
    }
  });

  // Sort videos first, then limit to specified number of media items
  mediaDetails.sort((a, b) => (a.media_type === "video" ? -1 : 1));
  return mediaDetails.slice(0, limit);
};
