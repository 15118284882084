import { Button, Link, Typography } from "@mui/material";
import { pdf } from "@react-pdf/renderer";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { generateAlertPDF } from "./generateAlertPDF";
import { serviceConfigurations } from "../../../../../common/serviceConfigurations";
import { isSmallScreen, mailTo } from "../../../../../common/utils";
import { AuthStateContext } from "../../../../../context";
import { IUserPermission, MediaBlob } from "../../../../../types";
import { DisabledComponentDialog } from "../../../../DisabledComponent";
import { parseUserPermissions } from "../../../../UserManagement/utils";
import { SingleAlertContext } from "../../../context";
import { captureMiddleFrameFromBlob } from "../utils";

enum PDFButtonText {
  PreparingPDF = "preparing_pdf",
  GeneratePDF = "generate_pdf",
  HidePDFPreview = "hide_pdf_preview",
  DownloadPDF = "download_pdf",
}

const { PreparingPDF, GeneratePDF, HidePDFPreview, DownloadPDF } =
  PDFButtonText;

const generatePDFName = (
  bulletinId: string,
  issuedTime: string,
  policeShortName?: string,
) => {
  let name = "";
  if (policeShortName?.length) {
    name += `${policeShortName}_`;
  }
  const shortTime = dayjs(issuedTime).format("YYYY_MM_DD");
  name += `ALERTID_${bulletinId}_ISSUED_${shortTime}.pdf`;
  return name;
};

interface DownloadReportPDFProps {
  policeLogo: any;
  mediaBlobs: MediaBlob[];
  issuedTime: string | undefined;
  disabled?: boolean;
}

const DownloadReportPDF = ({
  policeLogo,
  mediaBlobs,
  issuedTime,
  disabled,
}: DownloadReportPDFProps) => {
  const alertProperties = useContext(SingleAlertContext).alertProperties;
  const [isDisplayingPDF, setIsDisplayingPDF] = useState<boolean>(false);
  const [pdfUrl, setPdfUrl] = useState<string>("");

  const [imageBlobs, setImageBlobs] = useState<MediaBlob[]>([]);

  const { currentUser } = useContext(AuthStateContext);
  const [isReadOnlyDialogOpen, setIsReadOnlyDialogOpen] = useState(false);
  const [alertPermissions] = useState(
    currentUser &&
      parseUserPermissions(currentUser?.permissions as IUserPermission[]),
  );
  const readOnly = alertPermissions
    ? !alertPermissions.canCreateBulletin
    : false;
  const { t } = useTranslation("bulletin");
  const generatePDF = async () => {
    const downloadable = await pdf(
      generateAlertPDF({
        alertProperties,
        policeLogo,
        evidenceArray: imageBlobs,
        issuedDate: issuedTime,
        currentUser,
      }),
    ).toBlob();
    const pdfUrl = URL.createObjectURL(downloadable);
    setPdfUrl(pdfUrl);
    return downloadable;
  };

  const pdfName = () => {
    const { bulletinId, issuedTime, policeService } = alertProperties;
    let shortName = "";
    if (policeService?.name) {
      shortName = serviceConfigurations[policeService.name]?.shortName || "";
    }

    return generatePDFName(
      bulletinId?.toString() || "",
      issuedTime?.toISOString() || "",
      shortName,
    );
  };

  const isMobileView = isSmallScreen();

  useEffect(() => {
    const processVideoBlobs = async () => {
      const processedBlobs = await Promise.all(
        mediaBlobs.map(async (blob) => {
          if (blob.media_type === "video") {
            const imageBlob = await new Promise<MediaBlob>((resolve) => {
              captureMiddleFrameFromBlob(blob.blob, (extractedImageBlob) => {
                if (extractedImageBlob) {
                  const mediaBlob: MediaBlob = {
                    blob: extractedImageBlob,
                    media_type: "video_thumbnail",
                    filename: blob.filename,
                    dimensions: { width: 0, height: 0 },
                  };
                  resolve(mediaBlob);
                } else {
                  resolve(blob);
                }
              });
            });
            return imageBlob;
          } else {
            return blob;
          }
        }),
      );

      setImageBlobs(processedBlobs);
    };

    if (!readOnly) processVideoBlobs();
  }, [mediaBlobs]);

  useEffect(() => {
    if (disabled) {
      setIsDisplayingPDF(false);
    }
  }, [disabled]);

  useEffect(() => {
    if (!readOnly) generatePDF();
  }, [mediaBlobs.length]);

  const buttonText = () => {
    if (disabled) {
      return PreparingPDF;
    } else if (isDisplayingPDF) {
      return HidePDFPreview;
    }
    return GeneratePDF;
  };

  const handleGeneratePDFDesktop = async () => {
    if (!readOnly) {
      await generatePDF();
      setIsDisplayingPDF(!isDisplayingPDF);
    } else {
      setIsReadOnlyDialogOpen(true);
    }
  };

  const handleGeneratePDFMobile = async () => {
    if (!readOnly) {
      await generatePDF();
      saveAs(pdfUrl, pdfName());
    } else {
      setIsReadOnlyDialogOpen(true);
    }
  };

  return (
    <>
      {!isMobileView && (
        <>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleGeneratePDFDesktop}
            style={{
              width: "100%",
              marginBottom: buttonText() === HidePDFPreview ? "16px" : "0",
            }}
            disabled={disabled}
          >
            {t(buttonText())}
          </Button>

          {pdfUrl && isDisplayingPDF && (
            <>
              <iframe
                src={`${pdfUrl}#toolbar=0`}
                width="100%"
                height="1000px"
                style={{
                  marginBottom: "16px",
                }}
              />
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  saveAs(pdfUrl, pdfName());
                }}
                style={{ width: "100%" }}
              >
                {DownloadPDF}
              </Button>
            </>
          )}
          <DisabledComponentDialog
            isDialogOpen={isReadOnlyDialogOpen}
            handleClose={() => {
              setIsReadOnlyDialogOpen(false);
            }}
            title="You do not have access to downloading this bulletin."
            message={
              <Typography>
                Please contact{" "}
                <Link
                  rel="noopener noreferrer"
                  target="_blank"
                  href={mailTo({
                    email: "support@tryrubicon.com",
                    subject: "Requesting Access to Download Bulletins",
                    body: `Hello Rubicon Support Team, 
  
                    I would like to request access to download bulletins. I am trying to download a bulletin with id ${alertProperties.bulletinId}.
  
                    Thank you. 
  
                    Sincerely,
  
                    ${currentUser?.first_name} ${currentUser?.last_name} 
                    ${currentUser?.email}`,
                  })}
                  color="secondary"
                >
                  support@tryrubicon.com
                </Link>{" "}
                to request access.
              </Typography>
            }
          />
        </>
      )}
      {isMobileView && (
        <>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleGeneratePDFMobile}
            style={{ width: "100%" }}
            disabled={disabled}
          >
            {disabled ? PreparingPDF : GeneratePDF}
          </Button>
          <DisabledComponentDialog
            isDialogOpen={isReadOnlyDialogOpen}
            handleClose={() => {
              setIsReadOnlyDialogOpen(false);
            }}
            title="You do not have access to downloading this bulletin."
            message={
              <Typography>
                Please contact{" "}
                <Link
                  rel="noopener noreferrer"
                  target="_blank"
                  href={mailTo({
                    email: "support@tryrubicon.com",
                    subject: "Requesting Access to Download Bulletins",
                    body: `Hello Rubicon Support Team, 
  
                    I would like to request access to download bulletins. I am trying to download a bulletin with id ${alertProperties.bulletinId}.
  
                    Thank you. 
  
                    Sincerely,
  
                    ${currentUser?.first_name} ${currentUser?.last_name} 
                    ${currentUser?.email}`,
                  })}
                  color="secondary"
                >
                  support@tryrubicon.com
                </Link>{" "}
                to request access.
              </Typography>
            }
          />
        </>
      )}
    </>
  );
};

export default DownloadReportPDF;
