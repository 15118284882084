import React, { createContext, useState } from "react";

import { useDevEnvironment } from "../../DevContext";
import {
  GetRv2UsersResponse,
  GetSubmissionResponsePortal,
  GetSurveyResponse,
  SubmissionSummary,
  SurveyConfiguration,
} from "../../common/api-client/or-api";
import { API } from "../../common/api-client/or-api/api";

export interface OnlineReportingState {
  reports: SubmissionSummary[];
  reportTypeNames: string[];
  reportConfigurations: SurveyConfiguration[] | null;
  totalReportsCount: number;
  fetchLatestReports: (
    organizationID: string,
    timeCreatedMin?: string,
    timeCreatedMax?: string,
    timeUpdatedMin?: string,
    timeUpdatedMax?: string,
    status?: string,
    trackingNumber?: string,
    limit?: number,
    offset?: number,
    reportType?: string,
    search?: string,
    assignedTo?: string,
  ) => Promise<void>;
  fetchReportConfigurations: (organizationID: string) => Promise<void>;
  getSubmissionForID: (
    submissionID: string,
    version?: string,
  ) => Promise<GetSubmissionResponsePortal | null>;
  getSurveyForID: (surveyID: string) => Promise<GetSurveyResponse | null>;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  filtersVisible: boolean;
  setFiltersVisible: React.Dispatch<React.SetStateAction<boolean>>;
  searchView: "list" | "map" | "card";
  setSearchView: React.Dispatch<React.SetStateAction<"list" | "map" | "card">>;
  rv2Users: GetRv2UsersResponse | null;
  fetchRv2Users: (organizationID: string) => Promise<void>;
}

// Create the context
export const OnlineReportingContext = createContext<OnlineReportingState>({
  searchText: "",
  setSearchText: () => {},
  reports: [],
  reportTypeNames: [],
  reportConfigurations: null,
  totalReportsCount: 0,
  fetchLatestReports: async () => {},
  fetchReportConfigurations: async () => {},
  getSubmissionForID: async () => null,
  getSurveyForID: async () => null,
  filtersVisible: false,
  setFiltersVisible: () => {},
  searchView: "list",
  setSearchView: () => {},
  rv2Users: null,
  fetchRv2Users: async () => {},
});

const OnlineReportingProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { isDevEnv } = useDevEnvironment();
  const [reports, setReports] = useState<SubmissionSummary[]>([]);
  const [reportTypeNames, setReportTypeNames] = useState<string[]>([]);
  const [reportConfigurations, setReportConfigurations] = useState<
    SurveyConfiguration[] | null
  >(null);
  const [totalReportsCount, setTotalReportsCount] = useState(0);

  const [rv2Users, setRv2Users] = useState<GetRv2UsersResponse | null>(null);

  // search
  const [searchText, setSearchText] = useState<string>("");
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [searchView, setSearchView] = useState<"list" | "map" | "card">("list");

  async function fetchLatestReports(
    organizationID: string,
    timeCreatedMin?: string,
    timeCreatedMax?: string,
    timeUpdatedMin?: string,
    timeUpdatedMax?: string,
    status?: string,
    trackingNumber?: string,
    limit?: number,
    offset?: number,
    reportType?: string,
    search?: string,
    assignedTo?: string,
  ) {
    const fullList = await API.portal.getList(
      organizationID,
      timeCreatedMin,
      timeCreatedMax,
      timeUpdatedMin,
      timeUpdatedMax,
      status,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      trackingNumber ?? null,
      limit,
      offset,
      reportType && reportType?.trim().length > 0 ? reportType : null,
      search && search?.trim().length > 0 ? searchText : null,
      assignedTo ?? null,
    );
    setReports(fullList.submissions);
    setTotalReportsCount(fullList.total_count);
  }

  async function fetchReportConfigurations(organizationID: string) {
    const result = await API.orgConfig.get(organizationID);
    setReportConfigurations(result.surveys);
    const names = result.surveys.map((survey) => survey.name);
    setReportTypeNames(names);
  }

  async function getSubmissionForID(submissionID: string, version?: string) {
    const result = await API.submission.getPortal(
      submissionID,
      version ?? "latest",
    );
    return result;
  }

  async function getSurveyForID(surveyID: string) {
    const result = await API.survey.get(surveyID);
    return result;
  }

  async function fetchRv2Users(organizationID: string) {
    const usersList = await API.portal.getRv2Users(organizationID);
    setRv2Users(usersList);
  }

  return (
    <OnlineReportingContext.Provider
      value={{
        reports,
        reportTypeNames: reportTypeNames,
        reportConfigurations: reportConfigurations,
        totalReportsCount,
        fetchLatestReports,
        fetchReportConfigurations,
        getSubmissionForID,
        getSurveyForID,
        searchText,
        setSearchText,
        filtersVisible,
        setFiltersVisible,
        searchView,
        setSearchView,
        rv2Users,
        fetchRv2Users,
      }}
    >
      {children}
    </OnlineReportingContext.Provider>
  );
};

export default OnlineReportingProvider;
