// Temporary FormContextProvider while we wait for the new API endpoints

import { createContext, useContext, useState } from "react";

import {
  SurveyConfiguration,
  SurveyModel,
} from "../../../common/api-client/or-api";

const FormContext = createContext<{
  surveyConfigs: SurveyConfiguration[];
  setSurveyConfigs: (surveyConfigs: SurveyConfiguration[]) => void;
  draftSurveys: SurveyModel[];
  setDraftSurveys: (draftSurveys: SurveyModel[]) => void;
  surveyModels: SurveyModel[];
  setSurveyModels: (surveyModels: SurveyModel[]) => void;
}>(
  {} as {
    surveyConfigs: SurveyConfiguration[];
    setSurveyConfigs: (surveyConfigs: SurveyConfiguration[]) => void;
    draftSurveys: SurveyModel[];
    setDraftSurveys: (draftSurveys: SurveyModel[]) => void;
    surveyModels: SurveyModel[];
    setSurveyModels: (surveyModels: SurveyModel[]) => void;
  },
);

export const FormContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [surveyConfigs, setSurveyConfigs] = useState<SurveyConfiguration[]>([]);
  const [draftSurveys, setDraftSurveys] = useState<SurveyModel[]>([]);
  const [surveyModels, setSurveyModels] = useState<SurveyModel[]>([]);

  return (
    <FormContext.Provider
      value={{
        surveyConfigs,
        setSurveyConfigs,
        draftSurveys,
        setDraftSurveys,
        surveyModels,
        setSurveyModels,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export const useFormBuilderContext = () => useContext(FormContext);
