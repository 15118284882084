import { Delete } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import GooglePlacesAutocomplete from "./atomic-UI-components/GooglePlaceAutocomplete";
import { isSmallScreen } from "./utils";
import { LOCATION_CLASSIFICATION } from "../components/alerts/constants";
import { PlaceType } from "../types";

interface IAddressListProps {
  addresses: PlaceType[] | null[];
  setAddresses: any;
  errors: any;
}

const AddressList = ({
  addresses,
  setAddresses,
  errors,
}: IAddressListProps) => {
  const addAddressField = () => {
    setAddresses((prevAddressFields) => [
      ...prevAddressFields,
      {
        description: "",
        place_id: "",
        structured_formatting: { main_text: "", secondary_text: "" },
        location_classification: LOCATION_CLASSIFICATION.INCIDENT_ADDRESS,
        other_location_classification: "",
      },
    ]);
  };

  const removeAddressField = (index: number) => {
    setAddresses((prevAddressFields) =>
      prevAddressFields.filter((_, idx) => idx !== index),
    );
  };

  const locationClassificationOptions = Object.values(LOCATION_CLASSIFICATION);
  const showError = errors.some(
    (address) => address && (!address.isValid || !address.hasLocationType),
  );

  const isSmall = isSmallScreen();

  return (
    <>
      <div>
        {addresses?.map((addressField, index) => (
          <Box
            key={index + "-" + addressField?.place_id}
            sx={{
              display: "flex",
              flexDirection: isSmall ? "column" : "row",
              gap: isSmall ? "4px" : "8px",
              width: "100%",
              marginBottom: isSmall
                ? index === addresses.length - 1
                  ? "0px"
                  : "16px"
                : "0px",
            }}
          >
            <div
              style={{
                display: addressField?.place_id ? "flex" : "none",
                flexDirection: "row",
                width: !isSmall ? "40%" : "100%",
                gap: "4px",
              }}
            >
              <FormControl fullWidth>
                <Autocomplete
                  id="locationClassification"
                  options={locationClassificationOptions}
                  getOptionLabel={(option) => option}
                  value={addressField?.location_classification}
                  autoComplete
                  autoHighlight
                  clearOnBlur
                  filterSelectedOptions
                  openOnFocus
                  isOptionEqualToValue={(option, value) => option === value}
                  filterOptions={(options, params) => {
                    return options.filter((option) =>
                      option
                        .toLowerCase()
                        .includes(params.inputValue.toLowerCase()),
                    );
                  }}
                  onChange={(event, value) => {
                    const newAddress = {
                      ...addressField,
                      location_classification: value,
                    };
                    const newAddresses = [...addresses];
                    newAddresses[index] = newAddress;
                    if (value !== LOCATION_CLASSIFICATION.OTHER) {
                      newAddress.other_location_classification = "";
                    }
                    setAddresses(newAddresses);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={isSmall ? `Location Type ${index + 1}` : `Type`}
                      variant="outlined"
                      margin="none"
                      error={errors[index] && !errors[index].hasLocationType}
                      required
                      style={{
                        width: "100%",
                      }}
                    />
                  )}
                  disabled={!addressField?.place_id}
                  hidden={!addressField?.place_id}
                />
              </FormControl>
              {addressField?.location_classification ===
                LOCATION_CLASSIFICATION.OTHER && (
                <TextField
                  label="Other"
                  variant="outlined"
                  margin="none"
                  value={addressField?.other_location_classification}
                  onChange={(event) => {
                    const newAddress = {
                      ...addressField,
                      other_location_classification: event.target.value,
                    };
                    const newAddresses = [...addresses];
                    newAddresses[index] = newAddress;
                    setAddresses(newAddresses);
                  }}
                  error={errors[index] && !errors[index].hasLocationType}
                  sx={{
                    marginTop: "8px",
                    marginBottom: "4px",
                    width: "100%",
                  }}
                  required
                />
              )}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: isSmall
                  ? "100%"
                  : !addressField?.place_id
                    ? "100%"
                    : "60%",
              }}
            >
              <GooglePlacesAutocomplete
                selectedAddress={addressField}
                setSelectedAddress={setAddresses}
                error={errors[index] && !errors[index].isValid}
                label={`Location ${index + 1}`}
                index={index}
              />
              {addresses.length > 1 && (
                <Tooltip title="Delete This Address" placement="left">
                  <IconButton
                    onClick={() => removeAddressField(index)}
                    color="primary"
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </Box>
        ))}
      </div>
      {showError && (
        <Typography
          variant="caption"
          color="error"
          sx={{ marginLeft: !isSmall ? "10px" : "0px" }}
        >
          Please provide a valid location type and select a valid address from
          the dropdown.
        </Typography>
      )}
      {!addresses?.some((element) => element === null) && (
        <div>
          <Button onClick={addAddressField}>Add Another Location</Button>
        </div>
      )}
    </>
  );
};

export default AddressList;
