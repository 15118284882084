/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CameraModel } from '../models/CameraModel';
import type { PatchCameraRequest } from '../models/PatchCameraRequest';
import type { PutCameraRequest } from '../models/PutCameraRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CameraService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param propertyId
     * @param requestBody
     * @returns CameraModel
     * @throws ApiError
     */
    public putPortal(
        propertyId: string,
        requestBody: PutCameraRequest,
    ): CancelablePromise<CameraModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/camera_registry/portal/property/{property_id}/camera',
            path: {
                'property_id': propertyId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param propertyId property_id
     * @param cameraId camera_id
     * @returns any
     * @throws ApiError
     */
    public deletePortal(
        propertyId: string,
        cameraId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/camera_registry/portal/property/{property_id}/camera/{camera_id}',
            path: {
                'property_id': propertyId,
                'camera_id': cameraId,
            },
        });
    }

    /**
     * @param propertyId property_id
     * @param cameraId camera_id
     * @param requestBody
     * @returns CameraModel
     * @throws ApiError
     */
    public patchPortal(
        propertyId: string,
        cameraId: string,
        requestBody: PatchCameraRequest,
    ): CancelablePromise<CameraModel> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/camera_registry/portal/property/{property_id}/camera/{camera_id}',
            path: {
                'property_id': propertyId,
                'camera_id': cameraId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param propertyId
     * @param requestBody
     * @returns CameraModel
     * @throws ApiError
     */
    public putPublic(
        propertyId: string,
        requestBody: PutCameraRequest,
    ): CancelablePromise<CameraModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/camera_registry/public/property/{property_id}/camera',
            path: {
                'property_id': propertyId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param propertyId property_id
     * @param cameraId camera_id
     * @returns any
     * @throws ApiError
     */
    public deletePublic(
        propertyId: string,
        cameraId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/camera_registry/public/property/{property_id}/camera/{camera_id}',
            path: {
                'property_id': propertyId,
                'camera_id': cameraId,
            },
        });
    }

    /**
     * @param propertyId property_id
     * @param cameraId camera_id
     * @param requestBody
     * @returns CameraModel
     * @throws ApiError
     */
    public patchPublic(
        propertyId: string,
        cameraId: string,
        requestBody: PatchCameraRequest,
    ): CancelablePromise<CameraModel> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/camera_registry/public/property/{property_id}/camera/{camera_id}',
            path: {
                'property_id': propertyId,
                'camera_id': cameraId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
