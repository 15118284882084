import {
  ArrowBack,
  ArrowForward,
  Cancel,
  Edit,
  ErrorOutline,
  Share,
  TaskAlt,
} from "@mui/icons-material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PushPinIcon from "@mui/icons-material/PushPin";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import {
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Theme,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useTheme } from "react-admin";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useDrawer } from "../../../../common/SlideoutDrawer/DrawerContext";
import "../../alerts.scss";
import { apiAlertClient } from "../../../../common/api-client";
import { AuthStateContext } from "../../../../context";
import { BulletinResourceResponse, IUserPermission } from "../../../../types";
import { parseUserPermissions } from "../../../UserManagement/utils";
import ShareDialog from "../../ShareDialog";
import { DisplayAlert } from "../../add/DisplayAlert";
import ResolveBulletin from "../../add/DisplayAlert/ResolveBulletin";
import { ALERT_STATUS } from "../../constants";
import {
  AlertViewContext,
  SingleAlertContext,
  SingleAlertProvider,
} from "../../context";
import { updateToContextStructure } from "../../utils";

interface DisplayAlertWrapperProps {
  mode: ALERT_STATUS;
  display_type: "main" | "compare";
  queryNextPage?: () => void;
}

export const DisplayAlertWrapper = ({
  mode,
  display_type,
  queryNextPage = () => {},
}: DisplayAlertWrapperProps) => {
  const { currentItems } = useContext(AlertViewContext);
  const { currentUser } = useContext(AuthStateContext);
  const { alertProperties, setAlertProperties } =
    useContext(SingleAlertContext);
  const { setDrawerWidth, closeDrawer } = useDrawer();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation("bulletin");
  const idToUse =
    display_type === "main"
      ? parseInt(searchParams.get("bulletinId") ?? "0")
      : parseInt(searchParams.get("compareBulletinId") ?? "0");
  const isAlertSingleView = location.pathname.includes("/single");
  const [alertPermissions] = useState(
    currentUser &&
      parseUserPermissions(currentUser?.permissions as IUserPermission[]),
  );

  const [currentItem, setCurrentItem] = useState<BulletinResourceResponse>(
    {} as BulletinResourceResponse,
  );
  const alertCreatorId = currentItem?.creator_user_id;

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isResolveOpen, setIsResolveOpen] = useState(false);
  const [isCreator, setIsCreator] = useState(
    currentUser?.id === alertCreatorId,
  );
  const [isLoaded, setIsLoaded] = useState(false);

  const readOnly = alertPermissions
    ? !alertPermissions.canCreateBulletin
    : false;
  const { resolutions } = alertProperties;
  const latestPublishedResolution = resolutions
    ?.filter((resolution) => resolution.published)
    .sort((a, b) => {
      return (
        new Date(b.time_updated).getTime() - new Date(a.time_updated).getTime()
      );
    })[0];

  const hasUnpublishedResolution = resolutions?.some(
    (resolution) =>
      !resolution.published &&
      (resolution.details?.length > 0 ||
        Object.values(resolution.attachments).length > 0 ||
        resolution.reason?.length > 0),
  );

  const [pinnedItem, setPinnedItem] = useState<BulletinResourceResponse | null>(
    null,
  );

  useEffect(() => {
    const searchParams = window.location.search;
    if (searchParams.includes("share=true")) {
      setIsShareModalOpen(true);
    }
  }, []);

  const navigate = useNavigate();

  const [theme] = useTheme();
  const isSmall = useMediaQuery((theme as Theme).breakpoints.down("sm"));
  const containerRef = useRef<HTMLDivElement>(null);

  const alertsOrDrafts = currentItem?.is_draft === 1 ? "drafts" : "alerts";

  if (isSmall && searchParams.get("bulletinId") != null && !isAlertSingleView) {
    navigate(
      `/rubialert/${alertsOrDrafts}/${searchParams.get("bulletinId")}/single`,
    );
  }
  const getAlertById = (id) => {
    apiAlertClient
      .getBulletinById(id)
      .then((res) => {
        setCurrentItem(res);
        setIsLoaded(true);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const onEditAlert = () => {
    navigate(`/rubialert/${alertsOrDrafts}/${idToUse}/single/edit`);
  };
  const onShareAlert = () => {
    setIsShareModalOpen(true);
  };

  const shareModal = () => {
    if (!isShareModalOpen) return null;
    else
      return (
        <ShareDialog
          isShareModalOpenArg={isShareModalOpen}
          setIsShareModalOpenArg={setIsShareModalOpen}
          bulletinID={currentItem?.id}
        />
      );
  };

  const resolveCard = () => {
    if (isResolveOpen) {
      return (
        <ResolveBulletin
          bulletinType={currentItem?.type}
          bulletinId={currentItem?.id}
          setIsResolveOpen={setIsResolveOpen}
        />
      );
    }
  };

  const hasNextAlert = () => {
    if (currentItems) {
      const compareBulletinId = parseInt(
        searchParams.get("compareBulletinId") ?? "0",
      );
      let index = 0;
      if (compareBulletinId) {
        index = currentItems.map((item) => item.id).indexOf(compareBulletinId);
      } else {
        index = currentItems.map((item) => item.id).indexOf(idToUse);
      }
      index++;
      // if there's less than 5 alerts, load more page.
      if (index === currentItems.length - 5) {
        queryNextPage();
      }
      return index < currentItems.length;
    }
    return false;
  };

  const hasPreviousAlert = () => {
    if (currentItems) {
      const compareBulletinId = parseInt(
        searchParams.get("compareBulletinId") ?? "0",
      );
      let index = 0;
      if (compareBulletinId) {
        index = currentItems.map((item) => item.id).indexOf(compareBulletinId);
      } else {
        index = currentItems.map((item) => item.id).indexOf(idToUse);
      }
      index--;
      return index >= 0;
    }
    return false;
  };

  const onNextAlert = () => {
    const pinToFront = searchParams.get("pinToFront");
    if (currentItems) {
      const compareBulletinId = parseInt(
        searchParams.get("compareBulletinId") ?? "0",
      );
      let index = 0;
      if (compareBulletinId) {
        index = currentItems.map((item) => item.id).indexOf(compareBulletinId);
        index++;
      } else {
        index = currentItems.map((item) => item.id).indexOf(idToUse);
        index++;
      }
      if (index < currentItems.length) {
        if (pinToFront !== "true") {
          setSearchParams((searchParams) => {
            searchParams.set("bulletinId", currentItems[index].id.toString());
            return searchParams;
          });
        } else {
          // if there's no compareBulletinId, set drawer width to 0.7
          if (!searchParams.get("compareBulletinId")) {
            setDrawerWidth(window.innerWidth * 0.7);
          }
          setSearchParams((searchParams) => {
            searchParams.set(
              "compareBulletinId",
              currentItems[index].id.toString(),
            );
            return searchParams;
          });
        }
      }
    }
    // if this is the last alert, use queryNextPage
    if (!hasNextAlert()) {
      queryNextPage();
    }
  };

  const onPreviousAlert = () => {
    const pinToFront = searchParams.get("pinToFront");

    if (currentItems) {
      const compareBulletinId = parseInt(
        searchParams.get("compareBulletinId") ?? "0",
      );
      let index = 0;
      if (compareBulletinId) {
        index = currentItems.map((item) => item.id).indexOf(compareBulletinId);
        index--;
      } else {
        index = currentItems.map((item) => item.id).indexOf(idToUse);
        index--;
      }
      if (index >= 0) {
        if (pinToFront !== "true") {
          setSearchParams((searchParams) => {
            searchParams.set("bulletinId", currentItems[index].id.toString());
            return searchParams;
          });
        } else {
          setSearchParams((searchParams) => {
            // if there's no compareBulletinId, set drawer width to 0.7
            if (!searchParams.get("compareBulletinId")) {
              setDrawerWidth(window.innerWidth * 0.7);
            }
            searchParams.set(
              "compareBulletinId",
              currentItems[index].id.toString(),
            );
            return searchParams;
          });
        }
      }
    }
  };

  const closeCompareBulletin = () => {
    setSearchParams((searchParams) => {
      const pinToFront = searchParams.get("pinToFront");
      if (pinToFront) {
        searchParams.delete("compareBulletinId");
        setDrawerWidth(window.innerWidth * 0.5);
      }
      return searchParams;
    });
  };

  const closeMainBulletin = () => {
    setSearchParams((searchParams) => {
      const pinToFront = searchParams.get("pinToFront");
      const compareBulletinId = searchParams.get("compareBulletinId");
      const bulletinId = searchParams.get("bulletinId");
      if (pinToFront) {
        if (compareBulletinId) {
          searchParams.set("bulletinId", compareBulletinId);
          searchParams.delete("compareBulletinId");
          setDrawerWidth(window.innerWidth * 0.5);
        } else {
          searchParams.delete("bulletinId");
          closeDrawer();
        }
      } else {
        searchParams.delete("bulletinId");
        closeDrawer();
      }
      searchParams.delete("pinToFront");
      searchParams.delete("similarTo");

      return searchParams;
    });
  };

  // close compare bulletin button, although it called compare,
  // it actually close the focused bulletin and make pinToFront
  // as the focused bulletin
  const CloseCompareBulletinButton = () => {
    if (display_type === "compare") {
      return (
        <IconButton onClick={closeCompareBulletin}>
          <Cancel color="primary" />
        </IconButton>
      );
    } else {
      return (
        <IconButton onClick={closeMainBulletin}>
          <Cancel color="primary" />
        </IconButton>
      );
    }
  };

  // binding left and right keys to next and previous alerts only for type main
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "ArrowRight") {
        onNextAlert();
      } else if (e.key === "ArrowLeft") {
        onPreviousAlert();
      }
    };
    if (display_type === "main") {
      window.addEventListener("keydown", handleKeyDown);
      return () => window.removeEventListener("keydown", handleKeyDown);
    }
  }, [onNextAlert, onPreviousAlert, queryNextPage]);

  const ExitButton = ({ onExitAlert }: { onExitAlert: () => void }) => {
    return (
      <Button
        variant="outlined"
        color="primary"
        size="medium"
        startIcon={<Cancel />}
        onClick={onExitAlert}
        style={{
          marginTop: "8px",
          height: "40px",
          zIndex: 2,
          borderRadius: "0 10px 10px 0",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.30)",
          backgroundColor: "#fff",
        }}
        disabled={isResolveOpen}
      ></Button>
    );
  };

  const ResolveButton = () => {
    if (
      mode !== ALERT_STATUS.DRAFT &&
      !latestPublishedResolution?.resolved &&
      (readOnly || (!readOnly && isCreator))
    ) {
      return (
        <Tooltip
          title={
            hasUnpublishedResolution
              ? t("unpublished_resolution")
              : t("click_to_resolve")
          }
          placement="top"
        >
          <IconButton
            color={hasUnpublishedResolution ? "error" : "secondary"}
            onClick={() => setIsResolveOpen(!isResolveOpen)}
          >
            {hasUnpublishedResolution ? <ErrorOutline /> : <TaskAlt />}
          </IconButton>
        </Tooltip>
      );
    } else return null;
  };

  const PinToFrontButton = () => {
    const isPinnedToFront = searchParams.get("pinToFront");
    return (
      <Tooltip
        title={isPinnedToFront ? t("unpin_bulletin") : t("pin_bulletin")}
      >
        <IconButton
          aria-label="pin to front"
          style={{
            marginRight: "16px",
            paddingRight: "",
          }}
          color="primary"
          onClick={() => {
            setSearchParams((searchParams) => {
              if (isPinnedToFront) {
                searchParams.delete("pinToFront");
                searchParams.delete("compareBulletinId");
              } else {
                searchParams.set("pinToFront", "true");
              }
              return searchParams;
            });
          }}
        >
          {isPinnedToFront ? <PushPinIcon /> : <PushPinOutlinedIcon />}
        </IconButton>
      </Tooltip>
    );
  };

  const ShareButton = () => {
    if (mode !== ALERT_STATUS.DRAFT && (readOnly || (!readOnly && isCreator)))
      return (
        <Tooltip title={t("share_bulletin")}>
          <IconButton onClick={onShareAlert} color="secondary">
            <Share />
          </IconButton>
        </Tooltip>
      );
    else return null;
  };

  const EditButton = () => {
    if (readOnly || (!readOnly && isCreator))
      return (
        <Tooltip title={t("edit_bulletin")}>
          <IconButton onClick={onEditAlert} color="primary">
            <Edit />
          </IconButton>
        </Tooltip>
      );
    else return null;
  };

  const ShowSimilarButton = () => {
    const isShowingSimilar = searchParams.get("similarTo");

    if ((readOnly || mode !== ALERT_STATUS.DRAFT) && !isShowingSimilar) {
      return (
        <Button
          variant="contained"
          color="primary"
          size="medium"
          style={{
            marginRight: "16px",
          }}
          onClick={() => {
            setSearchParams((searchParams) => {
              searchParams.set("similarTo", currentItem?.id.toString());
              searchParams.set("pinToFront", "true");
              return searchParams;
            });
          }}
        >
          {t("find_similar")}
        </Button>
      );
    } else if (
      (readOnly || mode !== ALERT_STATUS.DRAFT) &&
      isShowingSimilar === currentItem?.id?.toString()
    ) {
      return (
        <Tooltip title={"Hide similar alerts"}>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            style={{
              marginRight: "16px",
            }}
            startIcon={<Cancel />}
            onClick={() => {
              setSearchParams((searchParams) => {
                searchParams.delete("similarTo");
                return searchParams;
              });
            }}
          >
            {t("hide_similar")}
          </Button>
        </Tooltip>
      );
    } else if (
      (readOnly || mode !== ALERT_STATUS.DRAFT) &&
      isShowingSimilar !== currentItem?.id?.toString()
    ) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Chip
            label={`Similar to ${isShowingSimilar}`}
            color="success"
            variant="outlined"
            onClick={() => {
              setSearchParams((searchParams) => {
                // set bulletinId to similarTo
                if (searchParams.get("similarTo")) {
                  searchParams.set(
                    "bulletinId",
                    searchParams.get("similarTo")!,
                  );
                }
                return searchParams;
              });
            }}
          />
          <Button
            variant="contained"
            color="primary"
            size="medium"
            onClick={() => {
              setSearchParams((searchParams) => {
                searchParams.set("similarTo", currentItem?.id.toString());
                return searchParams;
              });
            }}
          >
            {t("find_similar")}
          </Button>
        </div>
      );
    } else return null;
  };

  const PreviousButton = () => {
    return (
      <Button
        variant="outlined"
        color="primary"
        className="border-radius-rounded p-0"
        size="medium"
        onClick={onPreviousAlert}
        style={{
          minWidth: "48px",
          minHeight: "48px",
        }}
        disabled={isResolveOpen || !hasPreviousAlert()}
      >
        <ArrowBack />
      </Button>
    );
  };

  const NextButton = () => {
    return (
      <Button
        variant="contained"
        color="primary"
        className="border-radius-rounded"
        onClick={onNextAlert}
        disabled={isResolveOpen || !hasNextAlert()}
        style={{
          minWidth: "48px",
          minHeight: "48px",
          padding: 0,
        }}
      >
        <ArrowForward />
      </Button>
    );
  };

  const desktopButtons = () => {
    const resolveButton = ResolveButton();
    const shareButton = ShareButton();
    const closeCompareBulletinButton = CloseCompareBulletinButton();
    const editButton = EditButton();
    const showSimilarButton = ShowSimilarButton();
    const pinToFrontButton = PinToFrontButton();
    const draftsNavigationButton = ExitButton({
      onExitAlert: () => {
        navigate(`/rubialert/${alertsOrDrafts}`);
      },
    });

    if (resolveButton || shareButton || editButton || draftsNavigationButton) {
      return (
        <div
          className={
            resolveButton || shareButton || editButton || showSimilarButton
              ? "alert-view-heading"
              : ""
          }
          style={{
            backgroundColor:
              display_type === "compare" ||
              (display_type === "main" &&
                searchParams.get("pinToFront") === idToUse.toString())
                ? "rgb(240, 240, 240)"
                : "",
          }}
        >
          <div>
            {resolveButton}
            {shareButton}
            {editButton}
          </div>
          <div style={{ display: "flex" }}>
            {display_type === "main" && showSimilarButton}
            {display_type === "compare" &&
              !searchParams.get("pinToFront") &&
              showSimilarButton}
            {/* add a button to open in new tab */}
            <IconButton
              aria-label="open in new tab"
              style={{
                marginRight: "16px",
                paddingRight: "",
              }}
              color="primary"
              onClick={() => {
                window.open(
                  `/rubialert/${alertsOrDrafts}/${currentItem?.id}/single`,
                  "_blank",
                );
              }}
            >
              <OpenInNewIcon />
            </IconButton>
            {display_type === "main" && pinToFrontButton}
            {display_type === "compare" &&
              !searchParams.get("pinToFront") &&
              pinToFrontButton}
            {closeCompareBulletinButton}
          </div>
        </div>
      );
    }
  };

  const desktopBottomButtons = () => {
    const showBottomButtoms = hasPreviousAlert() || hasNextAlert();
    if (!showBottomButtoms) return null;
    return (
      <div className="desktop-bottom-buttons d-flex mb-3 w-90 justify-content-end">
        <div className="mr-3">
          <PreviousButton />
        </div>
        <div>
          <NextButton />
        </div>
      </div>
    );
  };

  // useEffects
  useEffect(() => {
    const isCreator =
      currentUser?.id?.toString() === alertCreatorId?.toString();
    setIsCreator(isCreator);
  }, [currentUser?.id, alertCreatorId]);

  // on navigation to a new alert
  useEffect(() => {
    setIsResolveOpen(false);
    if (Object.keys(currentItem).length > 0) {
      const alertProperties = updateToContextStructure(currentItem);
      setAlertProperties(alertProperties);
    }
  }, [currentItem]);

  useEffect(() => {
    if (idToUse) getAlertById(idToUse);
  }, [idToUse]);

  if (idToUse === 0) return null;
  if (display_type === "compare") {
    const bulletinId = searchParams.get("bulletinId");
    if (bulletinId === idToUse.toString()) {
      return;
    }
  }

  if (!isLoaded)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  return (
    <>
      <div
        className="alert-container h-100 justify-content-between"
        ref={containerRef}
      >
        <div className="w-100">
          {desktopButtons()}
          <div
            style={
              display_type === "compare"
                ? {
                    height: "max-content",
                  }
                : {}
            }
          >
            {isResolveOpen && resolveCard()}
            <DisplayAlert
              mode="add"
              issuing_police_service={currentItem.issuing_police_service?.name}
              type={currentItem.type}
              associatedCrimes={currentItem.associated_crime}
              occurrenceNumbers={currentItem.occurrence_number}
              issuedTime={currentItem.issued_time}
              incidentTime={currentItem.incident_time}
              description={currentItem.description}
              pdf={currentItem.pdf}
              evidences={currentItem.evidence_files}
            />
          </div>
        </div>
        {display_type === "main" && desktopBottomButtons()}
      </div>
      {isShareModalOpen && shareModal()}
    </>
  );
};
