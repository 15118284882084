import { DisplayAlertWrapper } from "./DisplayAlertWrapper";
import "../../alerts.scss";
import { ALERT_STATUS } from "../../constants";
import { SingleAlertProvider } from "../../context";

interface DisplayAlertDrawerProps {
  mode: ALERT_STATUS;
  queryNextPage?: () => void;
}

export const DisplayAlertDrawer = ({
  mode,
  queryNextPage = () => {},
}: DisplayAlertDrawerProps) => {
  return (
    <div
      className="w-100 d-flex flex-row justify-content-around h-100"
      style={{
        alignItems: "flex-start",
      }}
    >
      <SingleAlertProvider>
        <DisplayAlertWrapper display_type={"compare"} mode={mode} />
      </SingleAlertProvider>
      <SingleAlertProvider>
        <DisplayAlertWrapper
          display_type={"main"}
          mode={mode}
          queryNextPage={queryNextPage}
        />
      </SingleAlertProvider>
    </div>
  );
};
