import { toast } from "react-toastify";

import { SurveyConfiguration } from "../../../common/api-client/or-api";

export const generateTextMessageBody = (
  reportName: string,
  callerFirstName: string,
  callerLastName: string,
  servicesURLSlug: string,
  selectedReport: SurveyConfiguration,
  fullName: string,
  isDevEnv: boolean,
) => {
  const buildBaseURL = () => {
    const urlSlug = servicesURLSlug;
    if (!urlSlug) {
      toast.error(
        "❌ The organization does not have a valid URL slug, please contact support to update the configuration for this organization.",
      );
      return;
    }

    if (isDevEnv) {
      return `https://test.services.tryrubicon.com/${urlSlug}/online-reports/report/`;
    } else {
      return `https://www.services.tryrubicon.com/${urlSlug}/online-reports/report/`;
    }
  };
  const reportNameIncludesReport = reportName.toLowerCase().includes("report");
  const reportNameIncludesProgram = reportName
    .toLowerCase()
    .includes("program");
  const reportNameIncludesForm = reportName.toLowerCase().includes("form");

  return `Hi${callerFirstName ? ` ${callerFirstName}` : ""}${
    callerLastName ? ` ${callerLastName}` : ""
  },\n\nPlease use the following link to submit a ${
    reportNameIncludesReport
      ? "report"
      : reportNameIncludesProgram
        ? "program"
        : reportNameIncludesForm
          ? "form"
          : "report"
  }: \n\n${buildBaseURL()}${selectedReport?.survey_class}\n\n${fullName}`;
};
