import { LockOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Avatar,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import styles from "./Home.module.css";
import { ssoConfigurations } from "../common/serviceConfigurations";
import { validateEmail } from "../common/utils";
import { AuthStateContext } from "../context";

export const HomeLogin = (props) => {
  const [error, setError] = useState("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [organization, setOrganization] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showSSOWarning, setShowSSOWarning] = useState(false);
  const [searchParams] = useSearchParams();
  const invalidInvite = searchParams.get("invalidInvite");

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const navigate = useNavigate();

  const { currentUser, login, checkUserAuthStatus } =
    useContext(AuthStateContext);

  useEffect(() => {
    checkUserAuthStatus(false);
  }, []);

  useEffect(() => {
    if (currentUser) {
      navigate("/");
    }
  }, [currentUser]);

  useEffect(() => {
    if (invalidInvite) {
      toast.error(
        "Your invite link is invalid or has expired. Please click Forgot Password and follow the flow to gain access to your account.",
      );
    }
  }, [invalidInvite]);

  const handleSubmit = () => {
    setLoading(true);

    setError("");
    if (!email || !validateEmail(email)) {
      setError("Please enter a proper email");
      setLoading(false);
      return;
    }

    if (!password) {
      setError("Please enter a password");
      setLoading(false);
      return;
    }

    login(email, password).catch(() => {
      setLoading(false);
      toast.error(
        "Not authorized. You entered the wrong password or your session is expired. Try logging in again. If you need support contact support@tryrubicon.com.",
        { autoClose: 7000 },
      );
    });
  };

  const ssoOnSelect = (event) => {
    const serviceName = event.target.value;
    setOrganization(serviceName);
    setShowSSOWarning(false);
    const service = ssoConfigurations.find(
      (config) => config.fullName === serviceName,
    );

    if (service && service.ssoSlug) {
      navigate(`/${service.ssoSlug}/login`);
    } else {
      setShowSSOWarning(true);
    }
  };

  return (
    <div className={styles.paper}>
      <Avatar className={styles.avatar}>
        <LockOutlined />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <div className={styles.ssoContainer}>
        <p>Select your organization to use Enterprise SSO</p>
        <Select
          value={organization}
          className={styles.ssoSelect}
          onChange={ssoOnSelect}
        >
          {ssoConfigurations.map((config) => (
            <MenuItem key={config.fullName} value={config.fullName}>
              {config.fullName}
            </MenuItem>
          ))}
        </Select>
        {showSSOWarning ? (
          <p>
            <b>Your organization is not set up for Single Sign-On.</b>
          </p>
        ) : (
          <p> - or - </p>
        )}
        <p>Sign in with your email</p>
      </div>
      <form
        className={styles.form}
        noValidate
        onSubmit={(event) => {
          event.preventDefault(); // Prevent page reload
          handleSubmit(); // Call your submit function
        }}
      >
        <TextField
          required
          value={email}
          autoFocus
          type="email"
          variant="outlined"
          id="email"
          label="Email"
          fullWidth
          disabled={loading}
          onChange={(event) => {
            if (event.target.value !== null) setEmail(event.target.value);
          }}
        />

        <TextField
          required
          value={password}
          type={showPassword ? "text" : "password"}
          variant="outlined"
          id="password"
          label="Password"
          autoComplete="current-password"
          fullWidth
          disabled={loading}
          onChange={(event) => {
            if (event.target.value !== null)
              setPassword(event.target.value.replace(/\s/g, ""));
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Button
          name="Submit"
          type="submit" // Set button type to submit
          variant="contained"
          color="primary"
          size="large"
          disabled={loading}
          fullWidth
          onClick={() => {
            handleSubmit();
          }}
        >
          {loading && <CircularProgress size={25} thickness={2} />}
          {"Sign In"}
        </Button>

        {error && <h3 className={styles.errorMessage}>{error}</h3>}
        <Grid container>
          <Grid item xs>
            <Link
              className={styles.signAction}
              variant="body2"
              onClick={() => {
                props.onForgotPassword();
              }}
            >
              Forgot password?
            </Link>
          </Grid>
          <Grid item>
            <Link
              className={styles.signAction}
              variant="body2"
              onClick={() => {
                props.onSignup();
              }}
            >
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
