import { Camera } from "@mui/icons-material";
import { APIProvider } from "@vis.gl/react-google-maps";
import React from "react";
import ReactDOMServer from "react-dom/server";
import { ElementFactory, Question, Serializer, SvgRegistry } from "survey-core";
import {
  localization,
  PropertyGridEditorCollection,
} from "survey-creator-core";
import { FlatQuestion, FlatRepository, PdfBrick } from "survey-pdf";
import {
  ReactQuestionFactory,
  SurveyQuestionElementBase,
} from "survey-react-ui";

import CustomMapElement from "./CameraRegistryMapComponent";

export const CAMERA_REGISTRY_MAP_TYPE = "camera-registry-map";
const GOOGLE_PLACES_KEY = import.meta.env.VITE_GOOGLE_PLACES_KEY;

export class CameraRegistryModel extends Question {
  constructor(name: string) {
    super(name);
  }

  onValueChanged() {
    super.onValueChanged();
  }

  getType() {
    return CAMERA_REGISTRY_MAP_TYPE;
  }

  getCoverageAreaCheck() {
    return this.getPropertyValue("coverageAreaCheck");
  }

  setCoverageAreaCheck(val: boolean) {
    this.setPropertyValue("coverageAreaCheck", val);
  }
}

export function registerCameraRegistryMap() {
  ElementFactory.Instance.registerElement(CAMERA_REGISTRY_MAP_TYPE, (name) => {
    return new CameraRegistryModel(name);
  });
}

// Specify display names for the question type and its properties
const locale = localization.getLocale("");
locale.qt[CAMERA_REGISTRY_MAP_TYPE] = "Camera Registry Map";
locale.pe.colorPickerType = "Camera Registry Map type";

// Register an SVG icon for the question type
const svg = ReactDOMServer.renderToString(<Camera />);
SvgRegistry.registerIconFromSvg(CAMERA_REGISTRY_MAP_TYPE, svg);

Serializer.addClass(
  CAMERA_REGISTRY_MAP_TYPE,
  [
    {
      name: "cameraLocations",
      default: [],
    },
    {
      name: "googleMapsPlace",
      default: {},
    },
    {
      name: "coverageAreaCheck",
      default: false,
      choices: [false, true],
      category: "general",
      visibleIndex: 2,
    },
  ],
  function () {
    return new CameraRegistryModel("");
  },
  "question",
);

export class CameraRegistryMap extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);
    if (!this.question.value) {
      this.question.value = {
        googleMapsPlace: {},
        cameraLocations: [],
      };
    }

    this.state = { value: this.question.value };
  }

  get question(): CameraRegistryModel {
    return this.questionBase as CameraRegistryModel;
  }

  setFieldValue = (field: string, data: any) => {
    this.question.value = { ...this.question.value, [field]: data };
  };

  renderElement(): JSX.Element {
    return (
      <APIProvider apiKey={GOOGLE_PLACES_KEY}>
        <CustomMapElement
          cameraLocations={this.question.value.cameraLocations}
          setCameraLocations={(val) => {
            if (val) {
              this.setFieldValue("cameraLocations", val);
            }
          }}
          survey={this.question.survey}
          address={this.question.value.googleMapsPlace}
          setAddress={(val) => {
            this.setFieldValue("googleMapsPlace", val);
          }}
          disabled={this.props.isDisplayMode}
        />
      </APIProvider>
    );
  }
}

ReactQuestionFactory.Instance.registerQuestion(
  CAMERA_REGISTRY_MAP_TYPE,
  (props) => {
    return React.createElement(CameraRegistryMap, props);
  },
);

PropertyGridEditorCollection.register({
  fit: function (prop) {
    return prop.type === "color";
  },
  getJSON: function () {
    return {
      type: CAMERA_REGISTRY_MAP_TYPE,
      cameraLocations: [],
      googleMapsPlace: {},
      coverageAreaCheck: false,
    };
  },
});

class CustomPdfBrick extends PdfBrick {
  async renderInteractive() {}
}

class FlatCustomColor extends FlatQuestion {
  async generateFlatsContent(point: any) {
    const rect = {
      xLeft: point.xLeft,
      yTop: point.yTop + 10,
      xRight: point.xLeft + 20,
      yBot: point.yTop + 20,
    };
    return [new CustomPdfBrick(this.question, this.controller, rect)];
  }
}

FlatRepository.getInstance().register(
  CAMERA_REGISTRY_MAP_TYPE,
  FlatCustomColor,
);
