import AddIcon from "@mui/icons-material/Add";
import { Grid, Button } from "@mui/material";
import { useSessionStorage } from "@react-hooks-library/core";
import { APIProvider } from "@vis.gl/react-google-maps";
import React, { useRef, useState, useEffect, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { toast } from "react-toastify";

import RegistryCard from "./RegistryCard";
import RegistryDetailsPanel from "./RegistryDetailsPanel";
import RegistryListView from "./RegistryListView";
import RegistryMapView from "./RegistryMapView";
import { useRegistry } from "./context/RegistryContext";
import { useDevEnvironment } from "../../DevContext";
import { SubmissionSummary } from "../../common/api-client/or-api";
import { APP_GOOGLE_PLACES_KEY } from "../../environment";
import SearchBar from "../alerts/Search/SearchBar";
import ReportFilters, {
  IReportListFilterOptions,
  RenderReportFilterChips,
} from "../online-reporting/Search/ReportFilters";
import { OnlineReportingContext } from "../online-reporting/context";

const RegistryEntries = () => {
  const { orgId, registryName } = useParams();
  const { registryForms } = useRegistry();
  const [registryTitle, setRegistryTitle] = useState("");
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [loadingEntries, setLoadingEntries] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const { isDevEnv } = useDevEnvironment();
  const { fetchLatestReports, reports, searchText, setSearchText } = useContext(
    OnlineReportingContext,
  );
  const [registrySubmissions, setRegistrySubmissions] = useState<
    SubmissionSummary[]
  >([]);
  const [view, setView] = useSessionStorage("registryView", "list");

  // Simplified filters for registry entries
  const [filters, setFilters] = useSessionStorage<IReportListFilterOptions>(
    "registryFilters",
    {
      creationStartDate: null,
      creationEndDate: null,
      updateStartDate: null,
      updateEndDate: null,
      reportStatus: null,
      trackingNumber: null,
    },
  );

  const searchBarRef = useRef<HTMLInputElement | null>(null);

  const updateSearch = (updatedFilters: IReportListFilterOptions) => {
    setFilters(updatedFilters);
  };

  useEffect(() => {
    if (registryForms) {
      const registry = registryForms.find(
        (survey) => survey.survey_class === registryName,
      );
      if (registry) {
        setRegistryTitle(registry.name);
      }
    }
  }, [registryForms, registryName]);

  useEffect(() => {
    async function fetchData() {
      setLoadingEntries(true);
      try {
        await fetchLatestReports!(
          orgId ?? "",
          filters.creationStartDate ?? undefined,
          filters.creationEndDate ?? undefined,
          filters.updateStartDate ?? undefined,
          filters.updateEndDate ?? undefined,
          filters.reportStatus
            ? decodeURIComponent(filters.reportStatus)
            : undefined,
          filters.trackingNumber ?? undefined,
          undefined,
          0,
          registryTitle,
          searchText,
        );
        setLoadingEntries(false);
      } catch (e: any) {
        setLoadingEntries(false);
        const message = e.message;
        toast.error(
          `Failed to fetch registry entries${isDevEnv ? `: ${message}` : ""}`,
        );
      }
    }

    if (registryTitle) {
      fetchData();
      setInitialized(true);
    }
  }, [filters, searchText, registryTitle]);

  useEffect(() => {
    setRegistrySubmissions(
      reports.filter((report) => report.survey_class === registryName),
    );
  }, [reports]);

  const renderFilters = () => {
    return (
      <ReportFilters
        isOpen={filtersVisible}
        anchorEl={searchBarRef.current}
        onClose={() => setFiltersVisible(false)}
        filters={filters}
        setFilters={updateSearch}
      />
    );
  };

  const [selectedEntry, setSelectedEntry] = useState<
    SubmissionSummary | undefined
  >();
  const [detailsPanelOpen, setDetailsPanelOpen] = useState(false);
  const [selectedSubmissionId, setSelectedSubmissionId] = useState<
    string | undefined
  >();

  const handleEntryClick = (entry: SubmissionSummary) => {
    setSelectedEntry(entry);
    setSelectedSubmissionId(entry.id);
    setDetailsPanelOpen(true);
  };

  return (
    <div style={{ padding: "0 16px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1>{registryTitle || `${registryName} Entries`}</h1>
        <Button
          component={Link}
          to={`/rubiregistry/${orgId}/${registryName}/new`}
          variant="contained"
          startIcon={<AddIcon />}
        >
          New
        </Button>
      </div>
      <SearchBar
        getMoreItems={() => {}}
        onSearch={() => {}}
        onClearSearch={() => {}}
        searchView={view}
        setSearchView={setView}
        defaultView="list"
        searchText={searchText}
        setSearchText={setSearchText}
        currentItems={reports}
        setCurrentItems={() => {}}
        filtersVisible={filtersVisible}
        setFiltersVisible={setFiltersVisible}
        totalAlerts={reports.length}
        loading={loadingEntries}
        setLoading={setLoadingEntries}
        searchType="report"
        filtersRef={searchBarRef}
        filterChips={
          <RenderReportFilterChips
            filters={filters}
            setFilters={updateSearch}
          />
        }
        activeViews={["list", "card", "map"]}
      />
      {filtersVisible && renderFilters()}
      {view === "list" && (
        <div
          style={{
            height: "calc(100vh - 180px)",
            width: "100%",
            padding: "16px",
          }}
        >
          <RegistryListView
            reports={registrySubmissions}
            loadingEntries={loadingEntries}
          />
        </div>
      )}
      {view === "card" && (
        <div
          style={{
            height: "calc(100vh - 180px)",
            width: "100%",
            padding: "16px",
            overflowY: "auto",
          }}
        >
          <Grid container spacing={2}>
            {registrySubmissions.map((report, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                key={report.id || index}
                sx={{
                  display: "flex",
                  "& > *": {
                    // This targets the RegistryCard component
                    flex: 1,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    "& .MuiCardContent-root": {
                      // Target the card content
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end", // Push content to bottom
                    },
                  },
                }}
              >
                <RegistryCard
                  index={index}
                  registryItem={report}
                  onCardClick={handleEntryClick}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      )}
      {view === "map" && (
        <div
          style={{
            height: "calc(100vh - 180px)",
            width: "100%",
            padding: "16px",
          }}
        >
          <APIProvider apiKey={APP_GOOGLE_PLACES_KEY}>
            <RegistryMapView
              reports={registrySubmissions}
              loadingEntries={loadingEntries}
              onPinClick={handleEntryClick}
              selectedReportId={selectedSubmissionId}
            />
          </APIProvider>
        </div>
      )}
      <RegistryDetailsPanel
        open={detailsPanelOpen}
        onClose={() => {
          setDetailsPanelOpen(false);
          setSelectedSubmissionId(undefined);
        }}
        registryItem={selectedEntry}
      />
    </div>
  );
};

export default RegistryEntries;
