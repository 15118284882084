import { Permission } from "../components/UserManagement/types";

export interface IFieldEvent {
  eventName: string;
  fieldToUpdate: string;
  newValue: string;
}

export interface IFieldConfiguration {
  formatRegex?: string;
  events?: IFieldEvent[];
}

export interface IPoliceServiceConfiguration {
  id: string;
  testID: string;
  operationalArea?: string[];
  fieldConfigurations?: { [key: string]: IFieldConfiguration };
  shortName?: string;
  fullName?: string;
  region: string;
  mapCenter?: { lat: number; lng: number };
  apps: Permission[];
  logoURL?: string;
  generateAttachmentDescriptions?: boolean;
  servicesURLSlug?: string;
}

export interface ISSOConfiguration {
  fullName: string;
  logoURL?: string;
  ssoSlug?: string;
  ssoProvider?: string;
}

export const ssoConfigurations: ISSOConfiguration[] = [
  {
    fullName: "Anishinabek Police Service",
    logoURL: "/service-logos/anishinabek_police_service_logo.png",
    ssoSlug: "anishinabek-ps",
    ssoProvider: "Microsoft",
  },
  {
    fullName: "Barrie Police Service",
    logoURL: "/service-logos/Barrie-Police-Service-Logo.png",
    ssoSlug: "barrie-ps",
    ssoProvider: "Microsoft",
  },
  {
    fullName: "Brantford Police Service",
  },
  {
    fullName: "Chatham-Kent Police Service",
    logoURL: "/service-logos/chatham-kent_police_service_logo.png",
    ssoSlug: "ckps",
    ssoProvider: "Microsoft",
  },
  {
    fullName: "Cobourg Police Service",
  },
  {
    fullName: "Greater Sudbury Police Service",
    logoURL: "/service-logos/greater_sudbury_police_service_logo.png",
    ssoSlug: "gsps",
    ssoProvider: "Microsoft",
  },
  {
    fullName: "Kawartha Lakes Police Service",
    logoURL: "/service-logos/KLPS-Logo.png",
    ssoSlug: "kawartha-lakes-ps",
    ssoProvider: "Microsoft",
  },
  {
    fullName: "Niagara Regional Police Service",
  },
  {
    fullName: "Peel Regional Police",
    logoURL: "/service-logos/Peel_Regional_Police_Logo.png",
    ssoSlug: "peel-rp",
    ssoProvider: "Okta",
  },
  {
    fullName: "Timmins Police Service",
  },
  // {
  //   fullName: "DM Org",
  //   ssoSlug: "dm",
  // }
];

export const serviceConfigurations: {
  [key: string]: IPoliceServiceConfiguration;
} = {
  // "DM Org": {
  //   id: "05f95617-2631-47db-8dc8-a272a7076abc",
  //   testID: "05f95617-2631-47db-8dc8-a272a7076abc",
  //   shortName: "DM",
  //   fullName: "DM Org",
  //   region: "Cobourg",
  //   mapCenter: { lat: 43.9593, lng: -78.1657 },
  //   apps: [Permission.REPORT_V2],
  // },
  "Cobourg Police Service": {
    id: "2c9159e9-080c-4c54-81d1-700d5c7ef368",
    testID: "c2612d47-2d95-4362-bff0-2d5e695daed7",
    shortName: "CPS",
    fullName: "Cobourg Police Service",
    region: "Cobourg",
    mapCenter: { lat: 43.9593, lng: -78.1657 },
    apps: [Permission.REPORT, Permission.CAMERA_REGISTRY, Permission.ALERT],
    servicesURLSlug: "cobourg-police-service",
  },
  "Timmins Police Service": {
    id: "2c9159e9-080c-4c54-81d1-700d5c7ef399",
    testID: "2c9159e9-080c-4c54-81d1-700d5c7ef399",
    shortName: "TPS",
    fullName: "Timmins Police Service",
    region: "Timmins",
    logoURL: "/service-logos/Timmins-Police-Service-Logo.png",
    mapCenter: { lat: 48.476673, lng: -81.330016 },
    apps: [Permission.REPORT_V2],
    servicesURLSlug: "timmins-police-service",
  },
  "Barrie Police Service": {
    id: "e51f2900-e625-4fe5-907d-7be349974045",
    testID: "b0d585d5-abf0-42a5-8817-2e8e26630ef3",
    shortName: "BPS",
    fullName: "Barrie Police Service",
    region: "Barrie",
    logoURL: "/service-logos/Barrie-Police-Service-Logo.png",
    mapCenter: { lat: 44.386812, lng: -79.692489 },
    apps: [Permission.REPORT_V2],
    servicesURLSlug: "barrie-police-service",
  },
  "Kawartha Lakes Police Service": {
    id: "64d88277-48b6-4855-b631-f688c568341d",
    testID: "64d88277-48b6-4855-b631-f688c568341d",
    shortName: "KLPS",
    fullName: "Kawartha Lakes Police Service",
    region: "Kawartha Lakes",
    logoURL: "/service-logos/KLPS-Logo.png",
    mapCenter: { lat: 44.356204, lng: -78.740789 },
    apps: [Permission.REPORT_V2],
    servicesURLSlug: "kawartha-lakes-police-service",
  },
  "Chatham-Kent Police Service": {
    id: "2b12201d-ac51-490f-913e-a5e1c7a44b82",
    testID: "2b12201d-ac51-490f-913e-a5e1c7a44b82",
    shortName: "CKPS",
    fullName: "Chatham-Kent Police Service",
    region: "Chatham-Kent",
    logoURL: "/service-logos/chatham-kent_police_service_logo.png",
    mapCenter: { lat: 42.405545, lng: -82.189201 },
    apps: [Permission.REPORT_V2],
    servicesURLSlug: "chatham-kent-police-service",
  },
  "Anishinabek Police Service": {
    id: "0cc0f8e7-016b-4f0e-9f7a-4453bd7d4e82",
    testID: "0cc0f8e7-016b-4f0e-9f7a-4453bd7d4e82",
    shortName: "APS",
    fullName: "Anishinabek Police Service",
    region: "Anishinabek",
    logoURL: "/service-logos/anishinabek_police_service_logo.png",
    mapCenter: { lat: 46.538532, lng: -84.152278 },
    apps: [Permission.REPORT_V2],
    servicesURLSlug: "anishinabek-police-service",
  },
  "Gotham Police Department": {
    id: "dd7931ca-b0a1-4acf-972c-9982ad1f04a5",
    testID: "dd7931ca-b0a1-4acf-972c-9982ad1f04a5",
    shortName: "GPD",
    fullName: "Gotham Police Department",
    region: "Gotham",
    logoURL: "/service-logos/gotham_pd.png",
    mapCenter: { lat: 42.346873, lng: -71.046304 },
    apps: [Permission.REPORT_V2],
    servicesURLSlug: "gotham-police-department",
  },
  "Greater Sudbury Police Service": {
    id: "54a6982e-4800-468a-87fc-fd3074161fc0",
    testID: "54a6982e-4800-468a-87fc-fd3074161fc0",
    shortName: "GSPS",
    fullName: "Greater Sudbury Police Service",
    region: "Sudbury",
    logoURL: "/service-logos/greater_sudbury_police_service_logo.png",
    mapCenter: { lat: 46.49295, lng: -80.99302 },
    apps: [Permission.ALERT],
    servicesURLSlug: "greater-sudbury-police-service",
  },
  "Criminal Intelligence Service of Ontario": {
    id: "72568a9a-3d58-4c13-9cb2-604118ce7303",
    testID: "4b556c8c-e5fd-4e1d-93fc-81d2e5f5bea4",
    shortName: "CISO",
    fullName: "Criminal Intelligence Service Ontario",
    region: "Ontario",
    logoURL: "/service-logos/CISO-Logo_bw.png",
    mapCenter: { lat: 44.386812, lng: -79.692489 },
    apps: [Permission.ALERT],
    generateAttachmentDescriptions: true,
    servicesURLSlug: "4b556c8c-e5fd-4e1d-93fc-81d2e5f5bea4",
  },
  "Ontario Provincial Police": {
    id: "c9189a82-f262-4d55-b88e-2af39efcdbbe",
    testID: "c9189a82-f262-4d55-b88e-2af39efcdbbe",
    shortName: "OPP",
    fullName: "Ontario Provincial Police",
    region: "Ontario",
    logoURL: "/service-logos/OPP.png",
    mapCenter: { lat: 44.386812, lng: -79.692489 },
    apps: [Permission.ALERT],
    generateAttachmentDescriptions: true,
  },
  "Peel Regional Police": {
    id: "d718b876-5b95-49d3-93a7-c953233cd73d",
    testID: "1737dc6c-29a7-4a23-9823-fdf50b9730d2",
    operationalArea: [
      "11 Division",
      "12 Division",
      "21 Division",
      "22 Division",
      "Airport Division",
      "Central Robbery",
      "Special Victims",
    ],
    fieldConfigurations: {
      "contact.badge_number": {
        formatRegex: "[1-9][0-9]*(c)?$",
        events: [
          {
            eventName: "onChange",
            fieldToUpdate: "email",
            newValue: "#{badge_number}@peelpolice.ca",
          },
        ],
      },
    },
    shortName: "PRP",
    fullName: "Peel Regional Police",
    region: "Peel",
    apps: [Permission.ALERT],
  },
  "Durham Regional Police Service": {
    id: "",
    testID: "",
    shortName: "DRPS",
    fullName: "Durham Regional Police Service",
    region: "Durham",
    apps: [],
  },
  "Toronto Police Service": {
    id: "",
    testID: "",
    shortName: "TPS",
    fullName: "Toronto Police Service",
    region: "Toronto",
    apps: [],
  },
  "Brantford Police Service": {
    id: "acd67f6b-b3f9-4f3e-9460-3e64a45d2f5e",
    testID: "acd67f6b-b3f9-4f3e-9460-3e64a45d2f5e",
    shortName: "BPS",
    fullName: "Brantford Police Service",
    region: "Brantford",
    apps: [Permission.ALERT],
  },
  "Niagara Regional Police Service": {
    id: "b15fbe60-d1a1-4700-a03f-5a6773b0494f",
    testID: "b15fbe60-d1a1-4700-a03f-5a6773b0494f",
    shortName: "NRPS",
    fullName: "Niagara Regional Police Service",
    region: "Niagara",
    apps: [Permission.ALERT],
  },
  "OPP - Ontario Provincial Police": {
    id: "",
    testID: "",
    shortName: "OPP",
    fullName: "Ontario Provincial Police",
    region: "OPP",
    logoURL: "/service-logos/opp_logo.png",
    apps: [],
  },
  "Your Police Service": {
    id: "1",
    testID: "",
    shortName: "YPS",
    fullName: "Your Police Service",
    region: "Your Region",
    logoURL: "/service-logos/Timmins-Police-Service-Logo.png",
    operationalArea: [
      "11 Division",
      "12 Division",
      "21 Division",
      "22 Division",
      "Airport Division",
      "Central Robbery",
    ],
    fieldConfigurations: {
      "contact.badge_number": {
        formatRegex: "[1-9][0-9]*(c)?$",
        events: [
          {
            eventName: "onChange",
            fieldToUpdate: "email",
            newValue: "#{badge_number}@peelpolice.ca",
          },
        ],
      },
    },
    apps: [
      Permission.REPORT,
      Permission.CAMERA_REGISTRY,
      Permission.ALERT,
      Permission.REPORT_V2,
    ],
  },
  Rubicon: {
    id: "947cabf8-2f8c-4628-a0df-1440be75908e",
    testID: "05f95617-2631-47db-8dc8-a272a7076756",
    shortName: "YPS",
    fullName: "Rubicon",
    region: "Your Region",
    logoURL: "/service-logos/Timmins-Police-Service-Logo.png",
    operationalArea: [
      "11 Division",
      "12 Division",
      "21 Division",
      "22 Division",
      "Airport Division",
      "Central Robbery",
    ],
    fieldConfigurations: {
      "contact.badge_number": {
        formatRegex: "[1-9][0-9]*(c)?$",
        events: [
          {
            eventName: "onChange",
            fieldToUpdate: "email",
            newValue: "#{badge_number}@peelpolice.ca",
          },
        ],
      },
    },
    apps: [
      Permission.REPORT,
      Permission.CAMERA_REGISTRY,
      Permission.ALERT,
      Permission.REPORT_V2,
      Permission.INTEL,
    ],
  },
};

export function getServiceConfig(id: string, testMode: boolean = false) {
  return (
    Object.values(serviceConfigurations).filter(
      (service) => (testMode ? service.testID : service.id) === id,
    )[0] ?? {}
  );
}

export const substituteFormatValue = (
  formatString: string,
  data: { [key: string]: string },
): string => {
  let result = formatString;
  for (const key in data) {
    result = result.replace(`#{${key}}`, data[key]);
  }
  return result;
};
