import { CheckedUserPermissions, Permission } from "./types";
import { IUserPermission } from "../../types";

export const userPermissionChecks = (
  currentUserPermissions: Permission[],
): CheckedUserPermissions => {
  const {
    RUBI_ORG_OWN_MODIFY_USER_APPS,
    RUBI_ORG_ANY_MODIFY_USER_APPS,
    RUBI_ORG_OWN_MANAGE_ADMINS,
    RUBI_ORG_ANY_MANAGE_ADMINS,
    RUBI_USER_ANY_EDIT_FIELDS,
    RUBI_USER_ORG_EDIT_FIELDS,
    RUBI_ORG_ANY_ADD_USER,
    RUBI_ORG_OWN_ADD_USER,
    ALERT,
    REPORT,
    REPORT_V2,
    CAMERA_REGISTRY,
    LOGIN,
    // ALERT PERMISSIONS
    ALERT_CREATE_BULLETIN,
    ALERT_DASHBOARD,
    REGISTRY,
  } = Permission;

  return {
    canManageApps: currentUserPermissions.some((permission: Permission) =>
      [RUBI_ORG_OWN_MODIFY_USER_APPS, RUBI_ORG_ANY_MODIFY_USER_APPS].includes(
        permission,
      ),
    ),
    canManageAdmins: currentUserPermissions.some((permission) =>
      [RUBI_ORG_OWN_MANAGE_ADMINS, RUBI_ORG_ANY_MANAGE_ADMINS].includes(
        permission,
      ),
    ),
    canModifyUsers: currentUserPermissions.some((permission) =>
      [RUBI_USER_ANY_EDIT_FIELDS, RUBI_USER_ORG_EDIT_FIELDS].includes(
        permission,
      ),
    ),
    canCreateUsers: currentUserPermissions.some(
      (permission) =>
        permission === RUBI_ORG_ANY_ADD_USER ||
        permission === RUBI_ORG_OWN_ADD_USER,
    ),
    canAccessAlertsApp: currentUserPermissions.some(
      (permission) => permission === ALERT,
    ),
    canAccessReportsApp: currentUserPermissions.some(
      (permission) => permission === REPORT,
    ),
    canAccessReportsV2App: currentUserPermissions.some(
      (permission) => permission === REPORT_V2,
    ),
    canAccessCameraRegistryApp: currentUserPermissions.some(
      (permission) => permission === CAMERA_REGISTRY,
    ),
    canLogin: currentUserPermissions.some((permission) => permission === LOGIN),
    canCreateBulletin: currentUserPermissions.some(
      (permission) => permission === ALERT_CREATE_BULLETIN,
    ),
    canAccessDashboard: currentUserPermissions.some(
      (permission) => permission === ALERT_DASHBOARD,
    ),
    canAccessRegistryApp: currentUserPermissions.some(
      (permission) => permission === REGISTRY,
    ),
  };
};

export const parseUserPermissions = (permissions: IUserPermission[]) => {
  const userPermissions = permissions.map((permission) => {
    return permission.title;
  });
  if (userPermissions) {
    return userPermissionChecks(userPermissions);
  } else {
    return {} as CheckedUserPermissions;
  }
};
