import { LinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListItemNode,
  ListNode,
} from "@lexical/list";
import {
  $convertFromMarkdownString,
  TRANSFORMERS,
  TextFormatTransformer,
} from "@lexical/markdown";
import {
  InitialConfigType,
  LexicalComposer,
} from "@lexical/react/LexicalComposer";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { EditorRefPlugin } from "@lexical/react/LexicalEditorRefPlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ErrorBoundaryType } from "@lexical/react/shared/useDecorators";
import {
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatUnderlined,
  Link,
} from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import {
  $getSelection,
  $isRangeSelection,
  EditorThemeClasses,
  FORMAT_TEXT_COMMAND,
  LineBreakNode,
  TextFormatType,
} from "lexical";
import { useEffect, useState } from "react";
import "./LexicalEditor.scss";

const UNDERLINE_MARKDOWN: TextFormatTransformer = {
  format: ["underline"],
  tag: "~",
  type: "text-format",
  intraword: true,
};

export const customTransformers = [...TRANSFORMERS, UNDERLINE_MARKDOWN];

const EditorToolbar = () => {
  const [editor] = useLexicalComposerContext();
  const [isLinkDialogOpen, setIsLinkDialogOpen] = useState(false);
  const [linkProperties, setLinkProperties] = useState<{
    url: string;
    attributes: { title: string; rel: string; target: string };
  }>({
    url: "",
    attributes: { title: "", rel: "", target: "" },
  });

  const LinkDialog = (
    <Dialog open={isLinkDialogOpen} onClose={() => setIsLinkDialogOpen(false)}>
      <DialogTitle>Add Link</DialogTitle>
      <DialogContent>
        <TextField
          label="URL"
          variant="outlined"
          fullWidth
          onChange={(event) => {
            setLinkProperties({
              ...linkProperties,
              url: event.currentTarget.value,
            });
          }}
        />
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button
          onClick={() => {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, {
              url: linkProperties.url,
              ...linkProperties.attributes,
            });
            setIsLinkDialogOpen(false);
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );

  const applyFormat = (command: TextFormatType) => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, command);
      }
    });
  };

  const insertBulletList = () => {
    editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
  };

  const insertNumberedList = () => {
    editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
  };

  return (
    <ButtonGroup variant="outlined" aria-label="text formatting">
      <IconButton onClick={() => applyFormat("bold")} aria-label="bold">
        <FormatBold />
      </IconButton>
      <IconButton onClick={() => applyFormat("italic")} aria-label="italic">
        <FormatItalic />
      </IconButton>
      <IconButton
        onClick={() =>
          applyFormat(UNDERLINE_MARKDOWN.format[0] as TextFormatType)
        }
        aria-label="underline"
      >
        <FormatUnderlined />
      </IconButton>
      <IconButton
        onClick={() => {
          setIsLinkDialogOpen(true);
        }}
        aria-label="link"
      >
        <Link />
      </IconButton>

      <IconButton onClick={insertBulletList} aria-label="bullet list">
        <FormatListBulleted />
      </IconButton>
      <IconButton onClick={insertNumberedList} aria-label="numbered list">
        <FormatListNumbered />
      </IconButton>

      <>{LinkDialog}</>
    </ButtonGroup>
  );
};

type CustomOnChangePluginProps = {
  readOnly: boolean;
  markdown: string;
};

const CustomOnChangePlugin = ({
  readOnly,
  markdown,
}: CustomOnChangePluginProps) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (readOnly) {
      editor.setEditable(false);
    } else {
      editor.setEditable(true);
    }
  }, [readOnly]);

  useEffect(() => {
    editor.update(() => {
      $convertFromMarkdownString(markdown, customTransformers, undefined, true);
    });
  }, [markdown]);

  return null;
};

const theme: EditorThemeClasses = {
  text: {
    bold: "editor-bold",
    italic: "editor-italic",
    underline: "editor-underline",
    strikethrough: "editor-strikethrough",
    code: "editor-code",
  },
  paragraph: "editor-paragraph",
  heading: {
    h1: "editor-heading-h1",
    h2: "editor-heading-h2",
    h3: "editor-heading-h3",
  },
  list: {
    ul: "editor-list-ul",
    ol: "editor-list-ol",
    listitem: "editor-list-item",
  },
  quote: "editor-quote",
  link: "editor-link",
};

const ErrorBoundary: ErrorBoundaryType = ({ children, onError }) => {
  return <div>{children}</div>;
};

const LexicalEditor = ({
  readOnly = false,
  markdown,
  aboveEditorChildren,
  belowEditorChildren,
  editorButtons,
  editorRef,
}: {
  markdown: string;
  readOnly?: boolean;
  aboveEditorChildren?: JSX.Element;
  belowEditorChildren?: JSX.Element;
  editorButtons?: JSX.Element[];
  editorRef?: React.RefObject<typeof LexicalEditor>;
}) => {
  const initialConfig: InitialConfigType = {
    namespace: "LexicalEditor",
    theme: theme,
    onError: (error: Error) => {
      console.error(error);
    },
    editable: !readOnly,
    nodes: [LinkNode, LineBreakNode, ListNode, ListItemNode],
  };

  return (
    <div>
      <LexicalComposer initialConfig={initialConfig}>
        {!readOnly && (
          <div className="editor-toolbar">
            <EditorToolbar />
          </div>
        )}
        {aboveEditorChildren && (
          <Typography variant="body2" color="textPrimary">
            {aboveEditorChildren}
          </Typography>
        )}
        <RichTextPlugin
          contentEditable={<ContentEditable className="editor-input" />}
          placeholder={<div className="editor-placeholder"></div>}
          ErrorBoundary={ErrorBoundary}
        />
        <HistoryPlugin />
        {!editorRef && (
          <CustomOnChangePlugin readOnly={readOnly} markdown={markdown} />
        )}
        {/* The reason why we're not able to use this built in plugin is because of the way that it re-renders the state. 
        It causes the cursor to jump to the end (and also trim spaces at the end) */}
        {/* <OnChangePlugin
          onChange={(editorState, editor) => {
            if (onChange) {
              editor.update(() => {
                const markdown = $convertToMarkdownString(
                  customTransformers,
                  undefined,
                  true,
                );
                onChange(markdown);
              });
            }
          }}
        /> */}
        {/* instead, we can use the editorRefPlugin to get the markdown string if we need to */}
        {editorRef && <EditorRefPlugin editorRef={editorRef} />}
        <LinkPlugin />
        <ListPlugin />
        {/* <CheckListPlugin /> */}
        {belowEditorChildren && (
          <Typography variant="body2" color="textSecondary">
            {belowEditorChildren}
          </Typography>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          {editorButtons
            ? editorButtons.map((button, index) => (
                <div key={index}>{button}</div>
              ))
            : null}
        </div>
        {}
      </LexicalComposer>
    </div>
  );
};

export default LexicalEditor;
