import { Box } from "@mui/material";

const TopFixedActionContainer = ({
  children,
}: {
  children: React.ReactNode[];
}) => {
  return (
    <div
      style={{
        position: "sticky",
        top: 0,
        zIndex: 10,
        backgroundColor: "#fff",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.30)",
        padding: "4px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#fff",
          borderRadius: "10px",
          gap: "4px",
          maxWidth: "95%",
          position: "relative",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        {children.map((child, index) => (
          <div key={`top-fixed-action-container-${index}`}>{child}</div>
        ))}
      </Box>
    </div>
  );
};

export default TopFixedActionContainer;
