import React from "react";
import { useParams } from "react-router-dom";

const RegistryDashboard = () => {
  const { orgId } = useParams();

  return (
    <div>
      <h1>Registry Dashboard</h1>
      <p>Organization ID: {orgId}</p>
      {/* Dashboard content will go here */}
    </div>
  );
};

export default RegistryDashboard;
