import EditIcon from "@mui/icons-material/Edit";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";

import { AssignedTo } from "../../../common/api-client/or-api";
import { RubiUserModel } from "../../../common/api-client/or-api/models/RubiUserModel";
import { IUser } from "../../../types/typesAlerts";

const AssignedToSelector = ({
  currentAssignee,
  onAssign,
  userOptions,
  currentUser,
  disableEdit,
}: {
  currentAssignee: AssignedTo | null;
  onAssign: (user_id: string | null) => void;
  userOptions: RubiUserModel[];
  currentUser: IUser | null;
  disableEdit?: boolean;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedUser, setSelectedUser] = useState<AssignedTo | null>(
    currentAssignee ? currentAssignee : null,
  );

  const handleChange = (event, newValue) => {
    setSelectedUser(newValue);
    onAssign(newValue?.id ?? null);

    if (!newValue) {
      setIsEditing(false);
    }
  };

  const handleAssignToMe = () => {
    setSelectedUser(currentUser ? currentUser : null);
    onAssign(currentUser?.id ?? null);
    setIsEditing(false);
  };

  if (disableEdit && !currentAssignee) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {!isEditing && currentAssignee ? (
        <div>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              backgroundColor: "#f5f5f5",
              padding: "4px 8px",
              borderRadius: "16px",
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: "500" }}>
              {currentAssignee?.first_name} {currentAssignee?.last_name}
            </Typography>
            {!disableEdit && (
              <Tooltip title="Change Assignee">
                <IconButton
                  size="small"
                  onClick={() => setIsEditing(true)}
                  data-testid="change-assignee-button"
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </div>
      ) : (
        <div>
          <Autocomplete
            options={userOptions}
            getOptionLabel={(option) =>
              `${option.first_name} ${option.last_name}`
            }
            value={selectedUser}
            renderOption={(props, option, { inputValue }) => {
              const matchIndex = `${option.first_name} ${option.last_name}`
                .toLowerCase()
                .indexOf(inputValue.toLowerCase());

              let label;
              if (matchIndex >= 0) {
                const beforeMatch =
                  `${option.first_name} ${option.last_name}`.slice(
                    0,
                    matchIndex,
                  );
                const matchText =
                  `${option.first_name} ${option.last_name}`.slice(
                    matchIndex,
                    matchIndex + inputValue.length,
                  );
                const afterMatch =
                  `${option.first_name} ${option.last_name}`.slice(
                    matchIndex + inputValue.length,
                  );
                label = (
                  <>
                    {beforeMatch}
                    <b>{matchText}</b>
                    {afterMatch}
                  </>
                );
              } else {
                label = `${option.first_name} ${option.last_name}`;
              }

              return (
                <li {...props} key={option.id}>
                  {label}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Assign to a user..."
                variant="outlined"
                size="small"
                sx={{
                  width: 250,
                  fontSize: "12px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "16px",
                    fontSize: "12px",
                  },
                }}
              />
            )}
            onChange={handleChange}
            onBlur={() => setIsEditing(false)}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "-4px",
              marginRight: "8px",
            }}
          >
            <Button
              variant="text"
              onClick={handleAssignToMe}
              sx={{
                fontSize: "10px",
                padding: "0",
                margin: "0",
                textTransform: "none",
              }}
            >
              Assign to myself
            </Button>
          </div>
        </div>
      )}
    </Box>
  );
};

export default AssignedToSelector;
