import MyLocationIcon from "@mui/icons-material/MyLocation";
import { Box, CircularProgress, debounce, IconButton } from "@mui/material";
import {
  AdvancedMarker,
  Map,
  MapControl,
  Marker,
  Pin,
  useMap,
} from "@vis.gl/react-google-maps";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { BulletinResourceResponse } from "../../types";

const containerStyle = {
  width: "calc(100%)",
  height: "calc(100vh)",
  // marginLeft: "8px",
  // marginRight: "8px",
};

function AlertsMap({
  alerts,
  alertClickHandler,
  selectedAlertId,
  setSelectedAlertId,
  isLoading,
}: {
  alerts: BulletinResourceResponse[];
  alertClickHandler: (alert: BulletinResourceResponse, index: number) => void;
  selectedAlertId: number | null;
  setSelectedAlertId: (alertId: number) => void;
  isLoading: boolean;
}) {
  const googleMapId = "4380cd85df35e961";
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [usersLocation, setUsersLocation] =
    useState<google.maps.LatLngLiteral | null>(null);
  const selectedAlert = alerts.find((alert) => alert.id === selectedAlertId);
  const [searchParams, setSearchParams] = useSearchParams();

  const map = useMap();
  const [bounds, setBounds] = useState(map?.getBounds());

  const ne = bounds?.getNorthEast().toJSON();
  const sw = bounds?.getSouthWest().toJSON();

  const extendBounds = (
    bounds: google.maps.LatLngBounds,
    paddingFactor = 0.1,
  ) => {
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    const latPadding = (ne.lat() - sw.lat()) * paddingFactor;
    const lngPadding = (ne.lng() - sw.lng()) * paddingFactor;

    return new google.maps.LatLngBounds(
      { lat: sw.lat() - latPadding, lng: sw.lng() - lngPadding },
      { lat: ne.lat() + latPadding, lng: ne.lng() + lngPadding },
    );
  };

  const setBoundsInSearchParams = () => {
    if (bounds) {
      const extendedBounds = extendBounds(bounds);

      const ne = extendedBounds.getNorthEast().toJSON();
      const sw = extendedBounds.getSouthWest().toJSON();

      setSearchParams((searchParams) => {
        searchParams.set("bounds", `${sw.lat},${sw.lng},${ne.lat},${ne.lng}`);
        return searchParams;
      });
    }
  };

  const debouncedSetBoundsInSearchParams = useCallback(
    debounce(setBoundsInSearchParams, 500),
    [ne, sw],
  );

  const setMapCenterToFirstValidAlert = () => {
    const validCoordinates = alerts
      .map(
        (alert) =>
          alert.location?.addresses?.length &&
          alert.location.addresses[0]?.geometry,
      )
      .filter(
        (geometry) =>
          geometry &&
          typeof geometry.lat === "number" &&
          typeof geometry.lng === "number",
      ) as google.maps.LatLngLiteral[];

    if (validCoordinates.length > 0 && isMapLoaded) {
      const bounds = new google.maps.LatLngBounds();
      validCoordinates.forEach((geometry) => {
        bounds.extend(geometry);
      });
      map?.fitBounds(bounds, {
        top: 100,
        right: 100,
        bottom: 100,
        left: 100,
      });
    }
  };

  const adjustMapBoundsToAlert = (alert: BulletinResourceResponse) => {
    const validCoordinates = alert.location?.addresses?.filter(
      (address) =>
        address.geometry &&
        typeof address.geometry.lat === "number" &&
        typeof address.geometry.lng === "number",
    );

    const bounds = new google.maps.LatLngBounds();
    validCoordinates?.forEach((address) => bounds.extend(address.geometry));

    if (validCoordinates?.length > 1) {
      map?.fitBounds(bounds, {
        top: 100,
        right: 100,
        bottom: 100,
        left: 100,
      });
    } else {
      map?.setCenter(validCoordinates[0].geometry);
      if (map?.getZoom()) {
        const zoom = map.getZoom();
        if (zoom && zoom < 12) {
          map?.setZoom(12);
        }
      }
    }
  };

  useEffect(() => {
    if (isMapLoaded) {
      debouncedSetBoundsInSearchParams();
    }
    return () => debouncedSetBoundsInSearchParams.clear();
  }, [bounds, isMapLoaded]);

  useEffect(() => {
    if (alerts.length === 0) return;

    if (selectedAlertId === null) {
      setMapCenterToFirstValidAlert();
    } else {
      const selectedAlert = alerts.find(
        (alert) => alert.id === selectedAlertId,
      );
      if (selectedAlert && isMapLoaded) {
        adjustMapBoundsToAlert(selectedAlert);
      }
    }
  }, [selectedAlertId, isMapLoaded]);

  const getCurrentPosition = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setUsersLocation({ lat: latitude, lng: longitude });
        map?.setCenter({ lat: latitude, lng: longitude });
        if ((map?.getZoom() ?? 0) < 12) {
          map?.setZoom(12);
        }
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          toast.error("Location access denied.");
        } else if (error.code === error.POSITION_UNAVAILABLE) {
          toast.error("Location unavailable. Please try again.");
        } else if (error.code === error.TIMEOUT) {
          toast.error("Location request timed out.");
        } else {
          toast.error("An unknown error occurred while fetching location.");
        }
      },
    );
  };

  /**
   *  Function to get the user's current location and pan to it
   */
  const panToCurrentLocation = () => {
    if (navigator.permissions) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "granted") {
          getCurrentPosition();
        } else if (result.state === "denied") {
          toast.error(
            "Please enable location services in your browser and refresh the page to use this feature.",
          );
        } else if (result.state === "prompt") {
          getCurrentPosition();
        }
      });
    } else if (navigator.geolocation) {
      getCurrentPosition();
    }
  };

  const handleMarkerClick = (alert, index) => {
    setSelectedAlertId(alert.id.toString());
    alertClickHandler(alert, index);
    adjustMapBoundsToAlert(alert);
  };

  const getMarkers = (alerts: BulletinResourceResponse[]) =>
    alerts.flatMap(
      (alert, index) =>
        alert.location?.addresses?.map((address, addressIndex) => {
          const { lat, lng } = address?.geometry || {};
          if (typeof lat === "number" && typeof lng === "number") {
            return (
              <AdvancedMarker
                key={`${index}-${addressIndex}`}
                position={{ lat, lng }}
                onClick={() => {
                  handleMarkerClick(alert, index);
                }}
                clickable
                zIndex={
                  selectedAlert && selectedAlert.id === alert.id ? 1000 : 0
                }
              >
                <Pin
                  scale={selectedAlert?.id === alert?.id ? 1.5 : 1}
                  glyphColor={selectedAlert?.id === alert?.id ? "#ffffff" : ""}
                />
              </AdvancedMarker>
            );
          }
          return null;
        }) || [],
    );

  return (
    <div style={containerStyle}>
      <Map
        onTilesLoaded={() => {
          setIsMapLoaded(true);
          setBounds(map?.getBounds());
        }}
        mapId={googleMapId}
        onBoundsChanged={() => {
          setBounds(map?.getBounds());
        }}
        defaultZoom={12}
        // Backend limits to -90 to 90 for latitude and -180 to 180 for longitude
        minZoom={3}
        fullscreenControl={false}
      >
        {isLoading && (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {isMapLoaded && getMarkers(alerts)}

        {isMapLoaded && (
          <MapControl position={google.maps.ControlPosition.RIGHT_BOTTOM}>
            <Box
              sx={{
                bgcolor: "#fff",
                boxShadow: 2,
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "40px",
                height: "40px",
                margin: "10px",
              }}
            >
              <IconButton
                onClick={panToCurrentLocation}
                aria-label="Pan to current location"
              >
                <MyLocationIcon />
              </IconButton>
            </Box>
          </MapControl>
        )}
        {isMapLoaded && usersLocation?.lat && usersLocation?.lng && (
          <Marker
            position={usersLocation}
            icon={{
              path: google.maps.SymbolPath.CIRCLE,
              scale: 8,
              fillColor: "#4285F4",
              fillOpacity: 1,
              strokeColor: "#FFFFFF",
              strokeWeight: 2,
            }}
            onClick={panToCurrentLocation}
            zIndex={1000}
          />
        )}
      </Map>
    </div>
  );
}

export default AlertsMap;
