/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetModifyListResponse } from '../models/GetModifyListResponse';
import type { GetSubmissionModifyListResponse } from '../models/GetSubmissionModifyListResponse';
import type { PostModifyRequestRequest } from '../models/PostModifyRequestRequest';
import type { PostSubmissionModifyRequestRequest } from '../models/PostSubmissionModifyRequestRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ModifyService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns GetModifyListResponse
     * @throws ApiError
     */
    public modifyList(): CancelablePromise<GetModifyListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/camera_registry/public/modify_list',
        });
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public modifyRequest(
        requestBody: PostModifyRequestRequest,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/camera_registry/public/modify_request',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns GetSubmissionModifyListResponse
     * @throws ApiError
     */
    public submissionModifyList(): CancelablePromise<GetSubmissionModifyListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report/public/submission_modify_list',
        });
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public submissionModifyRequest(
        requestBody: PostSubmissionModifyRequestRequest,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/report/public/submission_modify_request',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
