/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetCamerasListResponse } from '../models/GetCamerasListResponse';
import type { GetOrgConfigResponse } from '../models/GetOrgConfigResponse';
import type { GetRv2UsersResponse } from '../models/GetRv2UsersResponse';
import type { GetSubmissionListResponse } from '../models/GetSubmissionListResponse';
import type { PutUserRequest } from '../models/PutUserRequest';
import type { UpdateSurveyRequest } from '../models/UpdateSurveyRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PortalService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns GetCamerasListResponse
     * @throws ApiError
     */
    public cameraList(): CancelablePromise<GetCamerasListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/camera_registry/portal/camera_list',
        });
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public putUser(
        requestBody: PutUserRequest,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/camera_registry/user',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param orgId
     * @param requestBody
     * @returns GetOrgConfigResponse
     * @throws ApiError
     */
    public postUpdateSurvey(
        orgId: string,
        requestBody: UpdateSurveyRequest,
    ): CancelablePromise<GetOrgConfigResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/report/org/{org_id}/config/update_survey',
            path: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param orgId
     * @returns GetRv2UsersResponse
     * @throws ApiError
     */
    public getRv2Users(
        orgId: string,
    ): CancelablePromise<GetRv2UsersResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report/org/{org_id}/users',
            path: {
                'org_id': orgId,
            },
        });
    }

    /**
     * @param orgId
     * @param timeCreatedMin
     * @param timeCreatedMax
     * @param timeUpdatedMin
     * @param timeUpdatedMax
     * @param status
     * @param source
     * @param firstName
     * @param lastName
     * @param email
     * @param phoneNumber
     * @param secondaryPhoneNumber
     * @param incidentNumber
     * @param trackingNumber
     * @param limit
     * @param offset
     * @param surveyName
     * @param search
     * @param assignedTo
     * @returns GetSubmissionListResponse
     * @throws ApiError
     */
    public getList(
        orgId: string,
        timeCreatedMin?: string | null,
        timeCreatedMax?: string | null,
        timeUpdatedMin?: string | null,
        timeUpdatedMax?: string | null,
        status?: string | null,
        source?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        secondaryPhoneNumber?: string | null,
        incidentNumber?: string | null,
        trackingNumber?: string | null,
        limit?: number | null,
        offset?: number | null,
        surveyName?: string | null,
        search?: string | null,
        assignedTo?: string | null,
    ): CancelablePromise<GetSubmissionListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report/portal/{org_id}/submission',
            path: {
                'org_id': orgId,
            },
            query: {
                'time_created_min': timeCreatedMin,
                'time_created_max': timeCreatedMax,
                'time_updated_min': timeUpdatedMin,
                'time_updated_max': timeUpdatedMax,
                'status': status,
                'source': source,
                'first_name': firstName,
                'last_name': lastName,
                'email': email,
                'phone_number': phoneNumber,
                'secondary_phone_number': secondaryPhoneNumber,
                'incident_number': incidentNumber,
                'tracking_number': trackingNumber,
                'limit': limit,
                'offset': offset,
                'survey_name': surveyName,
                'search': search,
                'assigned_to': assignedTo,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public putRv2User(
        requestBody: PutUserRequest,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/report/user',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
