import { useSessionStorage } from "@react-hooks-library/core";
import { createContext, useState } from "react";

import { apiUserClient } from "./common/api-client";
import { IAlertGroup, IUser } from "./types";

export interface IAuthStateContext {
  currentUser: IUser | null;
  setCurrentUser: React.Dispatch<React.SetStateAction<IUser | null>>;
  currentUserAgency: IAlertGroup;
  setCurrentUserAgency: React.Dispatch<React.SetStateAction<IAlertGroup>>;
  currentUserGroups: IAlertGroup[];
  setCurrentUserGroups: React.Dispatch<React.SetStateAction<IAlertGroup[]>>;
  mfaUserID: string;
  stytchMemberID: string;
  intermediateToken: string;
  redactedNumber: string;
  setRedactedNumber: (value: string) => void;
  setMFAUserID: (value: string) => void;
  setMFAMethodID: (value: string) => void;
  authSteps: string[];
  checkUserAuthStatus: (redirect: boolean) => void;
  login: (email: string, password: string) => Promise<void>;
  setPassword: (token: string, password: string) => Promise<void>;
  logout: () => void;
  goBack: () => void;
  addPhoneNumber: (phoneNumber: string) => Promise<void>;
  verifyOTP: (otp: string) => Promise<void>;
  resendOTP: () => Promise<void>;
  verifyPasswordStrength: (password: string) => Promise<void>;
  redirectionPath?: string;
  setRedirectionPath?: (value: string) => void;
  getOrgIDFromURL: () => string;
}

const AuthStateContext = createContext<IAuthStateContext>({
  currentUser: null as IUser | null,
  setCurrentUser: () => {},
  mfaUserID: "",
  stytchMemberID: "",
  intermediateToken: "",
  redactedNumber: "",
  setRedactedNumber: () => {},
  setMFAUserID: () => {},
  setMFAMethodID: () => {},
  authSteps: [],
  currentUserAgency: {} as IAlertGroup,
  setCurrentUserAgency: () => {},
  currentUserGroups: [],
  setCurrentUserGroups: () => {},
  checkUserAuthStatus: () => {},
  login: () => {
    return new Promise<void>(() => {});
  },
  setPassword: () => {
    return new Promise<void>(() => {});
  },
  logout: () => {},
  goBack: () => {},
  addPhoneNumber: () => {
    return new Promise<void>(() => {});
  },
  verifyOTP: () => {
    return new Promise<void>(() => {});
  },
  resendOTP: () => {
    return new Promise<void>(() => {});
  },
  verifyPasswordStrength: () => {
    return new Promise<void>(() => {});
  },
  redirectionPath: "",
  setRedirectionPath: () => {},
  getOrgIDFromURL: () => "",
});

interface IAuthStateProviderProps {
  children: React.ReactNode;
}

const AuthStateProvider = ({ children }: IAuthStateProviderProps) => {
  const [currentUser, setCurrentUser] = useState<IUser | null>(null);
  const [currentUserAgency, setCurrentUserAgency] = useState<IAlertGroup>(
    {} as IAlertGroup,
  );
  const [currentUserGroups, setCurrentUserGroups] = useState<IAlertGroup[]>([]);
  const [mfaUserID, setMFAUserID] = useSessionStorage<string>("mfaUserID", "");
  const [redactedNumber, setRedactedNumber] = useSessionStorage<string>(
    "redactedNumber",
    "",
  );
  const [redirectionPath, setRedirectionPath] = useSessionStorage<string>(
    "redirectionPath",
    "",
  );
  const [mfaMethodID, setMFAMethodID] = useSessionStorage<string>(
    "mfaMethodID",
    "",
  );
  const [stytchMemberID, setStytchMemberID] = useSessionStorage<string>(
    "stytchMemberID",
    "",
  );
  const [intermediateToken, setIntermediateToken] = useSessionStorage<string>(
    "intermediateToken",
    "",
  );
  const [isLoading, setIsLoading] = useState(false);
  const [authSteps, setAuthSteps] = useSessionStorage<string[]>(
    "authSteps",
    [],
  );

  function goToNextStep() {
    const currentStep = authSteps.findIndex((step) =>
      window.location.href.includes(step),
    );
    const nextStep = currentStep >= 0 ? authSteps[currentStep + 1] : "/";
    window.location.href = nextStep;
  }

  function goBack() {
    const currentStep = authSteps.findIndex((step) =>
      window.location.href.includes(step),
    );
    window.location.href = currentStep >= 1 ? authSteps[currentStep - 1] : "/";
  }

  function checkUserAuthStatus(redirect = true) {
    if (!isLoading) {
      setIsLoading(true);

      const redirection = window.location.pathname;
      const searchParams = window.location.search;

      apiUserClient
        .userDetails()
        .then((userData) => {
          setIsLoading(false);
          if (userData) {
            setCurrentUser(userData);
          } else {
            setCurrentUser(null);
            if (!redirection.includes("/login")) {
              setRedirectionPath(redirection);
            }

            if (redirect) {
              window.location.href = `/login${searchParams}`;
            }
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setCurrentUser(null);
          if (!redirection.includes("/login")) {
            setRedirectionPath(redirection);
          }

          if (redirect) {
            window.location.href = `/login${searchParams}`;
          }
        });
    }
  }

  function setPassword(token: string, password: string) {
    return apiUserClient.setPassword(token, password).then((data) => {
      setMFAUserID(data.user_id);
      setStytchMemberID(data.stytch_user_id);
      setIntermediateToken(data.intermediate_token);

      const steps: string[] = [];
      steps.push("/addphone");
      steps.push("/verifyphone");
      steps.push("/");
      setAuthSteps(steps);
      window.location.href = steps[0];
    });
  }

  function login(email: string, password: string) {
    return apiUserClient
      .loginUser({ username: email, password: password })
      .then((data) => {
        const steps: string[] = [];
        if (data) {
          setMFAUserID(data.user_id);
          setMFAMethodID(data.mfa_method_id);
          setStytchMemberID(data.stytch_user_id);
          setIntermediateToken(data.intermediate_token);

          if (data.requires_email_verification) {
            steps.push("/verifyemail");
          }
          if (!data.phone_number) {
            steps.push("/addphone");
            steps.push("/verifyphone");
          } else if (data.requires_mfa) {
            setRedactedNumber(data.phone_number);
            steps.push("/confirmotp");
          }

          if (data.has_temp_pw) {
            steps.push("/changepassword");
          }
          if (data.profile_update_required) {
            steps.push(`/users/${data.user_id}/profile/edit`);
          }

          steps.push(redirectionPath ? redirectionPath : "/");
          setAuthSteps(steps);
          setRedirectionPath("");
          window.location.href = steps[0];
        } else {
          setCurrentUser(null);
          window.location.href = "/login";
        }
      })
      .catch((err) => {
        setCurrentUser(null);
        throw err;
      });
  }

  function logout() {
    apiUserClient
      .logoutUser()
      .then((data) => {
        setCurrentUser(null);
        window.location.href = "/login";
      })
      .catch((err) => {
        setCurrentUser(null);
        window.location.href = "/login";
      });
  }

  function addPhoneNumber(phoneNumber: string) {
    const formData = {
      phone_number: phoneNumber,
      user_id: mfaUserID ?? "",
      intermediate_token: intermediateToken ?? "",
    };

    return apiUserClient.addPhone(formData).then((res) => {
      setMFAMethodID(res.phone_id);
      setRedactedNumber(res.phone_number);
      goToNextStep();
    });
  }

  function verifyOTP(code: string) {
    const formData = {
      otp_code: code,
      method_id: mfaMethodID ?? "",
      user_id: stytchMemberID ?? "",
      intermediate_token: intermediateToken ?? "",
    };

    return apiUserClient.verifyOTP(formData).then((res) => {
      if (res.phone_id) {
        setMFAMethodID(res.phone_id);
      }
      goToNextStep();
    });
  }

  function resendOTP() {
    const formData = {
      method_id: mfaMethodID ?? "",
      user_id: mfaUserID ?? "",
    };

    return apiUserClient.resendOTP(formData).then(() => {});
  }

  function verifyPasswordStrength(password: string) {
    return apiUserClient.verifyPasswordStrength(password);
  }

  const getOrgIDFromURL = () => {
    const url = new URL(window.location.href);
    return url.pathname.split("/")[2];
  };

  return (
    <AuthStateContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        currentUserAgency,
        setCurrentUserAgency,
        currentUserGroups,
        setCurrentUserGroups,
        mfaUserID,
        stytchMemberID,
        intermediateToken,
        redactedNumber,
        setRedactedNumber,
        setMFAUserID,
        setMFAMethodID,
        authSteps,
        checkUserAuthStatus,
        login,
        logout,
        setPassword,
        goBack,
        addPhoneNumber,
        verifyOTP,
        resendOTP,
        verifyPasswordStrength,
        redirectionPath,
        setRedirectionPath,
        getOrgIDFromURL,
      }}
    >
      {children}
    </AuthStateContext.Provider>
  );
};

export { AuthStateContext, AuthStateProvider };
