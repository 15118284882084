import { Assignment, Dashboard, List, Settings } from "@mui/icons-material";
import { IconButton, Theme, Tooltip, useMediaQuery } from "@mui/material";
import { FC, useContext } from "react";
import { Menu, MenuItemLink, useSidebarState, useTheme } from "react-admin";

import { useRegistry } from "../../components/registry-system/context/RegistryContext";
import { AuthStateContext } from "../../context";

interface Props {
  dense: boolean;
  logout: () => void;
  onMenuClick: () => void;
}

const RubiRegistryMenu: FC<Props> = ({ onMenuClick, dense, logout }) => {
  const [theme] = useTheme();
  const isSmall = useMediaQuery((theme as Theme).breakpoints.down("sm"));
  const [open, setOpen] = useSidebarState();
  const { currentUser, getOrgIDFromURL } = useContext(AuthStateContext);
  const orgID = getOrgIDFromURL() ?? currentUser?.rubi_org_id ?? "";
  const { registryForms } = useRegistry();

  const hideSideBar = () => {
    setOpen(false);
  };

  return (
    <div className="menu">
      {open ? (
        <div className="sidebar-top">
          <div className="app-name">
            Rubi<span>REGISTRY</span>
          </div>
        </div>
      ) : (
        <div className="application-item">
          <Tooltip title="Registry System" placement="right" arrow>
            <IconButton
              color="secondary"
              aria-label="RubiREGISTRY App"
              size="large"
              disableRipple
              sx={{ backgroundColor: "lightgrey" }}
            >
              <Assignment />
            </IconButton>
          </Tooltip>
        </div>
      )}
      <div className="left-menu">
        <Menu.Item
          to={`/rubiregistry/${orgID}/registries`}
          primaryText="Registries"
          leftIcon={<List />}
          sidebarIsOpen={open}
          dense={dense}
          onClick={isSmall ? hideSideBar : () => {}}
        />
        {registryForms.map((form) => (
          <MenuItemLink
            style={{ whiteSpace: "normal" }}
            key={form.survey_id}
            to={`/rubiregistry/${orgID}/${form.survey_class}`}
            primaryText={form.name}
            leftIcon={
              form.description?.image_url ? (
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "50%",
                    width: 24,
                    height: 24,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={form.description.image_url}
                    alt=""
                    style={{ width: 20, height: 20 }}
                  />
                </div>
              ) : (
                <Assignment />
              )
            }
            onClick={isSmall ? hideSideBar : () => {}}
          />
        ))}
        <MenuItemLink
          to={`/rubiregistry/${orgID}/dashboard`}
          primaryText="Dashboard"
          leftIcon={<Dashboard />}
          onClick={isSmall ? hideSideBar : () => {}}
        />
        <MenuItemLink
          to={`/rubiregistry/${orgID}/settings`}
          primaryText="Settings"
          leftIcon={<Settings />}
          onClick={isSmall ? hideSideBar : () => {}}
        />
      </div>
    </div>
  );
};

export default RubiRegistryMenu;
