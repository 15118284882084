import { Grid, Card, CardContent, Typography, Box } from "@mui/material";
import React from "react";
import { useParams, Link } from "react-router-dom";

import { useRegistry } from "./context/RegistryContext";
import "./RegistryList.css";

const RegistryList = () => {
  const { orgId } = useParams();
  const { registryForms } = useRegistry();

  return (
    <div>
      <h1 className="registry-list-title">Registry List</h1>
      <Grid container spacing={3} padding={2}>
        {registryForms.map((registry) => (
          <Grid item xs={12} sm={6} md={4} key={registry.id}>
            <Link
              to={`/rubiregistry/${orgId}/${registry.survey_class}`}
              style={{ textDecoration: "none" }}
            >
              <Card className="registry-card">
                <CardContent>
                  <Box display="flex" alignItems="center" mb={2}>
                    {registry.description?.image_url && (
                      <Box mr={2}>
                        <img
                          src={registry.description.image_url}
                          alt=""
                          style={{ width: 40, height: 40 }}
                        />
                      </Box>
                    )}
                    <Typography variant="h6" component="h2">
                      {registry.name}
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    {registry.description.definition}
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default RegistryList;
