import { ArrowLeft, ArrowRight, CancelTwoTone } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Box, IconButton, Modal, Tooltip, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import styles from "./ExpandedMediaDisplay.module.scss";
import LazyLoadVideo from "./LazyLoadVideo";

const CompareMediaDisplay = ({
  isSlideShowActive,
  setIsSlideShowActive,
  mediaSrc,
  mediaType,
  selectedIndex,
  setSelectedIndex,
  slideshowLength,
}: {
  isSlideShowActive: boolean;
  setIsSlideShowActive: React.Dispatch<React.SetStateAction<boolean>>;
  mediaSrc: string;
  mediaType: "image" | "video";
  selectedIndex: number;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  slideshowLength: number;
}) => {
  useEffect(() => {
    if (selectedIndex === -1) {
      setIsSlideShowActive(false);
      return;
    }
    setIsSlideShowActive(true);
  }, [selectedIndex]);
  const mediaAsset =
    mediaType === "image" ? (
      <TransformWrapper>
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <div className="w-100 h-100 d-flex flex-column">
            <div className="w-100 d-flex pt-5 justify-content-center">
              <Tooltip title="Zoom In" placement="top">
                <IconButton
                  color="primary"
                  size="medium"
                  onClick={() => zoomIn()}
                >
                  <AddIcon style={{ fontSize: 36 }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Zoom Out" placement="top">
                <IconButton
                  color="primary"
                  size="medium"
                  onClick={() => zoomOut()}
                >
                  <RemoveIcon style={{ fontSize: 36 }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Reset Zoom" placement="top">
                <IconButton
                  color="primary"
                  size="medium"
                  onClick={() => resetTransform()}
                >
                  <RestartAltIcon style={{ fontSize: 36 }} />
                </IconButton>
              </Tooltip>
            </div>
            <TransformComponent
              wrapperClass="w-100 h-100"
              contentClass="w-100 h-90"
            >
              <img
                src={mediaSrc}
                alt="media"
                className={styles.alert_full_image}
                onClick={() => {
                  if (selectedIndex < slideshowLength - 1)
                    setSelectedIndex(selectedIndex + 1);
                  else return;
                }}
              />
            </TransformComponent>
          </div>
        )}
      </TransformWrapper>
    ) : (
      <LazyLoadVideo
        src={mediaSrc}
        className={styles.alert_full_image}
        onClick={() => {
          if (selectedIndex < slideshowLength - 1)
            setSelectedIndex(selectedIndex + 1);
          else return;
        }}
      />
    );
  return (
    <div className={styles.images_slide_container}>
      <div className={styles.image_slide}>{mediaAsset}</div>
      <div className={styles.close_arrow_icon}>
        <IconButton
          color="primary"
          size="medium"
          onClick={() => {
            setSelectedIndex(-1);
            setIsSlideShowActive(false);
          }}
        >
          <CancelTwoTone style={{ fontSize: 36 }} />
        </IconButton>
      </div>
      {/* ALLOW NAVIGATION IN CAROUSEL if there are multiple pictures */}
      {slideshowLength > 1 && (
        <>
          <div
            className={styles.back_arrow_icon}
            onClick={() => {
              if (selectedIndex > 0) setSelectedIndex(selectedIndex - 1);
            }}
          >
            <IconButton
              color="default"
              size="medium"
              onClick={() => {
                if (selectedIndex > 0) setSelectedIndex(selectedIndex - 1);
                else setSelectedIndex(slideshowLength - 1);
              }}
            >
              <ArrowLeft style={{ fontSize: 36 }} />
            </IconButton>
          </div>
          <div
            className={styles.right_arrow_icon}
            onClick={() => {
              if (selectedIndex < slideshowLength - 1)
                setSelectedIndex(selectedIndex + 1);
            }}
          >
            <IconButton
              color="default"
              size="medium"
              onClick={() => {
                if (selectedIndex < slideshowLength - 1)
                  setSelectedIndex(selectedIndex + 1);
                else setSelectedIndex(0);
              }}
            >
              <ArrowRight style={{ fontSize: 36 }} />
            </IconButton>
          </div>
        </>
      )}
      <Typography className={styles.bottom_counter_text} variant="h6">
        {selectedIndex + 1}/{slideshowLength}
      </Typography>
    </div>
  );
};

export default CompareMediaDisplay;
