import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import { SurveyConfiguration } from "../../../common/api-client/or-api";
import { API } from "../../../common/api-client/or-api/api";
import { AuthStateContext } from "../../../context";

interface RegistryContextType {
  registryForms: SurveyConfiguration[];
  loading: boolean;
  error: Error | null;
}

export const RegistryContext = createContext<RegistryContextType>({
  registryForms: [],
  loading: false,
  error: null,
});

export const RegistryProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [registryForms, setRegistryForms] = useState<SurveyConfiguration[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const { currentUser, getOrgIDFromURL } = useContext(AuthStateContext);
  const orgID = getOrgIDFromURL() ?? currentUser?.rubi_org_id ?? "";

  useEffect(() => {
    const fetchRegistryForms = async () => {
      if (!orgID) return;

      setLoading(true);
      try {
        const response = await API.orgConfig.get(orgID);
        const registryConfigs = response.surveys.filter(
          (survey) => survey.is_registry,
        );
        setRegistryForms(registryConfigs);
      } catch (error) {
        setError(error as Error);
        console.error("Failed to fetch registry forms:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRegistryForms();
  }, [currentUser, orgID]);

  return (
    <RegistryContext.Provider value={{ registryForms, loading, error }}>
      {children}
    </RegistryContext.Provider>
  );
};

export const useRegistry = () => useContext(RegistryContext);
