import { Card, Typography, Stack, Box } from "@mui/material";
import React from "react";
import { Link, useParams } from "react-router-dom";

const FormManagementOverview: React.FC = () => {
  const { orgId } = useParams<{ orgId: string }>();

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Form Management
      </Typography>

      <Typography variant="body1" paragraph>
        Form management consists of two main components: Configurations and
        Surveys. These work together to create the complete form experience for
        reporting parties.
      </Typography>

      <Stack spacing={3}>
        {/* Configurations Section */}
        <Card sx={{ p: 3 }}>
          <Typography variant="h5" gutterBottom>
            Configurations
          </Typography>
          <Typography variant="body1" paragraph>
            Configurations control what reporting parties see when accessing
            forms. This includes the form's title, description, availability
            settings, and overall presentation. Each configuration references a
            survey that contains the actual questions.
          </Typography>
          <Link to={`/rubireportv2/${orgId}/forms/configs`}>
            View Form Configurations
          </Link>
        </Card>

        {/* Surveys Section */}
        <Card sx={{ p: 3 }}>
          <Typography variant="h5" gutterBottom>
            Surveys
          </Typography>
          <Typography variant="body1" paragraph>
            Surveys contain the actual questions and content that make up a
            form. They are referenced by configurations and can be reused across
            multiple configurations. This separation allows for efficient form
            management and version control.
          </Typography>
          <Link to={`/rubireportv2/${orgId}/forms/surveys`}>View Surveys</Link>
        </Card>
      </Stack>
    </Box>
  );
};

export default FormManagementOverview;
