/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PatchPoliceServiceRequest } from '../models/PatchPoliceServiceRequest';
import type { PoliceServiceModel } from '../models/PoliceServiceModel';
import type { PutPoliceServiceRequest } from '../models/PutPoliceServiceRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PoliceServiceService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody
     * @returns PoliceServiceModel
     * @throws ApiError
     */
    public putAdmin(
        requestBody: PutPoliceServiceRequest,
    ): CancelablePromise<PoliceServiceModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/camera_registry/admin/police_service',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param policeServiceId police_service_id
     * @returns any
     * @throws ApiError
     */
    public deleteAdmin(
        policeServiceId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/camera_registry/admin/police_service/{police_service_id}',
            path: {
                'police_service_id': policeServiceId,
            },
        });
    }

    /**
     * @returns PoliceServiceModel
     * @throws ApiError
     */
    public get(): CancelablePromise<PoliceServiceModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/camera_registry/portal/police_service',
        });
    }

    /**
     * @param requestBody
     * @returns PoliceServiceModel
     * @throws ApiError
     */
    public patch(
        requestBody: PatchPoliceServiceRequest,
    ): CancelablePromise<PoliceServiceModel> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/camera_registry/portal/police_service',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
